import { createAction } from '@atc/modular-redux';

// Action Creators
import removeOwnedVehicleActionCreator from './removeOwnedVehicleActionCreator';
import { updateOwnedVehicleZipCodeActionCreator, updateOwnedVehicleConditionActionCreator, updateOwnedVehicleMileageActionCreator, getUpdatedDataForSelectedVehicle } from './updateOwnedVehicleActionCreator';
import getOwnedVehicles from './getOwnedVehiclesCreator';
import getRecallRepairDataCreator from './getRecallRepairDataCreator';
import getSelectedVehiclePAData from './getSelectedVehiclePADataCreator';
import saveStashVehicleActionCreator from './saveStashVehicleActionCreator';
import getDemandBarDataCreator from './getDemandBarDataCreator';

export default (duck) => ({
    hydrateOwnedVehicle: createAction(duck.types.HYDRATE_OWNED_VEHICLE),
    setSelectedOwnedVehicleIndex: createAction(duck.types.SELECTED_OWNED_VEHICLE_INDEX),
    setSelectedOwnedVehicleId: createAction(duck.types.SELECTED_OWNED_VEHICLE_ID),
    setSelectedOwnedVehiclePAData: createAction(duck.types.SELECTED_OWNED_VEHICLE_PA_DATA),
    setSelectedOwnedVehicleRecallData: createAction(duck.types.SELECTED_OWNED_VEHICLE_RECALL_DATA),
    setSelectedOwnedVehicleRoutes: createAction(duck.types.SELECTED_OWNED_VEHICLE_ROUTES),
    setSelectedOwnedVehicleDemandBarData: createAction(duck.types.SELECTED_OWNED_DEMAND_BAR_DATA),
    setPageAlert: createAction(duck.types.SET_PAGE_ALERT),
    getOwnedVehicles: getOwnedVehicles(duck),
    saveStashVehicle: saveStashVehicleActionCreator(duck),
    removeOwnedVehicle: removeOwnedVehicleActionCreator(duck),
    updateOwnedVehicleZipCode: updateOwnedVehicleZipCodeActionCreator(duck),
    updateOwnedVehicleCondition: updateOwnedVehicleConditionActionCreator(duck),
    updateOwnedVehicleMileage: updateOwnedVehicleMileageActionCreator(duck),
    getUpdatedDataForSelectedVehicle: getUpdatedDataForSelectedVehicle(duck),
    getRecallRepairData: getRecallRepairDataCreator(duck),
    getDemandBarData: getDemandBarDataCreator(duck),
    getSelectedVehiclePAData: getSelectedVehiclePAData(duck),
    removeVehicle: createAction(duck.types.REMOVE_OWNED_VEHICLE),
    getVehiclesInitialLoad: createAction(duck.types.VEHICLES_INITIAL_LOAD),
});
