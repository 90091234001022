import fetchFromBFF from '../utilities/fetchFromBFF.js';
import { getConsumerMetadata } from 'reaxl-analytics';
import { getJSON, rejectNonOkResponses, handle404OrRejectWithError } from '../utilities/fetchHandlers.js';

const userApiFetch = async ({
    listingId,
    message,
    phoneNumber,
    userName,
}) => {
    const { myConsumerId } = await getConsumerMetadata();
    const data = {
        requestId: `VDP2MOBILE${listingId}`,
        alertConfigId: 'atc_pinpoint_sharevdp',
        dataMap: {
            mobile_number: phoneNumber.replace(/[^\d]/g, ''),
            sms_message: `${userName} ${message}`,
            consumerId: myConsumerId,
        },
    }

    return fetchFromBFF(`/account/auth-user/share-text`, {
        method: 'POST',
        headers: {
            accesstoken: 'temptoken',
            idtoken: 'temptoken',
        },
        body: JSON.stringify(data),
        }).then(rejectNonOkResponses)
        .then(getJSON)
        .catch(handle404OrRejectWithError);
}

export default (data) => userApiFetch(data);
