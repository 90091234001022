import { userApiDeleteNewInventory } from '../../../fetchers';

export default function deleteAllSavedSearchesAlertsActionCreator(savedSearchesDuck) {
    return ({
        onUpdateFailure = () => {},
    }) => async (dispatch, getState) => {
        const savedSearches = savedSearchesDuck.selectors.getSavedSearches(getState());

        const currentEmailSubscriptions = [];

        const updatedBookmarks = savedSearches.map((bookmark) => {
            const hasEmailAlerts = bookmark.newInventorySubscriptions.some(({ notificationType }) => notificationType === 'EMAIL');
            if (hasEmailAlerts) {
                const emailSubscription = bookmark.newInventorySubscriptions.find((subscription) => subscription.notificationType === 'EMAIL');
                currentEmailSubscriptions.push(emailSubscription);
                bookmark.newInventorySubscriptions = [];
                return bookmark;
            }

            return bookmark;
        });

        const currentEmailSubscriptionsPromises = currentEmailSubscriptions.map((emailSubscription) => new Promise((resolve, reject) => {
            userApiDeleteNewInventory(emailSubscription)
                .then((results) => {
                    resolve({
                        subscriptionId: emailSubscription.subscriptionId,
                        bookmarkDeleted: true,
                    });
                })
                .catch((error) => reject(error));
        }));

        Promise.all(currentEmailSubscriptionsPromises)
            .then((results) => {
                results.forEach((result) => {
                    if (result.bookmarkDeleted) {
                        dispatch(savedSearchesDuck.creators.hydrateSavedSearches(updatedBookmarks));
                    }
                });
            })
            .catch((error) => {
                onUpdateFailure();
                console.error(error);
            });
    };
}
