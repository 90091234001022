import { fetchYears } from '../../../fetchers';

export default function getYearsCreator(vehiclePickerDuck) {
    return ({
        onSuccess = () => {},
        onError = () => {},
    }) => async (dispatch) => {
        try {
            const data = await fetchYears();
            const years = data.reduce((acc, { id }) => { return [
                ...acc, 
                { value: id, text: id}
            ]}, []);
            dispatch(vehiclePickerDuck.creators.setYears({years}));
            onSuccess();
        } catch (error) {
            onError();
            throw new Error(error);
        }
    };
}