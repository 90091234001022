import { pathBuilders } from './index';

export default ({
    inventory = {},
    savedlistingIds = {},
    savedBookmarks = {},
}) => savedlistingIds.map((listingId) => {

    const listing = { ...inventory[listingId] };

    const {
        id,
        title,
        vin,
        owner = {},
    } = listing;

    const privateSeller = owner?.privateSeller || false;
    const bookmarkListing = savedBookmarks[listingId];
    const isTredVdp = privateSeller;

    return {
        ...listing,
        ...bookmarkListing,
        href: pathBuilders.buildVehicleDetailsPath({ listingId: id, vin, isTredVdp }),
        isExpired: Object.keys(listing).length < 1,
        originalTitle: title,
        title: bookmarkListing?.bookmarkTitle || title,
    };
});
