import _get from 'lodash/get';

export default {
    getPickerTypeSelector: (state) => _get(state, 'vehiclePicker.pickerType', {}),
    getYmmPickerDataSelector: (state) => _get(state, 'vehiclePicker.ymm', {}),
    getLpSelector: (state) => _get(state, 'vehiclePicker.lp', ''),
    getLpStateSelector: (state) => _get(state, 'vehiclePicker.state', ''),
    getVinPickerDataSelector: (state) => _get(state, 'vehiclePicker.vin', {}),
    getVinLpPickerDataSelector: (state) => _get(state, 'vehiclePicker.dataForVinLpDetails', {}),
};
