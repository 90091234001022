/* eslint-disable no-unreachable */
import { Duck } from '@atc/modular-redux';

// Selectors
import selectors from './vehiclePickerDuckSelectors';
import reducer from './vehiclePickerDuckReducer';
import creators from './actionCreators';

const defaultState = {
    ymm: {
        years: [],
        makes: [],
        models: [],
        zip: null,
        mileage: null,
        error: null,
        selectedYear: null,
        selectedMake: null,
        selectedModel: null,
        enableSubmit: false,
    },
    vin: '',
    lp:'',
    pickerType: 'ymm',
};

function vehiclePickerDuckCreator({
    namespace = '',
    store = 'vehiclePicker',
    storePath,
    env = 'qa',
    ...rest
} = {}) {

    return new Duck({
        ...rest,
        namespace,
        store,
        storePath,
        initialState: {
            ...defaultState,
        },
        consts: {},
        types: [
            'SET_PICKER_TYPE',
            'SET_YEARS',
            'SET_MAKES',
            'SET_MODELS',
            'SELECT_YEAR',
            'SELECT_MAKE',
            'SELECT_MODEL',
            'SET_ZIPCODE',
            'SET_MILEAGE',
            'SET_YMM_ERROR',
            'SET_ZIP_ERROR',
            'SET_VIN_LP_DATA',
            'RESET_STATE',
        ],

        creators: (duck) => creators(duck),
        reducer,
        selectors,
    });
}

export default vehiclePickerDuckCreator();
