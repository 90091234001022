import fetchFromBFF from '../utilities/fetchFromBFF.js';

export default function fetchPreferences(body) {
    const authenticate = !body.emailToken;
    return fetchFromBFF(authenticate ? '/account-api/subscription' : '/account-api/subscription/email-token', {
        method: 'PUT',
        body,
        authenticate,
    }).then((response) => response.status);
}
