import { fetchJSON } from '@bonnet/core/fetch';

export default (endpoint, options = {}, id = 'vrsServiceFetcher') => fetchJSON(`/cars-for-sale/vrs${endpoint}`, {
    ...options,
    circuitBreaker: {
        id,
        timeout: 5000,
        resetTimeout: 300000,
        fallback: {},
    },
});
