/* eslint-disable no-unreachable */
import { Duck, createAction } from '@atc/modular-redux';
import getOptionsCreator from './actionCreators/getOptionsCreator';
import getAddOptionsCreator from './actionCreators/getAddOptionsCreator';
import getUpdatedOptionsCreator from './actionCreators/getUpdatedOptionsCreator';
import saveUpdatedOptionsCreator from './actionCreators/saveUpdatedOptionsCreator';
import setSelectedColor from './actionCreators/setSelectedColor';
import setSelectedOptionsType from './actionCreators/setSelectedOptionsType';
// Selectors
import selectors from './editCarDuckSelectors';
import reducer from './editCarDuckReducer';

const defaultState = {
    vehicleId: '',
    options: {},
    optionsType: '',
    colorOptionId: null,
    selectHistory: '',
    alertError: '', // 'save'
    condition: '',
};

function editCarDuckCreator({
    namespace = '',
    store = 'editCar',
    storePath,
    env = 'qa',
    ...rest
} = {}) {

    return new Duck({
        ...rest,
        namespace,
        store,
        storePath,
        initialState: {
            ...defaultState,
        },
        consts: {},
        types: [
            'HYDRATE_OPTIONS',
            'UPDATE_OPTIONS',
            'SET_SELECT_HISTORY',
            'SET_OPTIONS_TYPE',
            'SET_COLOR_ID',
            'SET_ERROR',
            'SET_VEHICLE_ID',
            'SET_VIN_LP_DATA',
            'SET_CONDITION',
            'SET_EXT_COLOR',
            'CLEAR_STATE',
        ],

        creators: (duck) => ({
            setVehicleId: createAction(duck.types.SET_VEHICLE_ID),
            setVinLpData: createAction(duck.types.SET_VIN_LP_DATA),
            fetchOptions: getOptionsCreator(duck),
            hydrateOptions: createAction(duck.types.HYDRATE_OPTIONS),
            setSelectHistory: createAction(duck.types.SET_SELECT_HISTORY),
            updateOptions: getUpdatedOptionsCreator(duck),
            setOptionsType: createAction(duck.types.SET_OPTIONS_TYPE),
            setSelectedOptionsType: setSelectedOptionsType(duck),
            updateSelectedColor: setSelectedColor(duck),
            setColorId: createAction(duck.types.SET_COLOR_ID),
            setError: createAction(duck.types.SET_ERROR),
            getAddOptions: getAddOptionsCreator(duck),
            setCondition: createAction(duck.types.SET_CONDITION),
            setExtColor: createAction(duck.types.SET_EXT_COLOR),
            saveUpdatedOptions: saveUpdatedOptionsCreator(duck),
            clearState: createAction(duck.types.CLEAR_STATE),
        }),

        reducer,
        selectors,
    });
}

export default editCarDuckCreator();
