import { fetchJSON } from '@bonnet/core/fetch';

export default async (args) => {
    const {
        vehicleId,
        selectHistory,
    } = args || {};

    try {
        const initialOptions = await fetchJSON(
            `/account/services/selectedOptionsService?vehicleId=${vehicleId}${selectHistory ? `&initialOptions=${selectHistory}` : ''}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                },
            }
        );

        return initialOptions;
    } catch (err) {
        console.error('options ', err);
        throw new Error();
    }
};
