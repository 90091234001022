import { objectDuckCreator, createAction } from '@atc/modular-redux';

export default objectDuckCreator({
    namespace: '',
    store: 'dealerLot',
    initialState: {},
}).extend({
    creators: (duck) => ({
        resetState: createAction(duck.types.RESET),
    }),
});
