const pathRegex = /{(.+?)(\(.+?\))?[?]?}/g;

function addToArray(obj, array) {
    Object.keys(obj).forEach((key) => {
        array.push(`${key}=${obj[key]}`);
    });
    return array;
}

function asQueryString(queryParameters, lowercaseQueryString = true) {
    if (queryParameters == null || queryParameters.length) {
        return null;
    }
    let request = [];

    queryParameters.forEach((value, key) => {
        if (typeof value === 'object') {
            request = addToArray(value, request);
        } else if (typeof value === 'number') {
            request.push(`${key}=${value}`);
        } else if (typeof value === 'string') {
            request.push(`${key}=${value}`);
        }
    });

    const queryString = request.join('&');
    return lowercaseQueryString ? queryString.toLowerCase() : queryString;
}

function encode(p, lowercaseQueryString = true) {
    const value = p;

    if (typeof (p) === 'string' && p !== null) {
        const encodedStr = `${value || ''}`
            .replace(/\s/g, '-')
            .replace(/\//g, '-')
            .replace(/[^a-zA-Z0-9-_|,]/g, '');
        return lowercaseQueryString ? encodedStr.toLowerCase() : encodedStr;
    }

    return p;
}

function objToStrMap(obj, lowercaseQueryString = true) {
    const strMap = new Map();
    Object.keys(obj).forEach((key) => {
        // convert to string just in case anyone passes something else
        if (lowercaseQueryString) {
            strMap.set(key.toLowerCase(), obj[key].toString().toLowerCase());
        } else {
            strMap.set(key, obj[key].toString());
        }
    });
    return strMap;
}

function getRouteValues(paramDictionary, path, lowercaseQueryString = true) {
    const routeValues = new Map();
    const paramDictionaryClear = objToStrMap(paramDictionary, lowercaseQueryString);

    const url = path?.replace(pathRegex, (...match) => {
        const routekey = match[1];
        const routeValue = paramDictionary[routekey];

        if (routeValue) {
            routeValues.set(routekey, routeValue);
            paramDictionaryClear.delete(routekey);
        } else {
            // If the parameter is not listed, don't error out, just return empty string
            return '';
        }

        return routeValue;
    });

    return {
        url,
        routeValues,
        paramDictionaryClear,
    };
}

function fixRouteValues(parameters, lowercaseQueryString = true) {
    const fixedValues = {};
    Object.entries(parameters).forEach(([key, value]) => {
        if (value !== null) {
            const keyValue = lowercaseQueryString ? key.toLowerCase() : key;
            fixedValues[keyValue] = encode(parameters[key], lowercaseQueryString);
        }
    });
    return fixedValues;
}

function generateUrl(routeDefinition, parameters) {
    const paramDictionary = fixRouteValues(parameters, routeDefinition.LowercaseQueryString);
    // get route values from param dictionary and remove them so that paramDictionary only contains the query parameters
    const routeValues = getRouteValues(paramDictionary, routeDefinition.Path, routeDefinition.LowercaseQueryString);
    // remove empty parts in path
    const pathValues = routeValues.url?.split('/');
    routeValues.url = pathValues?.reduce((accumulator, currentValue) => (currentValue ? (accumulator + '/' + currentValue) : accumulator));

    // add trailing slash
    routeValues.url = routeValues.url.endsWith('/') ? routeValues.url : `${routeValues.url}/`;

    // add leading slash
    routeValues.url = routeValues.url.startsWith('/') ? routeValues.url : `/${routeValues.url}`;

    // lowercase path
    routeValues.url = routeValues.url.toLowerCase();

    // add query paramters
    const queryString = asQueryString(routeValues.paramDictionaryClear, routeDefinition.LowercaseQueryString);
    return queryString.length ? `${routeValues.url}?${queryString}` : routeValues.url;
}

export default generateUrl;
