/* eslint-disable no-console */
import fetch from '@bonnet/core/fetch';

export default function fetchWithCircuitBreaker({ path, data = {}, errMsg = 'fetch error', fallback = {}, id = 'circuitBreakerId' }) {
    return fetch(path, {
        ...data,
        circuitBreaker: {
            id,
            timeout: process.env.CIRCUIT_BREAKER_TIMEOUT,
            resetTimeout: process.env.CIRCUIT_BREAKER_TIMEOUT_RESET,
            fallback,
        },
    }).catch((error) => console.error(errMsg, error));
}
