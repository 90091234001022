import axios from 'axios';
import { createLoader, request } from './researchable';

const VRS_BASE_API = 'https://vrs-sandbox.datasolutions.coxautoinc.com/vrs';
const VRS_API_KEY = 'zspqs5ye4pb6j2ghusfkkt2e';

export const parseOptionsQuery = (optionQuery) => {
    const configChanges = [];
    const splitQuery = optionQuery.split('|');
    for (let i = 0; i + 1 < splitQuery.length; i += 2) {
        if (!isNaN(splitQuery[i]) && (splitQuery[i + 1] === 'true' || splitQuery[i + 1] === 'false')) {
            configChanges.push({
                Sequence: (configChanges.length + 1),
                VehicleOptionId: parseInt(splitQuery[i]),
                Action: (splitQuery[i + 1] === 'true' ? 'selected' : 'deselected'),
            });
        }
    }

    return configChanges;
};

const fetchSelectedOptions = async (key) => {
    const apiName = 'VRS FETCH SELECTED OPTIONS API';
    try {
        const [vehicleId, initialOptions] = key.split(':');
        if (initialOptions) {
            const initialOptionsData = parseOptionsQuery(initialOptions);
            const body = JSON.stringify({
                StartingConfiguration: {
                    VehicleId: parseInt(vehicleId),
                },
                ConfigurationChanges: initialOptionsData,
            });

            // TODO: VY confirm this shouldn't switch to Researchable API
            const requestUrl = `${VRS_BASE_API}/config/Configurations?ApplicationFilter=Consumer`;

            const { data } = await axios.post(requestUrl, body, {
                headers: {
                    'Content-type': 'application/json',
                    api_key: VRS_API_KEY,
                },
            });

            return (((data || {}).finalConfiguration) || {}).vehicleOptionIds;
        }
    } catch (e) {
        console.error(`${apiName} - Error: ${e}`);
        return {
            errorMessage: `${e}`,
            data: null,
        };
    }
    return null;
};

export const optionsLoader = createLoader({
    functionToCache: fetchSelectedOptions,
    name: 'options',
});
