import { adsDuck } from '../ducks';

export default function dispatchPageData() {
    return async (ctx) => {
        // Create batched action queue
        ctx.batchDispatch.createActions((deferredCtx) => {
            const {
                pageLevelTargeting,
                adUnit,
                prebidAdapters,
            } = deferredCtx.data?.ads || {};
            return [
                // Set the ad parameters
                adsDuck.creators.setKeys({
                    pageLevelTargeting,
                    adUnit,
                    prebidAdapters,
                    showAds: false,
                }),
            ];
        });

        // dispatch the batched action queue
        await ctx.batchDispatch.dispatchActions(ctx);
    };
}
