import Screen from '../constants/screen.js';
import Route from './Route.js';
import MyATCRoute from './MyATCRoute.js';

// Screens
// TODO:
// look into removing routes that aren't used because their screens are only reached via JS "navigation."
Route.SIGN_IN = new Route('sign-in', Screen.SIGN_IN); // Needs a route
Route.SIGN_UP = new Route('sign-up', Screen.SIGN_UP); // Needs a route
Route.SIGN_OUT = new Route('sign-out', Screen.SIGN_OUT); // Needs a route
Route.NEW_USER_WELCOME = new Route('new-user-welcome', Screen.WELCOME);

// Pages
Route.CARS = new Route('cars');
Route.MYCARS = new Route('mycars');
Route.MYCARS_ADD_CAR = new Route('mycars/add');
Route.MYCARS_EDIT_CAR = new Route('mycars/edit');
Route.MYCARS_REMOVE_CAR = new Route('mycars/remove');
Route.SHOPPEDCARS = new Route('savedcars');
Route.SEARCHES = new Route('searches');
Route.MESSAGES = new Route('messages');
Route.DEALER_REVIEWS = new Route('dealer-reviews');
Route.PROFILE = new Route('profile');
Route.UPDATE_PROFILE = new Route('update-profile');
Route.PREFERENCES = new Route('preferences');
Route.UNSUBSCRIBE_RESULT = new Route('unsubscribe-result');
Route.FEDERATED_AUTH = new Route('federated-auth');

/** *****************************
 * NOTICE: /my-autotrader URLs are deprecated!
 * PLEASE DO NOT CREATE ANY NEW ROUTES USING MyATCRoute
 */

// Deprecated /my-autotrader Screens; See notice above.
Route.MYATC_AUTH = new MyATCRoute('auth');
Route.MYATC_SIGN_IN = new MyATCRoute('auth', Screen.SIGN_IN);
Route.MYATC_SIGN_UP = new MyATCRoute('auth', Screen.SIGN_UP);
Route.MYATC_SIGN_OUT = new MyATCRoute('auth', Screen.SIGN_OUT);
Route.MYATC_NEW_USER_WELCOME = new MyATCRoute('auth', Screen.WELCOME);

// Deprecated /my-autotrader Tabs; See notice above.
Route.MY_CARS = new MyATCRoute('my-cars');
Route.MY_SEARCHES = new MyATCRoute('my-searches');
Route.MY_MESSAGES = new MyATCRoute('messages');
Route.MY_PROFILE = new MyATCRoute('my-profile');
Route.MY_UPDATE_PROFILE = new MyATCRoute('update-profile');
Route.MY_PREFERENCES = new MyATCRoute('my-preferences');
Route.MY_UNSUBSCRIBE_RESULT = new MyATCRoute('unsubscribe-result');

export default Route;
