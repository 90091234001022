import fetchFromBFF from '../utilities/fetchFromBFF';

const formatLastExecutedDate = (lastExecuted) => lastExecuted.replace('T', ' ').replace(/\d+/g, (num) => (num.length < 2 ? '0' : '') + num);

export default async ({ updatedBookmark }) => {
    const { bookmarkId, lastExecuted, bookmarkVersion } = updatedBookmark;

    const formattedBookmark = {
        ...updatedBookmark,
        version: bookmarkVersion,
        lastExecuted: formatLastExecutedDate(lastExecuted),
    };

    const response = await fetchFromBFF(`/account/auth-user/saved-search/id/${bookmarkId}`, {
        method: 'PATCH',
        headers: {
            accesstoken: 'temptoken',
            idtoken: 'temptoken',
        },
        body: JSON.stringify(formattedBookmark),
    });

    if (response.ok) {
        return response.json();
    }
    throw new Error(response?.statusText, { cause: response });
};
