export default (event) => {
    const eventOrigin = event.origin;
    const allowedDomains = [
        'localhost',
        'localhost:3000',
        'localhost:8080',
        'localhost:8081',
        'kbb.com',
    ];

    const isVerifiedOrigin = allowedDomains.some((domain) => typeof eventOrigin !== 'undefined'
      && eventOrigin.indexOf(domain) === (eventOrigin.length - domain.length));

    return isVerifiedOrigin;
};
