/* eslint-disable no-console,consistent-return */
import { saveUserApiOwnedVehicles, subscribePricingReport, getUserApiConsumer } from '../../../fetchers/index';
import { formatCarToAddDataForSave } from '../helpers';
import { ownedVehicleDuck } from '../..';
export default function saveCarToAddActionCreator(addCarDuck) {
    return ({
        carToAdd,
        onSaveSuccess = () => {},
        onSaveFailure = () => {},
    }) => async (dispatch, getState) => {
        // format car to add
        const formattedVehicle = formatCarToAddDataForSave(carToAdd);
        const { consumerid } = await getUserApiConsumer();

        await saveUserApiOwnedVehicles(formattedVehicle)
            .then((data) => {
                const { vehicleid } = data;
                dispatch(ownedVehicleDuck.creators.getOwnedVehicles({
                    onSuccess: onSaveSuccess,
                    onError: onSaveFailure,
                }));

                // subscribe newly added vehicle to pricing report
                subscribePricingReport(consumerid, vehicleid)
                    .catch((error) => {
                        console.error('saveAddCar subscribePricingReport', error);
                    });

            }).catch((error) => {
                console.error('saveAddCar error:', error);
                onSaveFailure();
                throw Error(error);
            });

    };
}
