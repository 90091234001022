import { savedInventoryDuckCreator } from 'axl-ducks';
import authDuck from './authDuck';

import { getPersonalizationClientEnv } from './duckUtils';

const savedInventoryDuck = savedInventoryDuckCreator({
    authDuck,
    env: getPersonalizationClientEnv(),
});

export default savedInventoryDuck;
