export default (state, action, { initialState, types }) => {

    const { payload } = action;

    switch (action.type) {

        case types.SET_ADD_CAR: {
            return {
                ...state,
                ...payload,
            };
        }

        case types.SET_CURRENT_STEP: {
            return {
                ...state,
                ...payload,
            };
        }
        case types.HYDRATE_OPTIONS: {
            return {
                ...state,
                options: {
                    ...payload,
                },
            };
        }
        case types.SET_OPTIONS_TYPE: {
            return {
                ...state,
                optionsType: payload,
            };
        }
        case types.SET_COLOR_ID: {
            return {
                ...state,
                colorOptionId: payload,
            };
        }
        case types.SET_SELECT_HISTORY: {
            return {
                ...state,
                selectHistory: payload,
            };
        }
        case types.SET_ERROR: {
            return {
                ...state,
                alertError: payload,
            };
        }
        case types.SET_CONDITION: {
            return {
                ...state,
                condition: payload,
            };
        }
        case types.SET_EXT_COLOR: {
            return {
                ...state,
                extColor: payload,
            };
        }

        case types.RESET_STATE: {
            return initialState;
        }

        default:
            return state;
    }
};
