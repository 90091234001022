import { fetchJSON } from '@bonnet/core/fetch';
import { convertIsoDateToLocal, capitalizeWord } from '../utilities';
import getOptionIds from '../utilities/getOptionIds';

export const fetchPrice = async ({ vehicle, shoppersVehicle, optionIds }) => fetchJSON('/account/services/priceAdvisorVehicleData', {
    query: {
        vehicleId: vehicle.kbbVehicleId || vehicle.kbb_vehicle_id,
        zipcode: vehicle.latestZipcode || vehicle.zipcode || vehicle.vehicle_preferences?.zipcode,
        mileage: vehicle.latestMileage || vehicle.mileage || vehicle.vehicle_preferences?.mileage || '0',
        condition: vehicle.latestCondition || vehicle.condition || vehicle.vehicle_preferences?.condition,
        optionIds,
        isShoppersVehicle: shoppersVehicle,
        saveType: shoppersVehicle ? vehicle.saveType : '',
        shoppersNewUsedVehicle: shoppersVehicle ? vehicle.vehicle_preferences?.new_or_used : '',
    },
}).then((priceResponse) => ({
    privatePartyValue: {
        href: priceResponse?.privateData?.href,
        slimHref: priceResponse?.privateData?.slimHref,
        isoDate: priceResponse?.privateData?.priceAsOfDate,
        localDate: convertIsoDateToLocal(priceResponse?.privateData?.priceAsOfDate),
        priceInfoOverlay: priceResponse?.privateData?.priceInfoOverlay,
    },
    tradeInValue: {
        href: priceResponse?.tradeData?.href,
        slimHref: priceResponse?.tradeData?.slimHref,
        isoDate: priceResponse?.tradeData?.priceAsOfDate,
        localDate: convertIsoDateToLocal(priceResponse?.tradeData?.priceAsOfDate),
        priceInfoOverlay: priceResponse?.tradeData?.priceInfoOverlay,
    },
    usedCarDealerValue: {
        href: priceResponse?.usedCarDealerData?.href,
        slimHref: priceResponse?.usedCarDealerData?.slimHref,
        isoDate: priceResponse?.usedCarDealerData?.priceAsOfDate,
        localDate: convertIsoDateToLocal(priceResponse?.usedCarDealerData?.priceAsOfDate),
        priceInfoOverlay: priceResponse?.usedCarDealerData?.priceInfoOverlay,
    },
    usedCarPrivatePartyValue: {
        href: priceResponse?.usedCarPrivatePartyData?.href,
        slimHref: priceResponse?.usedCarPrivatePartyData?.slimHref,
        isoDate: priceResponse?.usedCarPrivatePartyData?.priceAsOfDate,
        localDate: convertIsoDateToLocal(priceResponse?.usedCarPrivatePartyData?.priceAsOfDate),
        priceInfoOverlay: priceResponse?.usedCarPrivatePartyData?.priceInfoOverlay,
    },
    usedCpoValue: {
        href: priceResponse?.usedCpoData?.href,
        slimHref: priceResponse?.usedCpoData?.slimHref,
        isoDate: priceResponse?.usedCpoData?.priceAsOfDate,
        localDate: convertIsoDateToLocal(priceResponse?.usedCpoData?.priceAsOfDate),
        priceInfoOverlay: priceResponse?.usedCpoData?.priceInfoOverlay,
    },
    newCarDealerValue: {
        href: priceResponse?.newCarDealerData?.href,
        slimHref: priceResponse?.newCarDealerData?.slimHref,
        isoDate: priceResponse?.newCarDealerData?.priceAsOfDate,
        localDate: convertIsoDateToLocal(priceResponse?.newCarDealerData?.priceAsOfDate),
        priceInfoOverlay: priceResponse?.newCarDealerData?.priceInfoOverlay,
    },
    condition: priceResponse?.condition || capitalizeWord(vehicle?.vehicle_details?.latest_condition) || '',
}));

export default async function priceAdvisorFetcher({ vehicles, shoppersVehicle = false }) {
    return Promise.all(vehicles.map(async (vehicle) => {
        const optionIds = await getOptionIds(vehicle);
        return fetchPrice({ vehicle, shoppersVehicle, optionIds });
    }));
}
