import { fetchJSONWithCircuitBreaker } from '../utilities';

export default function emailFetcher(data, fallback = {}) {
    return fetchJSONWithCircuitBreaker(
        '/account/gearbox/contactowner',
        { method: 'POST',
            body: JSON.stringify(data),
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
            },
        },
        'emailFetcher Error: ',
        fallback,
        'emailFetcher'
    );
}
