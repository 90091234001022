export const grabSelectedOptions = (options) => {
    let allSelectedOptions = [];
    options.groups.forEach((accord) => {
        accord.sections.forEach((sect) => {
            allSelectedOptions = allSelectedOptions.concat((sect.options.filter((opt) => opt.isSelected)));
        });
    });
    return allSelectedOptions;
};

export const mapOptions = (options, mapFunc) => {
    
    const groups = options.groups.map((group) => {

        const groupSections = group.sections.map((section) => {
            const sectionOptions = section.options.map((option) => mapFunc({ option, section }));
            return {
                ...section,
                options: sectionOptions,
            };
        });

        return {
            ...group,
            sections: groupSections,
        };
    });

    return {
        ...options,
        groups,
    };
};

export const returnNewOptions = (initialOptions, updatedOption, isSelected, isIndirectChange, hasVrsChanges) => {
    const updatedOptions = mapOptions(initialOptions, ({ option, section }) => {
        if (option.vehicleOptionId === updatedOption.vehicleOptionId) {
            return {
                ...option,
                isIndirectChange,
                isSelected,
            };
        }

        if (section.required && section.optionSectionName === updatedOption.categoryGroup) {
            return {
                ...option,
                isSelected: false,
            };
        }
        return {
            ...option,
            isIndirectChange: !hasVrsChanges ? false : option?.isIndirectChange,
        };

    });

    return updatedOptions;
}

export const compareArrays = (initialOptions, vrsOptions) => {
    const vrsOptId = vrsOptions.length ? vrsOptions.map((opt) => opt?.vehicleOptionId) : [];
    const initOptId = initialOptions.length ? initialOptions.map((opt) => opt?.vehicleOptionId) : [];
    const vrsAddOptions = vrsOptions.length ? vrsOptions.filter((opt) => !initOptId.includes(opt?.vehicleOptionId)) : [];
    const vrsRemoveOptions = initialOptions.length ? initialOptions.filter((opt) => !vrsOptId.includes(opt?.vehicleOptionId)) : [];

    return { vrsRemoveOptions, vrsAddOptions };
};