/* eslint-disable no-console,consistent-return */
import { removeUserApiShoppedVehicles } from '../../../fetchers';

export default function removeOwnedVehicleActionCreator(shoppedVehicleDuck) {
    return ({
        itemIndex,
        onRemoveSuccess = () => { },
        onRemoveFailure = () => { },
    }) => async (dispatch, getState) => {
        const vehicles = shoppedVehicleDuck.selectors.getShoppedVehicles(getState());
        await removeUserApiShoppedVehicles(vehicles[itemIndex].consumerVehicleId).then(() => {
            // update store only when delete successful
            const deletedItem = vehicles.splice(itemIndex, 1);
            dispatch(shoppedVehicleDuck.creators.hydrateShoppedVehicle(vehicles));

            onRemoveSuccess();
            return deletedItem;
        }).catch((error) => {
            onRemoveFailure();
            throw Error(error);
        });
    };
}