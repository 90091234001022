import { createAction } from '@atc/modular-redux';

// Action Creators
import getYears from './getYearsCreator';
import getMakes from './getMakesCreator';
import getModels from './getModelsCreator';
import getVinLpData from './getVinLpDataCreator';

export default (duck) => ({
    setPickerType: createAction(duck.types.SET_PICKER_TYPE),
    setYears: createAction(duck.types.SET_YEARS),
    setMakes: createAction(duck.types.SET_MAKES),
    setModels: createAction(duck.types.SET_MODELS),
    setSelectedYear: createAction(duck.types.SELECT_YEAR),
    setSelectedMake: createAction(duck.types.SELECT_MAKE),
    setSelectedModel: createAction(duck.types.SELECT_MODEL),
    setZip: createAction(duck.types.SET_ZIPCODE),
    setMileage: createAction(duck.types.SET_MILEAGE),
    setYMMError: createAction(duck.types.SET_YMM_ERROR),
    setZipError: createAction(duck.types.SET_ZIP_ERROR),
    setVinLpData: createAction(duck.types.SET_VIN_LP_DATA),
    getYears: getYears(duck),
    getMakes: getMakes(duck),
    getModels: getModels(duck),
    getVinLpData: getVinLpData(duck),
    resetState: createAction(duck.types.RESET_STATE),
});
