import React from 'react';
import Head from '@bonnet/core/head';
import { withDevice } from '@bonnet/core/device';
import { connect } from 'react-redux';
import { getAuthScripts } from '@kbbsdk/global-sdk/navigation/web';

const GlobalHeadContainer = ({
    meta = {},
    isKBB,
    usewebcomponent,
    userwcbranch,
}) => {
    return (
        <Head>
            <title>
                {meta.title}
            </title>
            <meta
                name="description"
                content={meta.descriptionName}
            />
            <link
                rel="canonical"
                href={meta.canonicalUrl}
            />
            <meta
                property="og:title"
                content={meta.title}
            />

            <meta
                property="og:description"
                content={meta.descriptionName}
            />

            <meta
                property="og:type"
                content="website"
            />

            <meta
                property="og:site_name"
                content={meta.siteName}
            />

            <meta
                property="og:url"
                content={meta.canonicalUrl}
            />

            <link
                rel="canonical"
                href={meta.canonicalUrl}
            />
            <link
                rel="preload"
                as="font"
                type="font/woff2"
                href="https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC3gnD_vx3rCs.woff2"
                crossOrigin="anonymous"
            />
            <link
                rel="preload"
                as="font"
                type="font/woff2"
                href="https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_bZF3gnD_vx3rCs.woff2"
                crossOrigin="anonymous"
            />
            <link
                rel="preload"
                as="font"
                type="font/woff2"
                href="https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_c5H3gnD_vx3rCs.woff2"
                crossOrigin="anonymous"
            />
            <link
                rel="preload"
                as="font"
                type="font/woff2"
                href="https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFVZ0bf8pkAg.woff2"
                crossOrigin="anonymous"
            />
            {
                !isKBB ? (
                    <>
                        <style type="text/css">
                            {`
                        @font-face{font-family:Roboto;font-style:normal;font-weight:300;src:local('Roboto Light'),local('Roboto-Light'),url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5fBBc4AMP6lQ.woff2) format('woff2'),url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5fBBc-.woff) format('woff'),url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fBBc9.ttf) format('truetype');font-display:optional}
                        @font-face{font-family:Roboto;font-style:normal;font-weight:400;src:local('Roboto'),local('Roboto-Regular'),url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2) format('woff2'),url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu4mxM.woff) format('woff'),url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4mxP.ttf) format('truetype');font-display:optional}
                        @font-face{font-family:Roboto;font-style:normal;font-weight:700;src:local('Roboto Bold'),local('Roboto-Bold'),url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlfBBc4AMP6lQ.woff2) format('woff2'),url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlfBBc-.woff) format('woff'),url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfBBc9.ttf) format('truetype');font-display:optional}
                        @font-face {font-family: 'Montserrat';font-style: normal;font-weight: 500;font-display: optional;src: local('Montserrat Medium'), local('Montserrat-Medium'), url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC3gnD_vx3rCs.woff2) format('woff2');unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
                        @font-face {font-family: 'Montserrat';font-style: normal;font-weight: 600;font-display: optional;src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_bZF3gnD_vx3rCs.woff2) format('woff2');unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
                        @font-face {font-family: 'Montserrat';font-style: normal;font-weight: 800;font-display: optional;src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'), url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_c5H3gnD_vx3rCs.woff2) format('woff2');unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
                        @font-face {font-family: 'Open Sans';font-style: normal;font-weight: 400;font-display:optional;src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFVZ0bf8pkAg.woff2) format('woff2');unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
                    `}
                        </style>
                    </>
                ) : (
                    <>
                    </>
                )
            }
            <script
                type="module"
                src="https://auth-dist.awscconsumerinfo.autotrader.com/prod/v0/cai-authentication-web-components.esm.js"
            />
            <script
                src="https://hydra.kbb.com/rmr/risk-major-repair-web-component.js"
            />
            {isKBB && getAuthScripts({ usewebcomponent, userwcbranch })}
        </Head>
    );
};

const mapStateToProps = (state) => ({
    meta: state?.domain?.myatc?.page?.meta || '',
});

export default withDevice(connect(mapStateToProps, undefined)(GlobalHeadContainer));
