import fetchFromBFF from '../utilities/fetchFromBFF.js';
import { getJSON, rejectWithError } from '../utilities/fetchHandlers.js';

export default (brand) => fetchFromBFF('/account/auth-user/saved-listings',
    {
        headers: {
            accesstoken: 'temptoken',
            idtoken: 'temptoken',
        },
        query: {
            brand,
        },
    }).then(getJSON)
    .catch(rejectWithError);
