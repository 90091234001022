export function ascend(set, key) {
    return set.sort((a, b) => {
      if (a[key] > b[key]) {
        return 1;
      } else if (a[key] < b[key]) {
        return -1;
      }
  
      return 0;
    })
  }
  
  export function descend(set, key) {
    return set.sort((a, b) => {
      if (a[key] < b[key]) {
        return 1;
      } else if (a[key] > b[key]) {
        return -1;
      }
  
      return 0;
    })
  }