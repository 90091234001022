import { fetchJSON } from '@bonnet/core/fetch';

export default async (consumerId, vehicleId) => {
    try {
        const data = await fetchJSON('/account/light-user/pricing-report', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: {
                consumer_id: consumerId,
                vehicle_id: vehicleId,
                notification_type: 'EMAIL',
            },
        });

        return data;

    } catch (err) {
        console.error('userApiSubscribePricingReport Error:::', err);
    }
    return null;
};
