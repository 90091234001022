import { MAX_RESULT } from "../services/service-utilities/researchableDataLoader";
import { filterByEligibleVehicleClass, sort } from "../utilities";
import { fetchJSON } from '@bonnet/core/fetch';

export default async (args) => {
    const {
        vehicleClass = 'UsedCar',
        makeId
    } = args || {};

    try {
        let years;

        if (makeId) {
            years = await fetchJSON(
                `/account/services/researchableReferenceDataService/years?makeid=${makeId}&limit=${MAX_RESULT}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                    },
                }
            )
        } else {
            years = await fetchJSON(
                `/account/services/researchableReferenceDataService/years?limit=${MAX_RESULT}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                    },
                }
            )
        }
        
        return sort.descend(filterByEligibleVehicleClass(years, vehicleClass), 'yearId')
          .map(({ yearId }) => ({ id: yearId }));
    } catch (err) {
        console.error('years ', err);
    }
    return null;
};
