import fetchFromBFF from '../utilities/fetchFromBFF';

export default async (searchBookmarkId) => {
    const inventory = {
        searchBookmarkId,
        notificationType: 'EMAIL',
    };

    const response = await fetchFromBFF('/account/auth-user/new-inventory', {
        method: 'POST',
        headers: {
            accesstoken: 'temptoken',
            idtoken: 'temptoken',
        },
        body: JSON.stringify(inventory),
    });

    if (response.ok) {
        return response.json();
    }
    throw new Error(response?.statusText, { cause: response });
};
