import { createAction } from '@atc/modular-redux';

// Action Creators

import getSavedSearches from './getSavedSearchesCreator';
import { updateSavedSearchesNotesActionCreator, updateSavedSearchesAlertsActionCreator } from './updateSavedSearchesActionCreator';
import removeSavedSearchActionCreator from './removeSavedSearchCreator';
import deleteAllSavedSearchesAlertsActionCreator from './deleteAllSavedSearchesAlertsActionCreator';

export default (duck) => ({
    hydrateSavedSearches: createAction(duck.types.HYDRATE_SAVED_SEARCHES),
    getSavedSearches: getSavedSearches(duck),
    updateSavedSearchesNotes: updateSavedSearchesNotesActionCreator(duck),
    updateSavedSeachesAlerts: updateSavedSearchesAlertsActionCreator(duck),
    removeSavedSearch: removeSavedSearchActionCreator(duck),
    deleteAllSavedSearchesAlerts: deleteAllSavedSearchesAlertsActionCreator(duck),
});
