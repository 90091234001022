import { isEqual } from 'lodash';

const customCompare = (prev, next) => {
    if (typeof prev === 'object') {
        return isEqual(prev, next);
    }
    return prev === next;
};

const memoizeCustomComparison = (keys) => (prevProps, nextProps) => {
    if (keys) {
        return keys.every((key) => customCompare(prevProps[key], nextProps[key]));
    }

    // eslint-disable-next-line no-unused-vars
    const { actions, ...comparables } = prevProps;
    return Object.keys(comparables).every((key) => customCompare(prevProps[key], nextProps[key]));

};

export default memoizeCustomComparison;
