import getConfig from '@bonnet/core/config';

const normalizeTrim = (str) => str.toLowerCase()
    .replace(/(^-plus|^plus-)/, '+') // Kia Soul `+` edge case
    .replace(/(^-exclaim|^exclaim-)/, '!') // Kia Soul `!` edge case
    .replace(/[^+!a-z0-9]/g, '');
export const matchTrim = (a, b) => normalizeTrim(a) === normalizeTrim(b);

export const friendlyUrl = (param) => param
    ?.replace(/[\u0300-\u036f]/g, '') // Remove illegal characters
    .replace(/\s+|\/+|\.+/g, '-') // Change whitespace, slashes and periods to dashes
    .replace('&amp;-', '') // remove &amp;
    .toLowerCase() // Change to lowercase
    .replace(/[^a-z0-9-]/g, ''); // Remove anything that is not a letter, number or dash

export const formatString = (str) => (str ? str.replace(/\s+/g, '-').toLowerCase() : '');

export const generateListingsUrl = ({ year, make, model }) => {
    const { publicRuntimeConfig } = getConfig();
    const { kbbBaseUrl } = publicRuntimeConfig;

    const makeName = friendlyUrl(make);
    const modelName = friendlyUrl(model);

    return {
        usedCarDealer: `${kbbBaseUrl}/cars-for-sale/used/${year}/${makeName}/${modelName}/?sellerTypes=d`,
        usedCarPrivateParty: `${kbbBaseUrl}/cars-for-sale/used/${year}/${makeName}/${modelName}/?sellerTypes=p`,
        usedCpo: `${kbbBaseUrl}/cars-for-sale/certified/${year}/${makeName}/${modelName}/?sellerTypes=d`,
    };
};

export function generateShoppersYmmtUrl({ year, make, model, trim }) {
    const { publicRuntimeConfig } = getConfig();
    const { kbbBaseUrl } = publicRuntimeConfig;

    return `${kbbBaseUrl}/${friendlyUrl(make)}/${friendlyUrl(model)}/${year}/${friendlyUrl(trim)}/`;
}

export const generateSearchParamString = (...args) => {
    const searchParams = new URLSearchParams();

    // Destructure arguments into an object
    const params = args[0] || {};

    // Iterate through parameters and append
    for (const [key, value] of Object.entries(params)) {
        value && searchParams.append(key, value.toString().toLowerCase());
    }

    return searchParams.toString();
};

export function generateOptionsQuery(options) {
    // oldOptions: ['12345','12346'];
    // newOptions: ['12345|true','12346|false'];
    const newOptions = options.join('').includes('|');

    if (newOptions) {
        return options.reduce((acc, curr, i) => {
            const ifLast = i === options.length - 1;
            return `${acc}${curr}${ifLast ? '' : '|'}`;
        }, '',);
    }
    return options.reduce((acc, curr, i) => {
        const ifLast = i === options.length - 1;
        return `${acc}${curr}|true${ifLast ? '' : '|'}`;
    }, '',);

}
