import fetch from '@bonnet/core/fetch/fetch';
import { priceAdvisorFetcher, updateUserApiShoppedVehicle } from '../../fetchers';
import shoppedVehicleDuck from './index.js';
import { parseParamFromUrl } from '../../utilities';

export async function updateUserApiZipCode(getState, consumerVehicleId, updatedZipCode) {
    const userApiFormattedVehicles = shoppedVehicleDuck.selectors.getUserApiFormattedShoppedVehicles(getState());
    const vehicle = userApiFormattedVehicles.find((item) => item.vehicleid === consumerVehicleId);
    vehicle.vehicle_preferences.zipcode = updatedZipCode;
    await updateUserApiShoppedVehicle(vehicle);
}

export async function updateUserApiMileage(getState, consumerVehicleId, updatedMileage) {
    const userApiFormattedVehicles = shoppedVehicleDuck.selectors.getUserApiFormattedShoppedVehicles(getState());
    const vehicle = userApiFormattedVehicles.find((item) => item.vehicleid === consumerVehicleId);
    vehicle.vehicle_preferences.mileage = updatedMileage;
    await updateUserApiShoppedVehicle(vehicle);
}

export async function updateUserApiCondition(getState, consumerVehicleId, updatedCondition) {
    const userApiFormattedVehicles = shoppedVehicleDuck.selectors.getUserApiFormattedShoppedVehicles(getState());
    const vehicle = userApiFormattedVehicles.find((item) => item.vehicleid === consumerVehicleId);
    vehicle.vehicle_preferences.condition = updatedCondition;
    return updateUserApiShoppedVehicle(vehicle);
}

// First level only
/* eslint-disable camelcase */
function formatVehiclePreferencesFromConsumerVehicle(vehicle_preferences) {
    return Object.keys(vehicle_preferences).reduce((obj, key) => {
        const newKey = key.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
        return { ...obj, [newKey]: vehicle_preferences[key] };
    }, {});
}

export function shoppedDuckToUserApiMapper(vehicle) {
    return {
        kbb_make_id: vehicle?.consumerVehicleData?.kbbMakeId,
        kbb_make_name: vehicle?.consumerVehicleData?.kbbMakeName,
        kbb_model_id: vehicle?.consumerVehicleData?.kbbModelId,
        kbb_model_name: vehicle?.consumerVehicleData?.kbbModelName,
        kbb_trim_name: vehicle?.consumerVehicleData?.kbbTrimName,
        save_type: vehicle?.consumerVehicleData?.saveType,
        vehicleid: vehicle?.consumerVehicleId,
        consumerid: vehicle?.consumerId,
        kbb_vehicle_id: vehicle?.consumerVehicleData?.kbbVehicleId,
        save_name: vehicle?.consumerVehicleData?.saveName,
        year: vehicle?.consumerVehicleData?.year,
        vehicle_preferences: {
            kbb_vehicle_options: vehicle?.consumerVehicleData?.kbbVehicleOptions,
            zipcode: vehicle?.consumerVehicleData?.zipcode,
            condition: vehicle?.consumerVehicleData?.condition,
            mileage: vehicle?.consumerVehicleData?.mileage,
            vehicle_color: vehicle?.consumerVehicleData?.vehicleColor,
            new_or_used: vehicle?.consumerVehicleData?.newOrUsed,
        },
        valuation: vehicle?.consumerVehicleData?.valuation,
    };
}

/* eslint-disable camelcase */
export function userApiToShoppedDuckMapper(userApiShoppedVehicle) {
    const { vehicleid = '', consumerid = '', vehicle_preferences = {}, ...rest } = userApiShoppedVehicle;
    const formattedVehicleDetailsFromconsumerVehicle = formatVehiclePreferencesFromConsumerVehicle(vehicle_preferences);

    return {
        consumerVehicleId: vehicleid,
        consumerId: consumerid,
        consumerVehicleData: {
            ...formatVehiclePreferencesFromConsumerVehicle(rest),
            ...formattedVehicleDetailsFromconsumerVehicle,
        },
        mileage: formattedVehicleDetailsFromconsumerVehicle?.mileage,
        zipcode: formattedVehicleDetailsFromconsumerVehicle?.zipcode,
    };
}

export function researchableApiDataToShoppedDuckMapper(vehicleResearchableApiData) {
    return {
        defaultImage: vehicleResearchableApiData?.defaultImage,
        shortName: vehicleResearchableApiData?.name,
        ...vehicleResearchableApiData,
    };
}

export function priceAdvisorApiDataToShoppedDuckMapper(vehiclePriceAdvisorApiData) {
    return { ...vehiclePriceAdvisorApiData };
}

export function consumerReviewsDataToShopperDuckMapper(consumerReviewData) {
    return { ...consumerReviewData };
}

export function routerServiceDataToShoppedDuckMapper(routerServiceApiData) {
    return {
        ymmUrl: routerServiceApiData,
    };
}

export async function getResearchableApiData(kbbVehicleIds) {
    if (kbbVehicleIds.length === 0) return [];
    const researchableApiResponse = await fetch('/account/services/researchableVehicleData', { query: kbbVehicleIds });
    return researchableApiResponse.json();
}

export async function getPriceAdvisorApiData(vehiclesFromUserApi) {
    if (!vehiclesFromUserApi?.length === 0) return [];

    return priceAdvisorFetcher({ vehicles: vehiclesFromUserApi, shoppersVehicle: true });
}

// shoppers data from stash has a url which contains intent, we need to parse that in order to save new or used type correctly
export function parseIntentTypeFromStashUrl(url) {
    const stashUrl = new URL(url);
    const params = stashUrl.searchParams;

    return params.get('intent') || '';
}

export function formatStashDataForSave(stashData, zip, intent) {
    const conditionForUsed = parseParamFromUrl(stashData.url, 'condition') || 'good';

    return {
        save_name: stashData.name || '',
        save_type: 'YMMT',
        year: stashData.yearId || '',
        kbb_vehicle_id: stashData.vehicleId.toString() || '',
        kbb_make_id: stashData.makeId.toString() || '',
        kbb_model_id: stashData.modelId.toString() || '',
        vehicle_preferences: {
            kbb_vehicle_options: [],
            zipcode: zip || '92618',
            condition: intent === 'buy-new' ? null : conditionForUsed,
            mileage: parseParamFromUrl(stashData.url, 'mileage') || null,
            new_or_used: intent === 'buy-new' ? 'NEW' : 'USED',
        },
    };
}

export const formatPEDataForSave = (peData, zip) => ({
    save_name: peData.name || '',
    save_type: 'YMM',
    year: peData.yearId || '',
    kbb_vehicle_id: peData.vehicleId.toString() || '',
    kbb_make_id: peData.makeId || '',
    kbb_model_id: peData.modelId || '',
    vehicle_preferences: {
        kbb_vehicle_options: [],
        zipcode: zip || '92618',
        condition: null,
        mileage: null,
        new_or_used: 'NEW',
    },
});

export const formatVehicleDetails = (vehicles, profileZip) => {
    let formattedVehicles = vehicles;

    if (profileZip.length) {
        formattedVehicles = vehicles.map((v) => {
            const { vehicle_preferences } = v;

            return {
                ...v,
                vehicle_preferences: {
                    ...vehicle_preferences,
                    zipcode: vehicle_preferences?.zipcode ? vehicle_preferences.zipcode : profileZip,
                },
            };
        });
    }

    // Filter out vehicles with no zip code
    return formattedVehicles.filter((v) => v.vehicle_preferences.zipcode);
};
