import fetchFromBFF from '../utilities/fetchFromBFF';
import { getJSON, rejectNonOkResponses, handle404OrRejectWithError } from '../utilities/fetchHandlers.js';

export default (subscription) => fetchFromBFF(`/account/auth-user/new-inventory/id/${subscription.subscriptionId}`, {
    method: 'DELETE',
    headers: {
        accesstoken: 'temptoken',
        idtoken: 'temptoken',
    },
    body: JSON.stringify({
        version: subscription.subscriptionVersion,
    }),
}).then(rejectNonOkResponses)
    .then(getJSON)
    .catch(handle404OrRejectWithError);
