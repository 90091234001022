import { Google, Pixall, getBrandUnit } from 'reaxl-analytics-handlers';
import { USER_ACCOUNT_EVENT_SOURCE, getPixallEventLabel } from './utils';

export const PAGE_ID = {
    kbb: 'mykbb',
    atc: 'myat',
};

export const CommunicationPrefsClick = (eventLabel) => ({
    state = {},
    dataLayer = {},
} = {}, option) => {

    const { brand = '' } = state;
    const categoryFeature = getBrandUnit(brand);

    Google.sendClickEvent({
        eventElement: categoryFeature,
        eventResult: eventLabel,
    }, option);

    Pixall.sendClickEvent({
        eventSource: USER_ACCOUNT_EVENT_SOURCE,
        label: getPixallEventLabel(eventLabel),
        pageType: categoryFeature,
        feature: categoryFeature,
        hasListingType: false,
    }, dataLayer, option);

    return {};
};
