/* eslint-disable no-unreachable */
import { Duck } from '@atc/modular-redux';

// Selectors
import selectors from './ownedVehicleDuckSelectors';
import reducer from './ownedVehicleDuckReducer';
import creators from './actionCreators';

const defaultState = {
    vehicles: [],
    currentView: 'list', // 'addCar', 'editCar, 'removeCar', 'list'
    alert: {
        show: false,
        type: '',
    },
    vehiclesInitialLoad: false, // flag to determine if cars have been loaded initially
};

function ownedVehicleDuckCreator({
    namespace = '',
    store = 'ownedVehicle',
    storePath,
    env = 'qa',
    ...rest
} = {}) {

    return new Duck({
        ...rest,
        namespace,
        store,
        storePath,
        initialState: {
            ...defaultState,
        },
        consts: {},
        types: [
            'HYDRATE_OWNED_VEHICLE',
            'SELECTED_OWNED_VEHICLE_INDEX',
            'SELECTED_OWNED_VEHICLE_ID',
            'SELECTED_OWNED_VEHICLE_PA_DATA',
            'SELECTED_OWNED_VEHICLE_RECALL_DATA',
            'SELECTED_OWNED_VEHICLE_ROUTES',
            'SELECTED_OWNED_DEMAND_BAR_DATA',
            'REMOVE_OWNED_VEHICLE',
            'SET_PAGE_ALERT',
            'VEHICLES_INITIAL_LOAD',
        ],

        creators: (duck) => creators(duck),
        reducer,
        selectors,
    });
}

export default ownedVehicleDuckCreator();
