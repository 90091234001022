import { bodyStylesMapper } from './bodyStylesMapper';
import optionsMapper from './optionsMapper';

export const RESEARCHABLE_MAPPERS = {
    BODYSTYLES: 'bodyStylesMapper',
};

export const RESEARCHABLE_API_MAP = {
    years: {
        apiPath: 'api/reference/years',
    },
    makes: {
        apiPath: 'api/reference/makes',
    },
    models: {
        apiPath: 'api/reference/models',
    },
    bodystyles: {
        apiPath: 'api/reference/vehicles',
        dataMapper: bodyStylesMapper,
    },
    vehicles: {
        all: {
            apiPath: 'api/reference/vehicles/',
        },
        byId: {
            apiPath: 'api/reference/vehicle/id/',
        },
        similarVehicles: {
            apiPath: '/vehicles/getsimilarvehicles',
        },
    },
    options: {
        apiPath: (queryParams) => `api/reference/vehicleoptions/vehicleId/${queryParams?.vehicleId}`,
        dataMapper: optionsMapper,
    },
};
