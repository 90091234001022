const lowerCaseEqual = (a, b) => a?.toLowerCase() === b?.toLowerCase();

const containsLowerCase = (list, targetItem) => {
  return list.find((item) => lowerCaseEqual(item, targetItem))
}

export default (dataList, vehicleClass) => {
  if (vehicleClass && vehicleClass.length > 0 && dataList) {
    return dataList.filter(({ eligibleVehicleClasses}) => containsLowerCase(eligibleVehicleClasses, vehicleClass));    
  }

  return dataList;
}