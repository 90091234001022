export default (state, action, { initialState, types }) => {
    const { type, payload } = action;

    switch (type) {
        case types.SET_VEHICLE_ID: {
            return {
                ...state,
                vehicleId: payload,
            };
        }
        case types.SET_VIN_LP_DATA: {
            const {
                ...rest
            } = payload;
            return {
                ...state,
                ...rest,
            };
        }
        case types.HYDRATE_OPTIONS: {
            return {
                ...state,
                options: {
                    ...payload,
                },
            };
        }
        case types.SET_OPTIONS_TYPE: {
            return {
                ...state,
                optionsType: payload,
            };
        }
        case types.SET_COLOR_ID: {
            return {
                ...state,
                colorOptionId: payload,
            };
        }
        case types.SET_SELECT_HISTORY: {
            return {
                ...state,
                selectHistory: payload,
            };
        }
        case types.SET_ERROR: {
            return {
                ...state,
                alertError: payload,
            };
        }
        case types.SET_CONDITION: {
            return {
                ...state,
                condition: payload,
            };
        }
        case types.SET_EXT_COLOR: {
            return {
                ...state,
                extColor: payload,
            };
        }
        case types.CLEAR_STATE: {
            return {
                ...state,
                optionsType: '',
                options: [],
                selectHistory: '',
                colorOptionId: '',
                extColor: '',
            };
        }
        default: {
            return state;
        }
    }
};
