export const isYmmFormValid = (formData) => {
    const {
        selectedYear,
        selectedMake,
        selectedModel,
        zip,
        mileage,
        error
    } = formData;

    return !error && !!(selectedYear && selectedMake && selectedModel && zip && mileage);
}