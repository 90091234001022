export const ADDCAR_CURRENTSTEPS = {
    VEHICLE_PICKER: 'vehiclePicker',
    STYLES_CATEGORIES: 'stylesCategories',
    OPTIONS: 'options',
    VIN_DETAILS: 'vinDetails',
    VIN_LP_OPTIONS: 'vinlpOptions',
};

export const YMM_PICKER_FIELDS = {
    YEAR: 'year',
    MAKE: 'make',
    MODEL: 'model',
    ZIP: 'zipcode',
    MILEAGE: 'mileage',
};
