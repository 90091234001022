// import { sendCarIOwnClickEvent, sendCarIOwnImpression } from 'reaxl-analytics-handlers/es/handlers/myCarsPageEvents';
import { carsIOwnClick, carsIOwnImpression } from '../AddEditCarHandlers';

const ADDCAR_STEPS = [
    'Entry',
    'Details',
    'Options',
    'Condition',
];

// Add Car Impressions
const allAddCarImpressions = {};

ADDCAR_STEPS.forEach((step, i) => {
    const stepLabel = `::${step.toLowerCase()}`;

    allAddCarImpressions[`addCarImpression${step}`] = carsIOwnImpression(`add-a-car::$pathType${stepLabel}`);
});

// Edit Car Impressions
const editCarImpression = carsIOwnImpression('edit-car::$pathType::options');

// Recently Viewed or Recommended Card Click
const addCarRecentlyViewedClick = carsIOwnClick('cta::add-this-car');

// Add Car Clicks
const addCarYmmSelectionClick = carsIOwnClick('radio::ymm');
const addCarVinSelectionClick = carsIOwnClick('radio::vin');
const addCarLpSelectionClick = carsIOwnClick('radio::lp');
const addCarPathTypeClick = carsIOwnClick('radio::$pathType');
const addCarEntryGoClick = carsIOwnClick('cta::go');
const addCarBackClick = carsIOwnClick('cta::back');
const addCarNextClick = carsIOwnClick('cta::next');
const addCarSaveClick = carsIOwnClick('cta::save');

// Edit Car Clicks
const editCarBackClick = carsIOwnClick('cta::back::edit-car');
const editCarSaveClick = carsIOwnClick('cta::save::edit-car');

// Remove Car Clicks
const removeCarSubmit = carsIOwnClick('cta::remove-this-car::$responses', '_cars-owned');
const removeCarCancel = carsIOwnClick('cta::cancel::remove-car-survey', '_cars-owned');

// Remove Car Impressions
const removeCarImpression = carsIOwnImpression('remove-car-survey', '_cars-owned');

export default {
    ...allAddCarImpressions,
    editCarImpression,
    addCarVinSelectionClick,
    addCarLpSelectionClick,
    addCarYmmSelectionClick,
    addCarPathTypeClick,
    addCarEntryGoClick,
    addCarRecentlyViewedClick,
    addCarBackClick,
    addCarNextClick,
    addCarSaveClick,
    editCarBackClick,
    editCarSaveClick,
    removeCarImpression,
    removeCarSubmit,
    removeCarCancel,
};
