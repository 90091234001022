import fetchOptions from '../../../fetchers/fetchOptions.js';
import {
    returnNewOptions,
    compareArrays,
    grabSelectedOptions,
} from '../../editCarDuckCreator/helpers.js';

export default function getUpdatedOptionsCreator(addCarDuck) {
    return ({
        updatedOption,
        isSelected,
        onSuccess = () => {},
        onError = () => {},
    }) => async (dispatch, getState) => {
        try {
            let updatedOptions = {};
            const options = addCarDuck.selectors.getOptions(getState());
            const selectHistory = addCarDuck.selectors.getSelectHistory(getState());
            const vehicleId = addCarDuck.selectors.getVehicleId(getState());
            const updatedSelectHistory = `${selectHistory && `${selectHistory}|`}${updatedOption?.vehicleOptionId}|${isSelected}`;

            updatedOptions = returnNewOptions(options, updatedOption, isSelected, false, false);

            dispatch(addCarDuck.creators.setSelectHistory(updatedSelectHistory));

            if (updatedOption) {
                const newOptions = await fetchOptions({ vehicleId, selectHistory: updatedSelectHistory });

                const { vrsSelectedOptions } = newOptions;
                const { vrsRemoveOptions, vrsAddOptions } = compareArrays(grabSelectedOptions(updatedOptions), vrsSelectedOptions);

                // Persist colors b/c succeeding queries don't return colors
                updatedOptions = { colors: options.colors, ...newOptions };

                if (vrsAddOptions.length) {
                    vrsAddOptions.forEach((opt) => {
                        if (opt?.vehicleOptionId !== updatedOption?.vehicleOptionId) {
                            updatedOptions = returnNewOptions(newOptions, opt, true, true, true);
                        }
                    });
                }

                if (vrsRemoveOptions.length) {
                    vrsRemoveOptions.forEach((opt) => {
                        if (opt?.vehicleOptionId !== updatedOption?.vehicleOptionId) {
                            updatedOptions = returnNewOptions(newOptions, opt, false, true, true);
                        }
                    });
                }
            }

            dispatch(addCarDuck.creators.hydrateOptions(updatedOptions));

            onSuccess();

        } catch (error) {

            onError();
            throw new Error(error);
        }
    };
}
