import React, { useEffect } from 'react';
import { mapDispatchToActionProp } from '@atc/modular-redux';
import { connect } from 'react-redux';
import { Spinner } from '@argo/ui-elements';
import { analyticsDuck, userDuck } from '../ducks';

const AbstractPageContainer = (props) => {
    const {
        actions,
        Component,
        isKBB,
    } = props;

    // eslint-disable-next-line camelcase
    useEffect(() => {
        actions.addDomEventTagging();
        actions.setCachedTextAlerts();
    }, []);

    return (
        <div className="page-container">
            <div className={isKBB ? 'main-container' : 'container'}>
                <Component {...props} />
            </div>
            {isKBB && <Spinner className="page-spinner" />}
        </div>
    );
};

const mapDispatchToProps = mapDispatchToActionProp({
    setCachedTextAlerts: userDuck.creators.setCachedTextAlerts,
    addDomEventTagging: analyticsDuck.creators.addDomEventTagging,
});

export default connect(undefined, mapDispatchToProps)(AbstractPageContainer);
