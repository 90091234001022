import { getKbbGlobalNavConfiguration } from '@atc/bonnet-reference';
import { kbbPageDataDuck } from '../ducks';

export default function withKBBPageData() {
    return async (ctx) => {
        const { brand } = ctx.data;
        const isKbb = brand === 'kbb';

        // only load in KBB brand and in SSR mode
        if (typeof window === 'undefined' && isKbb) {
            try {
                // get KBB global navigation configuration
                const navigationConfiguration = await getKbbGlobalNavConfiguration();
                ctx.store.dispatch(kbbPageDataDuck?.creators?.setKeys({
                    navigation: navigationConfiguration,
                }));
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Error fetching kbb global navigation configuration: ', error);
            }
        }
    };
}
