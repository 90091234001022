/* eslint-disable no-console,consistent-return */
import {
    getPriceAdvisorApiData,
    geOwnersAppRoutes
} from '../helpers.js';


export default function getSelectedVehiclePADataCreator(ownedVehicleDuck) {
    return () => async (dispatch, getState) => {
        const selectedVehicle = ownedVehicleDuck.selectors.getSelectedOwnedVehicle(getState());
        
        try {
            const selectedVehiclePAData = ownedVehicleDuck.selectors.ownedVehiclePADataSelector(getState());
            
            if (selectedVehiclePAData?.privatePartyValue || selectedVehiclePAData?.tradeInValue) {
                return;
            }

            const priceAdvisorApiData = await getPriceAdvisorApiData(selectedVehicle?.vehicleOwnershipData);
    
            if (priceAdvisorApiData) {
                dispatch(ownedVehicleDuck.creators.setSelectedOwnedVehiclePAData({
                    vehicleOwnershipId: selectedVehicle.vehicleOwnershipId, 
                    priceAdvisorData: priceAdvisorApiData
                }));
            }
        } catch (error) {
            dispatch(ownedVehicleDuck.creators.setSelectedOwnedVehiclePAData({
                vehicleOwnershipId: selectedVehicle.vehicleOwnershipId, 
                priceAdvisorData: {
                    error: error
                }
            }));

            throw Error(`getSelectedVehiclePADataCreator: error fetching Price Advisor data ${error}`);
        }

        try {
            const routesData = await geOwnersAppRoutes(selectedVehicle);

            if (routesData) {
                dispatch(ownedVehicleDuck.creators.setSelectedOwnedVehicleRoutes({
                    vehicleOwnershipId: selectedVehicle.vehicleOwnershipId, 
                    routes: routesData
                }));
            }
        } catch (error) {
            throw Error(`getSelectedVehiclePADataCreator: error fetching routes data ${error}`);
        }
    };
}