import hasDom from 'has-dom';

// eslint-disable-next-line import/prefer-default-export
export function getPersonalizationClientEnv() {
    if (hasDom()) {
        // TODO: replace with process.env.ENVIRONMENT when it works...
        // eslint-disable-next-line
        if ([   'www.autotrader.com', 'speedy.autotrader.com', 'myatc-staging.awsacs.autotrader.com', 'myatc-live.awsacs.autotrader.com'].includes(window.location.hostname)) {
            return 'prod';
        }
    }
    return 'qa';
}
