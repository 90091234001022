import { fetchBodyStyles } from '../../../fetchers/index.js';

export default function getBodyStylesCreator(stylesCategoriesDuck) {
    return ({
        yearId,
        makeName,
        modelName,
        onSuccess = () => {},
        onError = () => {},
    }) => async (dispatch) => {
        try {
            const data = await fetchBodyStyles({ yearId, makeName, modelName });

            if (data && data?.bodyStyles) {
                dispatch(stylesCategoriesDuck.creators.setBodyStyles(data.bodyStyles));
                onSuccess();
            } else {
                onError();
            }
            return data?.bodyStyles;
        } catch (error) {
            onError();
            throw new Error(error);
        }
    };
}