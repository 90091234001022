import fetchFromBFF from '../utilities/fetchFromBFF.js';

const getJSON = (response) => response.json();

const validateProfileFetcher = (body) => fetchFromBFF('/account-api/validateProfileForm', {
    method: 'POST',
    body,
}).then(getJSON);

export default validateProfileFetcher;
