/* eslint-disable no-console,consistent-return */
import { removeUserApiOwnedVehicles } from '../../../fetchers/index';

export default function removeOwnedVehicleActionCreator(ownedVehicleDuck) {
    return ({
        itemIndex,
        onRemoveSuccess = () => {},
        onRemoveFailure = () => {},
    }) => async (dispatch, getState) => {
        const { ownedVehicle: ownedVehicleState } = getState();
        const vehicles = ownedVehicleDuck.selectors.getOwnedVehicles(getState());
        const selectedOwnedVehicleId = vehicles[itemIndex].vehicleOwnershipId;

        await removeUserApiOwnedVehicles(vehicles[itemIndex].vehicleOwnershipId).then(() => {
            // update store only when delete successful
            vehicles.splice(itemIndex, 1);

            let nextIndex = 0;
            if (vehicles.length > 1) {
                if (itemIndex === 0) {
                    itemIndex = 0;
                } else {
                    nextIndex = vehicles[itemIndex + 1] ? itemIndex + 1 : itemIndex - 1;
                }
            }

            ownedVehicleState.vehicles = vehicles;
            ownedVehicleState.selectedOwnedVehicleIndex = nextIndex;
            delete ownedVehicleState.recallRepairData?.[selectedOwnedVehicleId];
            delete ownedVehicleState.demandBarData?.[selectedOwnedVehicleId];
            delete ownedVehicleState.routes?.[selectedOwnedVehicleId];
            delete ownedVehicleState.priceAdvisorData?.[selectedOwnedVehicleId];

            dispatch(ownedVehicleDuck.creators.removeVehicle({ ownedVehicleState }));
            dispatch(ownedVehicleDuck.creators.setSelectedOwnedVehicleId(vehicles[nextIndex]?.vehicleOwnershipId));

            onRemoveSuccess();
        }).catch((error) => {
            onRemoveFailure();
            throw Error(error);
        });
    };
}
