import { createSelector, DuckSelector } from '@atc/modular-redux';
import _get from 'lodash/get';
import { ownedDuckToUserApiMapper } from './helpers';

export default {
    getOwnedVehicles: (state) => _get(state, 'ownedVehicle.vehicles', {}),
    getPageAlert: (state) => _get(state, 'ownedVehicle.alert', {}),
    getPriceAdvisorData: (state) => _get(state, 'ownedVehicle.priceAdvisorData', {}),
    getRecallRepairData: (state) => _get(state, 'ownedVehicle.recallRepairData', {}),
    getRoutes: (state) => _get(state, 'ownedVehicle.routes', {}),
    getDemandBarData: (state) => _get(state, 'ownedVehicle.demandBarData', {}),
    getSelectedOwnedVehicleId: (state) => _get(state, 'ownedVehicle.selectedOwnedVehicleId', ''),
    getSelectedOwnedVehicleIndex: new DuckSelector((selectors) => createSelector(
        selectors.getOwnedVehicles,
        selectors.getSelectedOwnedVehicleId,
        (vehicles = [], selectedOwnedVehicleId) => vehicles?.findIndex(({vehicleOwnershipId}) => selectedOwnedVehicleId === vehicleOwnershipId) || 0
    )),
    getSelectedOwnedVehicle: new DuckSelector((selectors) => createSelector(
        selectors.getOwnedVehicles,
        selectors.getSelectedOwnedVehicleId,
        (vehicles = [], selectedOwnedVehicleId) => vehicles?.find(({vehicleOwnershipId}) => selectedOwnedVehicleId === vehicleOwnershipId) || null
    )),
    getOwnedVehicleIds: new DuckSelector((selectors) => createSelector(
        selectors.getOwnedVehicles,
        (vehicles = []) => vehicles.map((vehicle) => {
            if (typeof vehicle === 'object') {
                return vehicle.vehicleId;
            }

            return vehicle;
        })
    )),
    getOwnedVehicleCount: new DuckSelector((selectors) => createSelector(
        selectors.getOwnedVehicleIds,
        (ids) => ids.length
    )),
    getUserApiFormattedOwnedVehicles: new DuckSelector((selectors) => createSelector(
        selectors.getOwnedVehicles,
        (vehicles = []) => vehicles.map((vehicle) => ownedDuckToUserApiMapper(vehicle))
    )),
    getUserApiFormattedOwnedVehicle: new DuckSelector((selectors) => createSelector(
        selectors.getOwnedVehicles,
        selectors.getSelectedOwnedVehicleIndex,
        (vehicles = [], selectedOwnedVehicleIndex) => ownedDuckToUserApiMapper(vehicles?.[selectedOwnedVehicleIndex])
    )),
    ownedVehicleRoutesSelector: new DuckSelector((selectors) => createSelector(
        selectors.getRoutes,
        selectors.getSelectedOwnedVehicleId,
        (routes = {}, selectedOwnedVehicleId) => routes?.[selectedOwnedVehicleId]
    )),
    ownedVehiclePADataSelector: new DuckSelector((selectors) => createSelector(
        selectors.getPriceAdvisorData,
        selectors.getSelectedOwnedVehicleId,
        (priceAdvisorData = {}, selectedOwnedVehicleId) => priceAdvisorData?.[selectedOwnedVehicleId]
    )),
    ownedVehicleRecallRepairDataSelector: new DuckSelector((selectors) => createSelector(
        selectors.getRecallRepairData,
        selectors.getSelectedOwnedVehicleId,
        (recallRepairData = {}, selectedOwnedVehicleId) => recallRepairData?.[selectedOwnedVehicleId]
    )),
    ownedVehicleDemandBarDataSelector: new DuckSelector((selectors) => createSelector(
        selectors.getDemandBarData,
        selectors.getSelectedOwnedVehicleId,
        (demandBarData = {}, selectedOwnedVehicleId) => demandBarData?.[selectedOwnedVehicleId]
    )),
    hasOwnedCars: (state) => {
        const ownedVehicles = _get(state, 'ownedVehicle.vehicles', {}) || [];

        return ownedVehicles.length > 0;
    },
    getVehiclesInitialLoad: (state) => _get(state, 'ownedVehicle.vehiclesInitialLoad', {}),
};
