import { fetchJSON } from '@bonnet/core/fetch';
import { getHeaderAuthToken } from '../utilities/getHeaderAuthToken';

export default function fetchSavedSearches(brand) {

    return getHeaderAuthToken().then((res) => fetchJSON(
        '/account-api/searchbookmarks',
        {
            method: 'GET',
            credentials: Object.keys(res).length > 0 ? 'omit' : 'same-origin',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                ...res,
            },
            query: {
                brand,
            },
        }
    ));

}
