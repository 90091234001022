import { Google, Pixall, getBrandUnit } from 'reaxl-analytics-handlers';
import { USER_ACCOUNT_EVENT_SOURCE } from './utils';

export const PAGE_ID = {
    kbb: 'mykbb',
    atc: 'myat',
};

const PATH_TYPES = ['lp', 'vin', 'ymm'];

const getPageIdElement = (brand, tag = '_owners-funnel') => `${PAGE_ID[brand.toLowerCase()] || ''}${tag}`;
// eslint-disable-next-line no-confusing-arrow
const getEventLabel = (eventlabel, pathType, responseOptions) => {
    let eventLabel = eventlabel;

    if (PATH_TYPES.includes(pathType) && eventlabel.includes('$pathType')) {
        eventLabel = eventlabel.replace('$pathType', pathType);
    } else if (responseOptions && eventlabel.includes('$responses')) {
        eventLabel = eventlabel.replace('$responses', responseOptions);
    }

    return eventLabel;
}

export const carsIOwnClick = (eventLabel, pageTag) => ({
    data = {},
    state = {},
    dataLayer = {},
} = {}, option) => {

    const { brand = '' } = state;
    const {
        vehicleId, year, make, model, mileage, makeId, modelId, vin, withVin, lp, license, licensePlate, withLp, responseOptions,
        options: { chromeStyleId, bodyStyle, engineType } = {},
    } = data;
    const parsedLicensePlate = lp || license || licensePlate;
    const categoryFeature = getBrandUnit(brand);
    const pathType = ((parsedLicensePlate || withLp) && 'lp') || ((vin || withVin) && 'vin') || 'ymm';
    const parsedEventLabel = getEventLabel(eventLabel, pathType, responseOptions);

    Google.sendClickEvent({
        eventElement: categoryFeature,
        eventResult: parsedEventLabel,
        eventData: {
            vehicle: {
                catalog: dataLayer?.catalog,
                chromeStyleId: chromeStyleId?.toString(),
                vehicleId,
                year,
                makeId,
                makeName: make,
                modelId,
                modelName: model,
                bodyStyle,
                mileage: mileage?.toString(),
                fuelType: engineType,
                vehicleEntry: pathType,
            },
        },
    }, option);

    Pixall.sendClickEvent({
        eventSource: USER_ACCOUNT_EVENT_SOURCE,
        pageId: getPageIdElement(brand, pageTag),
        hasListingType: false,
        label: parsedEventLabel,
        pageType: categoryFeature,
        feature: categoryFeature,
        make,
        model,
        vehicleType: 'used',
        year,
        bodyStyle,
        vin,
        cdl: {
            eventProperties: {
                vehicle: {
                    fuelType: engineType,
                    legacyConfigurationId: vehicleId,
                    vehicleEntry: pathType,
                },
            },
        },
    }, dataLayer, option);

    return {};
};

export const carsIOwnImpression = (eventLabel, pageTag) => ({
    data = {},
    state = {},
    dataLayer = {},
} = {}, option) => {

    const { brand = '' } = state;
    const {
        vehicleId, year, make, model, mileage, makeId, modelId, vin, withVin, lp, license, licensePlate, withLp,
        options: { chromeStyleId, bodyStyle, engineType } = {},
    } = data;
    const parsedLicensePlate = lp || license || licensePlate;
    const categoryFeature = getBrandUnit(brand);
    const pathType = ((parsedLicensePlate || withLp) && 'lp') || ((vin || withVin) && 'vin') || 'ymm';
    const parsedEventLabel = getEventLabel(eventLabel, pathType);

    Pixall.sendImpression({ dataLayer }, {
        eventSource: USER_ACCOUNT_EVENT_SOURCE,
        eventType: 'loaded',
        hasListingType: false,
        pageId: getPageIdElement(brand, pageTag),
        label: parsedEventLabel,
        pageType: categoryFeature,
        feature: categoryFeature,
        make,
        model,
        vehicleType: 'used',
        year,
        bodyStyle,
        vin,
        cdl: {
            eventProperties: {
                vehicle: {
                    fuelType: engineType,
                    legacyConfigurationId: vehicleId,
                    vehicleEntry: pathType,
                },
            },
        },
    }, option);

    Google.sendImpressions(dataLayer, {
        eventElement: categoryFeature,
        eventResult: parsedEventLabel,
        eventData: {
            vehicle: {
                catalog: dataLayer?.catalog,
                chromeStyleId: chromeStyleId?.toString(),
                vehicleId,
                year,
                makeId,
                makeName: make,
                modelId,
                modelName: model,
                bodyStyle,
                mileage: mileage?.toString(),
                fuelType: engineType,
                vehicleEntry: pathType,
            },
        },
    }, option);

    return {};
};
