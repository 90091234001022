export const MAPPING_BOOLEAN_FIELD_WITH_FILTER = [{
    booleanField: 'hasHistoryReport',
    filter: 'With history report',
}, {
    booleanField: 'hasSpecialCashBack',
    filter: 'With cash-back offers',
}, {
    booleanField: 'hasSpecialFinancing',
    filter: 'With special finance offers',
}, {
    booleanField: 'hasSpecial',
    filter: 'With cash-back offers',
}, {
    booleanField: 'hasSpecial',
    filter: 'With special finance offers',
}, {
    booleanField: 'hasVideo',
    filter: 'With videos',
}, {
    booleanField: 'hasPhoto',
    filter: 'With photos',
}, {
    booleanField: 'hasPrice',
    filter: 'With prices',
}];

export const DEFAULT_MPG_RANGES = [
    {
        code: '10',
        name: '10 - 19 MPG',
    },
    {
        code: '20',
        name: '20 - 29 MPG',
    },
    {
        code: '30',
        name: '30 - 39 MPG',
    },
    {
        code: '40',
        name: '40+ MPG',
    },
];

export const MIN_YEAR = '1981';
