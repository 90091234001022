/* eslint-disable no-console,consistent-return */
import {
    getPriceAdvisorApiData,
} from '../helpers.js';


export default function getSelectedVehiclePADataCreator(shoppedVehicleDuck) {
    return ({
        itemIndex,
    }) => async (dispatch, getState) => {
        try {
            const vehicles = shoppedVehicleDuck.selectors.getShoppedVehicles(getState());
            const selectedVehicle = vehicles[itemIndex];

            // check if we already have PA data
            if (selectedVehicle.usedCarDealerValue 
                || selectedVehicle.usedCarPrivatePartyValue 
                || selectedVehicle.usedCpoValue
                || selectedVehicle.newCarDealerValue) {
                return;
            }

            const priceAdvisorApiData = await getPriceAdvisorApiData(selectedVehicle?.consumerVehicleData);
            if (priceAdvisorApiData) {
                const updatedVehicles = vehicles.map((vehicle) => {
                    if (vehicle.consumerVehicleId === selectedVehicle.consumerVehicleId) {
                        return { ...vehicle, ...priceAdvisorApiData };
                    }
                    return vehicle;
                });

                dispatch(shoppedVehicleDuck.creators.hydrateShoppedVehicle(updatedVehicles));
            }
        } catch (error) {
            throw Error(error);
        }
    };
}