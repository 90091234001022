/* eslint-disable no-console,consistent-return */
import { Cookies } from 'atc-js';
import { saveUserApiOwnedVehicles, getUserApiConsumer } from '../../../fetchers/index';
import { formatStashDataForSave, formatVehicleDetails } from '../helpers';

export default function saveStashVehicleActionCreator(ownedVehicleDuck) {
    return ({
        stashData,
        onSaveSuccess = () => {},
        onSaveFailure = () => {},
    }) => async (dispatch, getState) => {
        const { zip = '' } = await getUserApiConsumer();
        const cookies = new Cookies();
        const cookieZip = cookies.get('ZipCode');
        const finalZip = zip || cookieZip;
        const formattedVehicle = formatStashDataForSave(stashData, finalZip);

        await saveUserApiOwnedVehicles(formattedVehicle).then(() => {
            dispatch(ownedVehicleDuck.creators.getOwnedVehicles({  
                onSuccess: onSaveSuccess,
                onError:  onSaveFailure, 
            }));
        }).catch((error) => {
            console.error('stash save error:', error);
            onSaveFailure();
            throw Error(error);
        });
    };
}
