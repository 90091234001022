import _get from 'lodash/get';

export default {
    getCarToAdd: (state) => _get(state, 'addCar', {}),
    getCurrentStep: (state) => _get(state, 'addCar.currentStep', 'vehiclePicker'),
    getOptions: (state) => _get(state, 'addCar.options', {}),
    getVehicleId: (state) => _get(state, 'addCar.vehicleId', null),
    getOptionsType: (state) => _get(state, 'addCar.optionsType', null),
    getColorId: (state) => _get(state, 'addCar.colorOptionId', null),
    getAlertError: (state) => _get(state, 'addCar.alertError', null),
    getSelectHistory: (state) => _get(state, 'addCar.selectHistory', ''),
    getCondition: (state) => _get(state, 'addCar.condition', null),
    getExtColor: (state) => _get(state, 'addCar.extColor', ''),
};
