import { createAction } from '@atc/modular-redux';

// Action Creators
import getBodyStyles from './getBodyStylesCreator';

export default (duck) => ({
    setBodyStyles: createAction(duck.types.SET_BODYSTYLES),
    setSelectedCategory: createAction(duck.types.SELECT_CATEGORY),
    setSelectedStyle: createAction(duck.types.SELECT_STYLE),
    setSelectedVehicleId: createAction(duck.types.SELECT_VEHICLEID),
    setError: createAction(duck.types.SET_ERROR),
    getBodyStyles: getBodyStyles(duck),
    resetState: createAction(duck.types.RESET_STATE),
});
