/* eslint-disable no-console */

import { ATC_BRAND, KBB_BRAND } from 'reaxl-brand/lib/brands';

export default function adProcessor({
    brand = ATC_BRAND,
    inventory = {},
    vehicle = {},
    search = {},
    intent = 'all',
}) {
    let targeting = {};

    if (brand === KBB_BRAND) {
        const make = (vehicle?.make || inventory?.makeCode)?.toLowerCase().replace(/\W/gi, '') || '';
        const model = (vehicle?.model || inventory?.modelCode)?.toLowerCase().replace(/\W/gi, '') || '';
        targeting = {
            // cat: shortCategory,
            // subcat: subCategory || 'unk',
            dmk: make || 'nl', // same as 'mk' parameter, but needed for dealermake ads
            vehid: vehicle?.vehicleId?.toString() || 'nl',
            yr: (vehicle?.year || inventory?.year)?.toString() || 'nl',
            kmake: make || 'nl',
            kmod: `${make}_${model}`,
            // kbody: shortCategory,
            // abody: shortCategoryAtccode,
            // ksubseg: subCategory || 'unk',
            kveh: vehicle?.vehicleId?.toString() || 'nl',
            int: intent,
            md: `${model}_${make}`,
            mk: make || 'nl',
            mkid: vehicle?.makeId?.toString() || 'nl',
            mdlid: vehicle?.modelId?.toString() || 'nl',
            // amake: makeAtccode,
            // amod: formatCombinedField(makeAtccode, modelAtccode),
            // asubseg: getField(datalayer, 'info.categories.sub.atcCode'),
            // prcrn: getField(datalayer, 'info.vehicle.price'),
        };
    } else {
        const kmod = (inventory.makeCode + (inventory.seriesCode ? `_${inventory.seriesCode}` : `_${inventory.modelCode}`)).toLowerCase();
        const amod = (`${inventory.makeCode}_${inventory.makeCode}` + (inventory.seriesCode ? inventory.seriesCode : inventory.modelCode));
        targeting = {
            tprice: search.toPrice || 'nl',
            kmod: kmod || 'nl',
            mke: inventory.makeCode || 'none',
            abody: inventory.bodyStyle || 'nl',
            yrange: search.yearRange || 'nl',
            bstyl: inventory.bodyStyle || 'none',
            yr: inventory.year || 'nl',
            kbody: inventory.bodyStyle ? inventory.bodyStyle.substring(0, 3) : 'nl',
            amod,
            mdl: inventory.modelCode || 'none',
            kmake: inventory.makeCode ? inventory.makeCode.toLowerCase() : 'nl',
            amake: inventory.makeCode || 'nl',
            eyr: search.toYear || 'nl',
            fprice: search.fromPrice || 'nl',
            ownid: inventory.owner ? inventory.owner.id : 'nl',
            series: inventory.seriesCode || 'nl',
        };
    }

    return targeting;
}
