import Route from '../reference/routes.js';

// These pages need to be accessible to unauthenticated users.
const authPages = [
    Route.NEW_USER_WELCOME,
    Route.SIGN_IN,
    Route.SIGN_OUT,
    Route.SIGN_UP,
];

export default function withAuthRedirect({
    refUrl,
    route,
} = {}) {
    return async (ctx) => {
        const {
            asPath = '',
            req,
            res,
            store,
        } = ctx;

        if (authPages.includes(route) || (route === Route.PREFERENCES && req?.query?.email)) {
            // These pages don't require a signed-in user.
            return;
        }

        const {
            auth: {
                isLoggedIn = false,
            },
        } = store.getState();

        if (!isLoggedIn) {
            const searchParams = new URLSearchParams({
                refUrl: refUrl || asPath,
            });
            res.redirect(Route.SIGN_IN.path + '?' + searchParams);
            res.end();
        }
    };
}
