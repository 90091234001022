import fetchFromBFF from '../utilities/fetchFromBFF.js';
import { getJSON } from '../utilities/fetchHandlers.js';

export default () => fetchFromBFF('/account/auth-user/consumer',
    {
        headers: {
            accesstoken: 'temptoken',
            idtoken: 'temptoken',
        },
    }).then((response) => {
    if (!response?.ok) {
        throw Error(response);
    }
    return response;
})
    .then(getJSON)
    .catch((reason) => {
        if (!(reason instanceof Error)) {
            if (reason.status === 404) {
                return [];
            }
        }
        return reason;
    });
