import { getUserApiSavedSearches, fetchSavedOptions } from '../../../fetchers';
import { buildSearchBookmarkElement } from '../helpers';

export default function getSavedSearches(savedSearchesDuck) {
    return () => async (dispatch, getState) => {
        try {
            let savedSearches = [];
            const { result: searchOptions } = await fetchSavedOptions();

            await Promise.all([getUserApiSavedSearches('KBB'), getUserApiSavedSearches('AT')])
                .then((response = []) => {
                    savedSearches = [...(!response[0].errors ? response[0] : []), ...(!response[1].errors ? response[1] : [])];
                    const formattedSavedSearches = savedSearches
                        .sort((d1, d2) => Date.parse(d2.bookmarkCreationDate) - Date.parse(d1.bookmarkCreationDate))
                        .map((search) => buildSearchBookmarkElement(search, searchOptions));
                    dispatch(savedSearchesDuck.creators.hydrateSavedSearches(formattedSavedSearches));
                })
                .catch((err) => {
                    dispatch(savedSearchesDuck.creators.hydrateSavedSearches([]));
                    console.error('fetching user api saved searches error: ', err);
                });

            return savedSearches;
        } catch (error) {
            throw new Error(error);
        }
    };
}
