/* eslint-disable no-console,consistent-return */
import {
    getResearchableApiRecallData,
    getServiceAndRepairRoutes
} from '../helpers.js';

export default function getRecallRepairDataCreator(ownedVehicleDuck) {
    return () => async (dispatch, getState) => {
        const selectedVehicle = ownedVehicleDuck.selectors.getSelectedOwnedVehicle(getState());

        try {
            const selectedVehicleRecallData = ownedVehicleDuck.selectors.ownedVehicleRecallRepairDataSelector(getState());

            if (selectedVehicleRecallData && !selectedVehicleRecallData?.error) {
                return;
            }
            
            const data = await getResearchableApiRecallData(selectedVehicle?.vehicleId);
    
            if (data) {
                dispatch(ownedVehicleDuck.creators.setSelectedOwnedVehicleRecallData({
                    vehicleOwnershipId: selectedVehicle.vehicleOwnershipId,
                    recallRepairData: data
                }));
            }
        } catch (error) {
            dispatch(ownedVehicleDuck.creators.setSelectedOwnedVehicleRecallData({
                vehicleOwnershipId: selectedVehicle.vehicleOwnershipId,
                recallRepairData: {
                    error: error?.message
                }
            }));
        }

        try {
            const selectedVehicleRoutes = ownedVehicleDuck.selectors.ownedVehicleRoutesSelector(getState());

            if (selectedVehicleRoutes) {
                return;
            }
            
            const routes = await getServiceAndRepairRoutes(selectedVehicle);
    
            if (routes) {
                dispatch(ownedVehicleDuck.creators.setSelectedOwnedVehicleRoutes({
                    vehicleOwnershipId: selectedVehicle.vehicleOwnershipId, 
                    routes
                }));
            }
        } catch (error) {
            throw Error(error);
        }
    };
}