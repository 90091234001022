export default (state, action, { initialState, types }) => {

    const { payload } = action;

    switch (action.type) {

        case types.HYDRATE_OWNED_VEHICLE: {
            return {
                ...state,
                vehicles: [
                    ...payload,
                ],
                vehiclesInitialLoad: true,
            };
        }

        case types.SELECTED_OWNED_VEHICLE_INDEX: {
            return {
                ...state,
                selectedOwnedVehicleIndex: payload,
            };
        }

        case types.SELECTED_OWNED_VEHICLE_ID: {
            return {
                ...state,
                selectedOwnedVehicleId: payload,
            };
        }

        case types.SELECTED_OWNED_VEHICLE_PA_DATA: {
            return {
                ...state,
                priceAdvisorData: {
                    ...state?.priceAdvisorData,
                    [payload.vehicleOwnershipId]: payload.priceAdvisorData
                }
            };
        }

        case types.SELECTED_OWNED_VEHICLE_RECALL_DATA: {
            return {
                ...state,
                recallRepairData: {
                    ...state?.recallRepairData,
                    [payload.vehicleOwnershipId]: payload.recallRepairData
                }
            };
        }

        case types.SELECTED_OWNED_DEMAND_BAR_DATA: {
            return {
                ...state,
                demandBarData: {
                    ...state?.demandBarData,
                    [payload.vehicleOwnershipId]: payload.demandBarData
                }
            };
        }

        case types.SELECTED_OWNED_VEHICLE_ROUTES: {
            return {
                ...state,
                routes: {
                    ...state?.routes,
                    [payload.vehicleOwnershipId]: {
                        ...(state?.routes?.[payload.vehicleOwnershipId] || {}),
                        ...payload.routes
                    }
                }
            };
        }

        case types.SET_PAGE_ALERT: {
            return {
                ...state,
                alert: payload,
            }
        }

        case types.REMOVE_OWNED_VEHICLE: {
            return {
                ...state,
                ...payload.ownedVehicleState,
            };
        }
        
        default:
            return state;
    }
};
