import fetchFromBFF from '../utilities/fetchFromBFF.js';

const getJSON = (response) => response.json();
const getOK = (response) => response.ok;

export const fetchReadConsumer = () => fetchFromBFF('/account-api/consumer').then(getJSON).then((response) => ({
    ...response,
    email: response.email?.toLowerCase(),
    firstName: response.firstName || response.firstname,
    lastName: response.lastName || response.lastname,
}));

export const fetchUpdateConsumerUserApi = (body) => fetchFromBFF('/account/auth-user/consumer',
    {
        method: 'PUT',
        headers: {
            accesstoken: 'temptoken',
            idtoken: 'temptoken',
        },
        body,
    }).then(getOK);

export const fetchDeleteConsumerUserApi = (body) => fetchFromBFF('/account/auth-user/consumer',
    {
        method: 'DELETE',
        headers: {
            accesstoken: 'temptoken',
            idtoken: 'temptoken',
        },
        body,
    }).then(getOK);

export default fetchReadConsumer;
