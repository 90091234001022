/* eslint-disable import/prefer-default-export */
import pageEventReference from './tags/pageEvents';

export const USER_ACCOUNT_EVENT_SOURCE = 'userAccount';

export const getPixallEventLabel = (eventlabel) => eventlabel?.replace(/::/ig, '_');

// ensure pageHierarchy for Add/Edit/Remove car events use the requested values
export function getPageHierarchy(pagePath, brand) {
    const { siteSection, pageType, detailPageName } = pageEventReference[brand][pagePath];

    return {
        siteSection,
        pageType,
        detailPageName,
    };
}
