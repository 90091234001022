import _get from 'lodash/get';

export default {
    getCarToUpdate: (state) => _get(state, 'editCar', {}),
    getOptions: (state) => _get(state, 'editCar.options', {}),
    getVehicleId: (state) => _get(state, 'editCar.vehicleId', {}),
    getOptionsType: (state) => _get(state, 'editCar.optionsType', {}),
    getColorId: (state) => _get(state, 'editCar.colorOptionId', {}),
    getAlertError: (state) => _get(state, 'editCar.alertError', {}),
    getSelectHistory: (state) => _get(state, 'editCar.selectHistory', {}),
    getCondition: (state) => _get(state, 'editCar.condition', {}),
    getExtColor: (state) => _get(state, 'editCar.extColor', {}),
};
