import queryString from 'query-string';

export default (vehicles, search) => {
    const { vehicleid, trim } = queryString.parse(search || '') || null;
    if (vehicleid || trim) {
        return vehicles.find((vehicle) => {
            const kbbTrimName = vehicle?.consumerVehicleData?.kbbTrimName || vehicle?.vehicleOwnershipData?.kbbTrimName || '';
    
            if (trim && kbbTrimName) {
                if (kbbTrimName.toLowerCase() === trim) {
                    if (vehicle.vehicleId === vehicleid) {
                        return vehicle;
                    }
                }
            } else if (vehicle.vehicleId === vehicleid) {
                return vehicle;
            }
    
            return null;
        });
    }
}