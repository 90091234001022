import {
    locationAssign,
    locationReplace,
} from '../utilities/navigate.js';

class Route {
    static #all = [];

    static #screenPaths = new Set();

    static #pathToRoute = new Map();

    static #screenToRoute = new Map();

    #path = '';

    #displayName = '';

    #screen;

    #navigate = (searchObj = {}, replace) => {
        const searchParams = new URLSearchParams(searchObj).toString();
        (replace ? locationReplace : locationAssign)(this.#path + (searchParams ? '?' + searchParams : ''));
    }

    constructor(pathPart, screen) {
        this.#screen = screen;
        const screenName = screen?.name || '';
        this.#displayName = pathPart;
        Route.#all.push(this);
        this.#path = this.generatePath(pathPart.toLowerCase(), screenName.toLowerCase()).toLowerCase();
        Route.#pathToRoute.set(this.#path, this);
        if (screenName) {
            Route.#screenPaths.add(this.#path);
            // Ignore MyATCRoute screens
            if (!this._screenUrl) {
                Route.#screenToRoute.set(screenName.toLowerCase(), this);
            }
        }
    }

    generatePath(path, screenName) {
        return `/account/${path}`;
    }

    get path() {
        return this.#path;
    }

    get displayName() {
        return this.#displayName;
    }

    get screen() {
        return this.#screen;
    }

    navigateAssign = (searchObj) => {
        this.#navigate(searchObj);
    }

    navigateReplace = (searchObj) => {
        this.#navigate(searchObj, true);
    }

    toString() {
        return this.#path;
    }

    static get all() {
        return [...this.#all];
    }

    static get screenPaths() {
        // [...this.#screenPaths] doesn't work because Babel's 'loose' setting is turned on by Bonnet.
        return Array.from(this.#screenPaths);
    }

    static fromPath(path) {
        return this.#pathToRoute.get(path?.toLowerCase());
    }

    static fromScreen(screenName) {
        return Route.#screenToRoute.get((screenName?.name || screenName)?.toLowerCase());
    }
}

export default Route;
