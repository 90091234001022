import React, { Suspense, lazy } from 'react';
import { Header as KBBHeader } from '@kbbsdk/global-sdk/navigation/web';
import { AnalyticsContext } from '@kbbsdk/global-sdk/analytics/react/context';
import FallbackProxy from '@kbbsdk/global-sdk/analytics/FallbackProxy';
import { ErrorBoundary } from 'reaxl-molecules';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import { connect } from 'react-redux';
import navLinks from '../reference/kbbGlobalNavV2.json';

import {
    kbbPageDataDuck,
} from '../ducks';

const deferPromise = global.windowLoadPromise || Promise.resolve(true);
const UpdateSavedInventoryContainer = lazy(
    () => deferPromise
        .then(() => import(
            /* webpackChunkName: "updateSavedInventoryContainer" */
            './UpdateSavedInventoryContainer'
        ))
);

const KBBHeaderContainer = ({ navigation }) => {
    const history = useHistory();
    const {
        userwcbranch,
    } = queryString.parse(history && history.location && history.location.search);

    return (
        <>
            <AnalyticsContext.Provider value={FallbackProxy}>
                <KBBHeader
                    navigation={navigation}
                    withMyKbb
                    enableCognitoExperience
                    suppressZipCode
                    enableGtm
                    useWebComponent
                    userwcbranch={userwcbranch}
                    useAuthRedirect
                />
            </AnalyticsContext.Provider>
            {(typeof window !== 'undefined') && (
                <Suspense fallback={null}>
                    <ErrorBoundary>
                        <UpdateSavedInventoryContainer />
                    </ErrorBoundary>
                </Suspense>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    const navigation = kbbPageDataDuck.selectors.getDuckState(state)?.navigation || navLinks;
    return {
        navigation,
    };
};

export default connect(mapStateToProps)(KBBHeaderContainer);
