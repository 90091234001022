import { fetchVinLpData } from '../../../fetchers/index.js';

export default function getVinLpDataCreator(vehiclePickerDuck) {
    return ({
        onSuccess = () => {},
        onError = () => {},
        license,
        state,
        vin,
        selectedVehicleId,
    }) => async (dispatch) => {
        try {
            const data = await fetchVinLpData({ license, state, vin, selectedVehicleId });

            if (data && data?.dataForVinLpDetails) {
                dispatch(vehiclePickerDuck.creators.setVinLpData(data));
                onSuccess(data);
            } else {
                dispatch(vehiclePickerDuck.creators.setVinLpData({ error: data?.error || true }));
                onError(data);
            }

        } catch (error) {
            dispatch(vehiclePickerDuck.creators.setVinLpData({ error: true }));
            onError({ error: true });
            throw new Error(error);
        }
    };
}