import { removeUserApiSavedSearches } from '../../../fetchers';

export default function removeSavedSearchActionCreator(savedSearchesDuck) {
    return ({
        bookmarkId,
        onRemoveSuccess = () => { },
        onRemoveFailure = () => { },
    }) => async (dispatch, getState) => {
        const savedSearches = savedSearchesDuck.selectors.getSavedSearches(getState());
        const savedSearchesAfterRemove = savedSearches.filter((bookmark) => bookmark.bookmarkId !== bookmarkId);
        await removeUserApiSavedSearches(bookmarkId).then(() => {
            dispatch(savedSearchesDuck.creators.hydrateSavedSearches(savedSearchesAfterRemove));
            onRemoveSuccess(savedSearchesAfterRemove.length);
            return savedSearchesAfterRemove;
        }).catch((error) => {
            console.error(error);
            onRemoveFailure();
        });
    };
}
