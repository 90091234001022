import { objectDuckCreator } from '@atc/modular-redux';
import get from 'lodash.get';

export default objectDuckCreator({
    namespace: 'MY_ATC',
    store: 'domain.myatc',
}).extend({
    reducer: (state, action, { types }) => {
        switch (action.type) {

            case 'LOAD_SERVERDATA': {

                const myAtcContent = get(action, ['payload', 'myAtcContent'], false);
                return {
                    ...state,
                    ...myAtcContent,
                };
            }

            default:
                return state;
        }
    },
});
