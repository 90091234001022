import querystring from 'querystring';
import { isNONPROD } from './getEnvironment';

const atcDomain = isNONPROD() ? 'test.autotrader.com' : 'www.autotrader.com';
const atcVDP = (qstring) => `/cars-for-sale/vehicledetails.xhtml${qstring}`;
const tredVDP = (vin, qstring) => `https://${atcDomain}/marketplace/buy/${vin}${qstring}`;

export function buildVehicleDetailsPath({
    listingId,
    search = true,
    vin,
    isTredVdp,
}) {
    let searchString = '';

    if (search) {
        searchString = '?' + querystring.stringify({
            listingId,
        });
    }

    return isTredVdp ? tredVDP(vin, searchString) : atcVDP(searchString);
}

export default {
    buildVehicleDetailsPath,
};
