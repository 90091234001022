// import { MAX_RESULT } from "../services/service-utilities/researchableDataLoader";
// import { filterByEligibleVehicleClass } from "../utilities";
import { fetchJSON } from '@bonnet/core/fetch';

export default async (args) => {
    const {
        // vehicleClass = 'UsedCar',
        license,
        state,
        vin,
        selectedVehicleId,
    } = args || {};

    try {
        const data = await fetchJSON(
            '/account/services/vinLpDataService',
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                query: {
                    vin,
                    license,
                    state,
                    selectedVehicleId,
                },
            }
        );

        return data;

    } catch (err) {
        console.error('fetchVinLpData ', err);
    }
    return null;
};
