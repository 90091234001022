import { MAX_RESULT } from "../services/service-utilities/researchableDataLoader";
import { filterByEligibleVehicleClass, sort } from "../utilities";
import { fetchJSON } from '@bonnet/core/fetch';

export default async (args) => {
    const {
        vehicleClass = 'UsedCar',
        yearId
    } = args || {};

    try {
        
        const data = await fetchJSON(
            `/account/services/researchableReferenceDataService/makes?yearid=${yearId}&limit=${MAX_RESULT}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                },
            }
        )
     
        if (data) {
            return sort.ascend(filterByEligibleVehicleClass(data, vehicleClass), 'makeName')
                .map(({ makeId, makeName }) => ({
                    value: makeId,
                    text: makeName
                }));
        }
    } catch (err) {
        console.error('makes ', err);
    }
    return null;
};
