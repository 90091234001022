import {paymentsDuckCreator} from 'axl-ducks';
import hasDom from 'has-dom';
import importAuthUtilityBundle from '../utilities/importAuthUtilityBundle'

import {ownerDetailsFetcher, WalletStorageFetcher, WalletStorageService,} from 'reaxl-wallet';

import {fetchPaymentCalculation as paymentFetcher,} from '../fetchers';

function getEnv() {
    let env = 'qa';
    if (hasDom()) {
        // eslint-disable-next-line
        if (['www.autotrader.com', 'speedy.autotrader.com', 'www.kbb.com'].includes(window?.location?.hostname)) {
            env = 'prod';
        }
    }

    return env;
}

async function getUserAccountClientAuth() {
    const { getConsumerId } = await importAuthUtilityBundle();
    return await getConsumerId();
}

//TODO: refactor this clientAuth object later
const userAccountClientAuth = {
    currentUserInfo: async () => ({
        attributes: { 'custom:consumer_id': await getUserAccountClientAuth() }
    })
};

const walletStorageService = new WalletStorageService({
    clientAuth: userAccountClientAuth,
    fetcher: WalletStorageFetcher,
    environment: getEnv(),
});

const paymentsDuck = paymentsDuckCreator({
    store: 'payments',
    paymentFetcher,
    walletStorageService,
    ownerDetailsFetcher: ownerDetailsFetcher(),
});

export default paymentsDuck;
