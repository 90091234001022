import { CommunicationPrefsClick } from '../CommunicationsPrefsHandlers';

// Add Car Clicks
const manageSavedCarsAlertsClick = CommunicationPrefsClick('text-link::saved-cars::manage-alerts');
const manageListingsAlertsClick = CommunicationPrefsClick('text-link::listings::manage-alerts');
const unsubscribeSelectClick = CommunicationPrefsClick('cta::comm-prefs::unsubscribe');
const nextCarSearchClick = CommunicationPrefsClick('text-link::next-car-search');
const unsubscribeSavedCarsClick = CommunicationPrefsClick('checkbox::unsubscribe::saved-cars');
const unsubscribeListingsClick = CommunicationPrefsClick('checkbox::unsubscribe::listings');
const unsubscribeMarketingEmailsClick = CommunicationPrefsClick('checkbox::unsubscribe::marketing-emails');
const unsubscribeMyCarsOwnedClick = CommunicationPrefsClick('checkbox::unsubscribe::my-cars-owned');
const unsubscribeAllClick = CommunicationPrefsClick('text-link::unsubscribe-all');

export default {
    manageSavedCarsAlertsClick,
    manageListingsAlertsClick,
    unsubscribeSelectClick,
    nextCarSearchClick,
    unsubscribeSavedCarsClick,
    unsubscribeListingsClick,
    unsubscribeMarketingEmailsClick,
    unsubscribeMyCarsOwnedClick,
    unsubscribeAllClick,
};
