/* eslint-disable no-console */
import React, { Fragment, lazy, Suspense } from 'react';
import { fetchJSON } from '@bonnet/core/fetch';
import withCtxMiddleware from '@bonnet/with-ctx-middleware';
import ctxPresetAtc from '@atc/bonnet-ctx-preset-atc';
import isBot from '@bonnet/ctx-is-bot';
import atcId from '@atc/bonnet-ctx-atc-id';
import queryString from 'query-string';
import { useHistory } from 'react-router';

import {
    OptimizelyPageProvider,
    addOptimizelyDynamicConfig,
} from 'reaxl-optimizely';
import getConfig from '@bonnet/core/config';

import { AdLibrariesHeaders } from 'reaxl-ads';
import { BrandProvider } from 'reaxl-brand';

import { Provider as FeatureFlagsContextProvider } from 'reaxl-features';
import { AnalyticsProvider } from 'reaxl-analytics';
// Import the AXL style library
import '@atc/axl-atc/lib/css/axl-atc.min.css';
import UserProvider from '@kbbsdk/global-sdk/navigation/web/mykbb/UserProvider';

import clientConfig from '@kbbsdk/config/clientConfig';
import { isPROD } from '../utilities/getEnvironment';

import useDataLayer from '../analytics/useDataLayer';
import ConfigContext from '../contexts/ConfigContext';
import {
    withDataIsland,
    withWalletConfigs,
    withInterestRates,
    withBrandCSS,
    withKBBPageData,
} from '../context-middleware';

import featureConfig from '../constants/features';

import {
    analyticsDuck,
    inventoryDuck,
    editCarDuck,
    userDuck,
    myAtcSortDuck,
    dealerLotDuck,
    inventoryResultsDuck,
    inventorySelectionsDuck,
    myAtcDuck,
    emailLinkDuck,
    mobileMessageDuck,
    preferencesSelectionsDuck,
    cancelAlertsModalVisibleDuck,
    brandDuck,
    adsDuck,
    paymentsDuck,
    interestRateDuck,
    savedInventoryDuck,
    ownedVehicleDuck,
    shoppedVehicleDuck,
    kbbPageDataDuck,
    vehiclePickerDuck,
    addCarDuck,
    stylesCategoriesDuck,
    vinLpDetailsDuck,
    savedSearchesDuck,
} from '../ducks';

import { fetchConfigs } from '../fetchers';

import analytics from '../analytics';

import AtcFooter from '../containers/FooterContainer';
import GlobalHeadContainer from '../containers/GlobalHeadContainer';
import AbstractPageContainer from '../containers/AbstractPageContainer';
import ThemeProvider from '../containers/ThemeProvider';
import CacheKey from '../utilities/CacheKey';
import { isKBBBrand } from '../utilities/brands';

import AtcHeader from '../containers/HeaderContentContainer';

import KbbHeader from '../containers/KbbHeaderContainer';
import KbbFooter from '../containers/KbbFooterContainer';

import Route from '../reference/routes';

const NewUserWelcomeModal = lazy(() => import(/* webpackChunkName: "lazyLoadMyAtcSection" */ '../containers/NewUserWelcomeModal'));
const CancelAccountConfirmationModalContainer = lazy(() => import(/* webpackChunkName: "lazyLoadMyAtcSection" */ '../containers/CancelAccountConfirmationModalContainer'));
const MyWalletContainer = lazy(() => import(/* webpackChunkName: "myWalletChunk" */ '../containers/MyWalletContainer'));

const renderAdLibrariesHeads = ({
    reqUrl,
    mvtFeatureParameters,
    configs,
}) => {

    const {
        ENABLE_AD_LIBRARIES_LOAD_FROM_SERVER,
    } = mvtFeatureParameters;

    const enableAdLibrariesLoadFromServer = !!ENABLE_AD_LIBRARIES_LOAD_FROM_SERVER;
    if (!enableAdLibrariesLoadFromServer) return null;

    const openWrapUrl = configs?.site?.enableOpenWrap && configs?.site?.openWrapUrl;
    const prebidPath = CacheKey.add('/resources/js/bundle/prebid.js', false);

    return (
        <AdLibrariesHeaders
            reqUrl={reqUrl}
            openWrapUrl={openWrapUrl}
            prebidPath={prebidPath}
        />
    );
};

const App = (props) => {
    const {
        configs = {},
        optimizelyDynamicConfig,
        showNewUserModal,
        reqUrl,
        mvtParams = { ENABLE_AD_LIBRARIES_LOAD_FROM_SERVER: true },
        brand,
        dataIsland,
    } = props;

    const isKBB = isKBBBrand(brand);
    const { publicRuntimeConfig } = getConfig();
    const dataLayer = useDataLayer();
    const history = useHistory();
    const {
        usewebcomponent = true,
        userwcbranch,
    } = queryString.parse(history && history.location && history.location.search);

    const analyticsProps = {
        dataLayer,
        getDataIsland: () => ({ ...dataIsland }),
        option: {
            disableGTM: false,
            disableGTMEvents: false,
            ...(isKBB && {
                populateChannelManager: false,
            }),
        },
        value: analytics,
    };

    clientConfig.userApiUrl = isPROD()
        ? 'https://use1-user-api-master.awscsuser.kbb.com'
        : 'https://use1-user-api-master.awscsusernp.kbb.com';

    let Header = AtcHeader;
    let Footer = AtcFooter;
    let footerProps = {
        isBot: props.isBot,
    };
    if (isKBB) {
        footerProps = {};
        Header = KbbHeader;
        Footer = KbbFooter;
    }
    const showHeaderFooter = props.location?.pathname !== Route.FEDERATED_AUTH.path;

    const searchParams = new URLSearchParams(props.location?.search);

    if (searchParams.get('debug')) {
        console.log(`app diagnostics:::: ${JSON.stringify(publicRuntimeConfig.diagnostics)}`);
    }

    return (
        <FeatureFlagsContextProvider flags={{ config: featureConfig }}>
            <OptimizelyPageProvider
                disable={publicRuntimeConfig.disableOptimizely}
                optimizelyDynamicConfig={optimizelyDynamicConfig}
            >
                <AnalyticsProvider {...analyticsProps}>
                    <ConfigContext.Provider value={configs}>
                        <ThemeProvider isKBB={isKBB}>
                            <UserProvider>
                                <BrandProvider brand={brand}>
                                    {(typeof window !== 'undefined') ? (
                                        <Fragment>
                                            <Suspense fallback={null}>
                                                { showNewUserModal && <NewUserWelcomeModal /> }
                                            </Suspense>
                                            <Suspense fallback={null}>
                                                <CancelAccountConfirmationModalContainer />
                                            </Suspense>
                                            <Suspense fallback={null}>
                                                <MyWalletContainer />
                                            </Suspense>
                                        </Fragment>
                                    ) : null}
                                    <GlobalHeadContainer
                                        isKBB={isKBB}
                                        usewebcomponent={usewebcomponent}
                                        userwcbranch={userwcbranch}
                                    />
                                    {typeof window === 'undefined' && renderAdLibrariesHeads({
                                        reqUrl,
                                        mvtFeatureParameters: mvtParams,
                                        configs,
                                    })}
                                    {showHeaderFooter ? <Header /> : null}
                                    <AbstractPageContainer
                                        {...props}
                                        isKBB={isKBB}
                                    />
                                    {showHeaderFooter ? <Footer {...footerProps} /> : null}
                                </BrandProvider>
                            </UserProvider>
                        </ThemeProvider>
                    </ConfigContext.Provider>
                </AnalyticsProvider>
            </OptimizelyPageProvider>
        </FeatureFlagsContextProvider>
    );
};

App.getReduxConfiguration = (ctx) => {
    ctx.store.addReducers([
        userDuck,
        inventoryDuck,
        savedInventoryDuck,
        ownedVehicleDuck,
        shoppedVehicleDuck,
        dealerLotDuck,
        editCarDuck,
        myAtcSortDuck,
        inventoryResultsDuck,
        analyticsDuck,
        inventorySelectionsDuck,
        myAtcDuck,
        emailLinkDuck,
        mobileMessageDuck,
        preferencesSelectionsDuck,
        cancelAlertsModalVisibleDuck,
        brandDuck,
        adsDuck,
        paymentsDuck,
        interestRateDuck,
        kbbPageDataDuck,
        vehiclePickerDuck,
        addCarDuck,
        stylesCategoriesDuck,
        vinLpDetailsDuck,
        savedSearchesDuck,
    ]);
};

App.getInitialProps = async (ctx) => {

    const { store, req } = ctx;
    const myAtcContent = await fetchJSON('/account/services/myAtcService', req);

    const { zip } = myAtcContent;
    const state = store.getState();

    const {
        user: {
            showNewUserModal,
        },
    } = state;

    store.dispatch(myAtcDuck.creators.setKeys({
        ...myAtcContent,
    }));

    // If after all that nonsense we finally have a zip then we need to
    // update in a couple places
    if (zip) {
        // set the zip in the user store
        store.dispatch(userDuck.creators.setLocation({
            zip,
        }));
    }

    // Ensure the app loads root reducers
    const reqUrl = req && `${req.protocol}://${req.get('host')}${req.originalUrl}`;

    // Only on the server, we need to pass through the exp cookie
    const headers = {};
    if (req && req.cookies.exp) {
        headers.cookie = `exp=${req.cookies.exp}`;
    }

    await withCtxMiddleware([
        withBrandCSS(),
        ctxPresetAtc({
            pageName: 'myatc',
            userDuck,
        }),
        isBot(),
        atcId(),
        addOptimizelyDynamicConfig(),
        withWalletConfigs(),
        withInterestRates(),
        withDataIsland(),
        withKBBPageData(),
    ], ctx);

    const mvtParams = {
        // hardcoded until FF cleanup
        HIDE_KBB_COOKIE_BANNER: 'true',
        ENABLE_DEALER_CERTIFIED_FILTER: 'true',
        ENABLE_OPTIMIZELY_EDGE_ARTICLE: 'true',
        ENABLE_OPTIMIZELY_PRELOAD_HEADER: 'true',
        ENABLE_OPTIMIZELY_WEBCLIENT_HP: 'true',
        ENABLE_OPTIMIZELY_WEBCLIENT_ASF: 'true',
        ENABLE_OPTIMIZELY_WEBCLIENT_ACCELERATE: 'true',
        DEALER_LOT_TAGLINE: 'true',
        LISTINGS_MOBILE_SUPPORT: 'true',
        ENABLE_AD_LIBRARIES_LOAD_FROM_SERVER: true,
    };

    const remoteConfigs = await fetchConfigs();
    const configs = {
        ...remoteConfigs,
        // perhaps we can load process.env here?
    };
    const {
        isBot: isRobot,
        brand,
        optimizelyDynamicConfig,
        dataIsland,
    } = ctx.data;

    return {
        configs,
        mvtParams,
        reqUrl,
        myAtcContent,
        isBot: isRobot,
        brand,
        optimizelyDynamicConfig,
        showNewUserModal,
        dataIsland,
    };
};

export default App;
