import { fetchOptions, fetchOptionsPage } from '../../../fetchers';

export default function getAddOptionsCreator(addCarDuck) {
    return ({
        onSuccess = () => { },
        onError = () => { },
        vehicleId,
        selectHistory,
        color,
    }) => async (dispatch) => {
        try {
            const ymmt = await fetchOptionsPage({ vehicleId });
            const selectedOptions = await fetchOptions({ vehicleId, selectHistory });
            // const selectedColor = selectedOptions.colors.find((c) => c.optionName.toLowerCase() === color);
            const options = {
                ...ymmt,
                ...selectedOptions,
            };

            dispatch(addCarDuck.creators.hydrateOptions(options));

            // if (selectedColor && selectedColor?.vehicleOptionId) {
            //     dispatch(editCarDuck.creators.updateSelectedColor({ colorId: selectedColor.vehicleOptionId }));
            // }
            if (ymmt && selectedOptions) {
                onSuccess(selectedOptions);
            } else {
                onError();
            }

        } catch (error) {
            onError();
            throw new Error(error);
        }
    };
}
