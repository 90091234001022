import get from 'lodash.get';
import { Cookies } from 'atc-js';

import { userDuckCreator } from 'axl-ducks';
import { getPersonalizationClientEnv } from './duckUtils';

import savedInventoryDuck from './savedInventoryDuck';
import inventoryDuck from './inventoryDuck';

function getUserRadius() {
    const cookies = new Cookies();
    return cookies.get('ATC_USER_RADIUS');
}

export default userDuckCreator({
    inventoryDuck,
    savedInventoryDuck,
    env: getPersonalizationClientEnv(),
})
    .extend({
        reducer: (state, action, { types }) => {
            switch (action.type) {

                case 'LOAD_SERVERDATA': {
                    return {
                        ...state,
                        location: {
                            ...state.location,
                            zip: get(action, 'payload.requestParams.zip', ''),
                            searchRadius: getUserRadius(),
                        },
                    };
                }

                default:
                    return state;
            }
        },
    });
