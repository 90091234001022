import {
    locationAssign,
    locationReplace,
    locationOpenTab,
} from './navigate.js';

export default function redirectExternal(url, option = {}) {
    if (globalThis.location) {
        if (option?.tab) {
            return locationOpenTab(url);
        }
        if (option?.replace) {
            return locationReplace(url);
        }
        return locationAssign(url);
    }

    return false;
}
