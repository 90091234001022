import withBrand from '@atc/bonnet-ctx-brand';
import userLocation from '@atc/bonnet-ctx-user-location';
import withCtxMiddleware from '@bonnet/with-ctx-middleware';
import withAuthRedirect from './withAuthRedirect';
import getMobileSmsUrl from './getMobileSmsUrl';
import ctxPageInstanceId from './ctxPageInstanceId';
import withAdParameters from './withAdParameters';
import withDataIsland from './withDataIsland';

import { brandDuck, userDuck } from '../ducks';
import setPageMeta from './setPageMeta';

export default function ctxPresetMyAtc({
    refUrl,
    route,
    page = 'cars',
    dispatcher = () => {},
    pageReferenceKey,
}) {
    return (ctx) => withCtxMiddleware([
        ctx.withModuleCtxMiddleware(),
        withBrand({ brandDuck }),
        withDataIsland({ pageReferenceKey }), // this must come after withBrand
        ctxPageInstanceId(),
        setPageMeta(route),
        withAdParameters({ page, pageReferenceKey }),
        getMobileSmsUrl(),
        dispatcher(),
        userLocation({ userDuck }),
        withAuthRedirect({ refUrl, route }),
    ], ctx);
}
