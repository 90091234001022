export default class Screen {
    #screenName;

    #heading;

    static #allScreens = new Map();

    static all = () => [...this.#allScreens.values()];

    static fromName = (name) => this.#allScreens.get(name);

    get name() {
        return this.#screenName;
    }

    get heading() {
        return this.#heading;
    }

    constructor(screenName, heading) {
        this.#screenName = screenName;
        this.#heading = heading;
        Screen.#allScreens.set(screenName, this);
    }

    toString = () => this.name;

    static CHANGE_PASSWORD = new Screen('changePassword', 'Update Your Password');

    static CHANGE_PASSWORD_CONFIRMATION = new Screen('changePasswordConfirmation', 'Success!');

    static CONFIRMATION = new Screen('confirmation', 'Success');

    static FORGOT_PASSWORD = new Screen('forgotPassword', 'Forgot Your Password?');

    static FORGOT_PASSWORD_SUBMIT_CODE = new Screen('forgotPasswordSubmitCode', 'Forgot Your Password?');

    static FORGOT_PASSWORD_SUBMIT = new Screen('forgotPasswordSubmit', 'Reset Password');

    static FORGOT_PASSWORD_SUBMIT_CONFIRMATION = new Screen('forgotPasswordSubmitConfirmation', 'Email Sent!');

    static RESEND_VERIFICATION = new Screen('resendVerification', 'Resend Verification Email');

    static SIGN_IN = new Screen('signIn', 'Sign In');

    static SIGN_UP = new Screen('signUp', 'Sign Up for an Account');

    static SIGN_OUT = new Screen('signOut', 'Sign In');

    static VERIFY_EMAIL = new Screen('verifyEmail', 'Just One More Step!');

    static VERIFY_EMAIL_CHANGE = new Screen('verifyEmailChange', 'Verify Email Change');

    static VERIFY_EMAIL_CHANGE_CONFIRMATION = new Screen('verifyEmailChangeConfirmation', 'OK, You’re All Set!');

    static VERIFY_EMAIL_EXPIRED_LINK = new Screen('verifyEmailExpiredLink', 'Resend Verification Email');

    static WELCOME = new Screen('welcome', 'Email Verified!');
}
