import { fetchJSONWithCircuitBreaker } from '../utilities';

export default function fetchRecommendedInventory(criteria, fallback = {}) {
    return fetchJSONWithCircuitBreaker(
        '/rest/pl/v0/recommendations/listings',
        { 
            query: criteria,
            headers: {
                'X-fwd-svc': 'atc',
            },
        },
        'fetchRecommendedInventory Error: ',
        fallback,
        'fetchRecommendedInventory'
    );
}
