/* eslint-disable no-console */
import { fetchJSON } from '@bonnet/core/fetch';
import remoteActions from '../actions/remoteActions.js';

export default function fetchJSONWithCircuitBreaker(path, data = {}, errMsg = 'fetchJSON error', fallback = {}, id = 'circuitBreakerId') {
    return fetchJSON(path, {
        ...data,
        circuitBreaker: {
            id,
            timeout: process.env.CIRCUIT_BREAKER_TIMEOUT,
            resetTimeout: process.env.CIRCUIT_BREAKER_TIMEOUT_RESET,
            fallback,
        },
    }).then((res) => {
        if (!Object.keys(res).length && ['fetchDealerLot', 'marketLocation'].includes(id)) {
            remoteActions.auditBeacon(`${id} circuit breaker is open`, {});
        }
        return res;
    }).catch((error) => {
        console.error(errMsg, error);
        if (['fetchDealerLot', 'marketLocation'].includes(id)) {
            remoteActions.auditBeacon(`${id} fetch error`, { errorMessage: error.message });
        }
    });
}
