import querystring from 'querystring';
import { getPath } from '@atc/bonnet-paths';

import redirectExternal from './redirectExternal';

export async function navigateToSRP({
    listingType,
    maxPrice,
}) {

    const friendlySeoUrl = await getPath(
        'srp',
        {
            listingType,
            maxPrice,
        }
    );

    redirectExternal(friendlySeoUrl);
}

export default function buildSrpPath(bookmarks, zip) {
    const getCondition = (cond) => {
        if (cond === 'Y') {
            return 'NEW';
        }
        return 'USED,CERTIFIED';

    };

    const getMileageRange = (mileage) => {
        let milesRange = 15000;
        const milesInt = Number(mileage);
        switch (true) {
            case (milesInt <= 15000):
                milesRange = '15000';
                break;
            case (milesInt <= 45000):
                milesRange = '45000';
                break;
            case (milesInt <= 60000):
                milesRange = '60000';
                break;
            case (milesInt <= 75000):
                milesRange = '75000';
                break;
            case (milesInt <= 200000):
                milesRange = '200000';
                break;
            case (milesInt <= 150000):
                milesRange = '150000';
                break;
            case (milesInt <= 100000):
                milesRange = '100000';
                break;
            default:
                milesRange = '200001';
        }
        return milesRange;
    };

    const { searchCriteria = {} } = bookmarks;

    const query = {
        ...(searchCriteria.carMakeCode ? { makeCodeList: searchCriteria.carMakeCode.toUpperCase() } : {}),
        ...(searchCriteria.carModelCode ? { modelCodeList: searchCriteria.carModelCode.toUpperCase() } : {}),
        ...(searchCriteria.carMileage ? { maxMileage: getMileageRange(searchCriteria.carMileage) } : {}),
        ...(searchCriteria.carModelYear ? {
            startYear: searchCriteria.carModelYear.toUpperCase(),
            endYear: searchCriteria.carModelYear.toUpperCase(),
        } : {}),
        ...(searchCriteria.carNewFlag ? { listingTypes: getCondition(searchCriteria.carNewFlag) } : {}),
        ...(searchCriteria.maxPrice ? { maxPrice: searchCriteria.maxPrice } : {}),
        ...(zip ? { zip } : {}),
    };
    const queryString = querystring.stringify(query);
    return `/cars-for-sale/searchresults.xhtml?${queryString}`;
}
