/* eslint-disable no-unreachable */
import { Duck } from '@atc/modular-redux';

// Selectors
import selectors from './addCarDuckSelectors';
import reducer from './addCarDuckReducer';
import creators from './actionCreators';
import { ADDCAR_CURRENTSTEPS } from '../../constants';

const defaultState = {
    zip: null,
    mileage: null,
    year: null,
    make: null,
    makeId: null,
    model: null,
    modelId: null,
    vehicleId: null,
    trim: null,
    options: {},
    optionsType: null,
    colorOptionId: null,
    selectHistory: '',
    alertError: null, // 'save'
    condition: null,
    extColor: null,
    brand: null,
    currentStep: ADDCAR_CURRENTSTEPS.VEHICLE_PICKER,
};

function addCarDuckCreator({
    namespace = '',
    store = 'addCar',
    storePath,
    env = 'qa',
    ...rest
} = {}) {

    return new Duck({
        ...rest,
        namespace,
        store,
        storePath,
        initialState: {
            ...defaultState,
        },
        consts: {},
        types: [
            'SET_ADD_CAR',
            'SET_CURRENT_STEP',
            'HYDRATE_OPTIONS',
            'UPDATE_OPTIONS',
            'SET_SELECT_HISTORY',
            'SET_OPTIONS_TYPE',
            'SET_COLOR_ID',
            'SET_ERROR',
            'SET_VEHICLE_ID',
            'SET_CONDITION',
            'SET_EXT_COLOR',
            'RESET_STATE',
        ],

        creators: (duck) => creators(duck),
        reducer,
        selectors,
    });
}

export default addCarDuckCreator();