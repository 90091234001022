import React from 'react';
import { StaticFooter, FooterContentInjector } from 'reaxl-footer';
import { useAnalytics } from 'reaxl-analytics';

const FooterContainer = () => {
    const { sendClick } = useAnalytics();

    const handleFooterClick = (event, data) => (
        sendClick('footerLinkClick', event, data)
    );

    return (
        <React.Fragment>
            <FooterContentInjector />
            <StaticFooter onClick={handleFooterClick} />
        </React.Fragment>
    );
};

export default FooterContainer;
