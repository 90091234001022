import {
    MAPPING_BOOLEAN_FIELD_WITH_FILTER,
    DEFAULT_MPG_RANGES,
    MIN_YEAR,
} from './constants';

const getString = (selectItems) => (selectItems?.join?.(', '));

const getSortedString = (selectItems) => (selectItems?.sort().join(', '));

const currencyFormat = (num) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
    return formatter.format(num);
};

const convertTypeStringToArray = (data) => {
    if (!data) return undefined;
    return (typeof (data) === 'string') ? data.split(',') : data;
};

const buildSelectItem = (searchCriteriaItemCodes, searchOptionItems, callBack) => {
    if (!searchCriteriaItemCodes?.includes) return undefined;
    const result = searchOptionItems?.filter(({ code }) => searchCriteriaItemCodes?.includes(code))
        .map(({ name }) => name);
    return callBack ? callBack(result) : getString(result);
};

const buildMileage = (maxMilage, searchOptionItems) => {
    const maxMileageString = [maxMilage]?.flat()[0]?.toString();
    return searchOptionItems?.find((mileageOption) => mileageOption.code === maxMileageString)?.name;
};

const buildTrim = (trimCodes, models) => {
    if (!trimCodes?.map) return undefined;
    const result = trimCodes.map((trim) => {
        const modelTrim = trim.split('|');
        const model = models.find(({ code }) => code === modelTrim[0]);
        return `${model?.name} ${modelTrim[1]}`;
    });
    return getString(result);
};

const buildSeries = (seriesCodes, models) => {
    if (!seriesCodes?.includes) return undefined;
    const result = models?.filter(({ classSeries = {} }) => seriesCodes.includes(classSeries.code))
        .map(({ classSeries = {} }) => classSeries.name);
    return getString(Array.from(new Set(result)));
};

const buildColor = (colorCodes, allColors) => getString(colorCodes?.map((colorCode) => allColors.find((elm) => elm.code === colorCode)?.name).filter(Boolean));

const buildListingFeatures = (searchCriteria) => {
    const result = MAPPING_BOOLEAN_FIELD_WITH_FILTER?.filter(({ booleanField }) => searchCriteria[booleanField])
        .map(({ filter }) => filter);
    return getString(result);
};

const convertMPGRanges = (mpgRanges) => {
    // Checking for mpgRanges strings
    const mpgRangesArray = convertTypeStringToArray(mpgRanges);
    return mpgRangesArray ? mpgRangesArray.map((range) => range.split('-')?.[0]) : undefined;
};

const buildMarketExtension = (marketExtension) => (marketExtension === 'on' ? 'Delivery to Your Door' : undefined);

const buildSearchZipRadius = (searchRadius, searchZip) => {
    if (!searchZip) {
        return undefined;
    }
    const searchRadiusMile = searchRadius && searchRadius[0] !== '0' ? `${searchRadius} miles` : 'Any Distance';
    return `Within ${searchRadiusMile} of ${searchZip}`;
};

const buildYearRange = (searchCriteria) => {
    const { startYear, endYear } = searchCriteria;
    if (!startYear && !endYear) {
        return undefined;
    }
    return `${startYear || MIN_YEAR} - ${endYear || new Date().getFullYear() + 1}`;

};

const buildPrice = (searchCriteria) => {
    const { minPrice, maxPrice } = searchCriteria;

    if (!minPrice && !maxPrice) {
        return undefined;
    }
    if (minPrice && maxPrice && minPrice !== '0' && maxPrice !== '0') {
        return `${currencyFormat(parseInt(minPrice, 10))} - ${currencyFormat(parseInt(maxPrice, 10))}`;
    }
    if (minPrice && minPrice !== '0') {
        return `Min. ${currencyFormat(parseInt(minPrice, 10))}`;
    }
    if (maxPrice && maxPrice !== '0') {
        return `Max. ${currencyFormat(parseInt(maxPrice, 10))}`;
    }
    return undefined;
};

const buildSearchSummaryData = (searchCriteria, searchOptions) => {
    const {
        transmissionGroups = [], styles = [], doors = [], engineGroups = [], vehicleFeatures = [], fuelTypeGroups = [], driveGroups = [],
        makes = [], extColorGroups = [], intColorGroups = [], mileageRanges = [], homeServices = [], vehicleHistoryOptions = [],
        sellers = [], dealTypes = [], listingTypes = [],
    } = searchOptions;
    const {
        makeCodeList = [], modelCodeList = [], trimCodeList = [], doorCodes = [], driveGroup = [], engineCodes = [], extColorsSimple = [],
        interiorColorsSimple = [], featureCodes = [], fuelTypeGroup = [], keywordPhrases, listingTypesList = [], maxMileage = [], mileage = [], sellerTypes = [], seriesCodeList = [],
        marketExtension = [], dealTypesList = [], vehicleStyleCodes = [], transmissionCodes = [], mpgRanges = [], zip, searchRadius = ['0'], homeServicesList = [], vehicleHistory = [],
    } = searchCriteria;
    try {
        const mileageFilter = mileage || maxMileage;
        const allModels = (makes && makes.map(({ models }) => models).flat()) || [];
        const convertedMileageRanges = mileageRanges.map((range) => ({ ...range, code: range.code.replace('-', '') }));
        const convertedMPGRanges = convertMPGRanges(mpgRanges);
        const listingTypesUppercase = listingTypesList?.map((type) => type.toUpperCase());

        const allColors = [...extColorGroups, ...intColorGroups];

        return {
            listingTypes: buildSelectItem(listingTypesUppercase, listingTypes),
            make: buildSelectItem(makeCodeList, makes),
            model: buildSelectItem(modelCodeList, allModels),
            trim: buildTrim(trimCodeList, allModels),
            doors: buildSelectItem(doorCodes, doors),
            drive: buildSelectItem(driveGroup, driveGroups),
            engine: buildSelectItem(engineCodes, engineGroups),
            exteriorColor: buildColor(extColorsSimple, allColors),
            interiorColor: buildColor(interiorColorsSimple, allColors),
            features: buildSelectItem(featureCodes, vehicleFeatures, getSortedString),
            fuel: buildSelectItem(fuelTypeGroup, fuelTypeGroups),
            listingFeatures: buildListingFeatures(searchCriteria),
            mileage: buildMileage(mileageFilter, convertedMileageRanges),
            price: buildPrice(searchCriteria),
            sellerType: buildSelectItem(sellerTypes, sellers),
            series: buildSeries(seriesCodeList, allModels),
            marketExtension: buildMarketExtension(marketExtension),
            dealTypes: buildSelectItem(dealTypesList, dealTypes),
            style: buildSelectItem(vehicleStyleCodes, styles),
            transmission: buildSelectItem(transmissionCodes, transmissionGroups),
            mpgRanges: buildSelectItem(convertedMPGRanges, DEFAULT_MPG_RANGES),
            vehicleHistory: buildSelectItem(vehicleHistory, vehicleHistoryOptions),
            homeServices: buildSelectItem(homeServicesList, homeServices),
            searchZipRadius: buildSearchZipRadius(searchRadius, zip),
            yearRange: buildYearRange(searchCriteria),
            keyword: keywordPhrases,
        };
    } catch (err) {
        console.error(`ERROR buildSearchSummaryData: ${err}`);
        throw err;
    }
};

export default buildSearchSummaryData;
