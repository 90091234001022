export const getJSON = (response) => response?.json();

export const rejectNonOkResponses = (response) => {
    if (!response?.ok) {
        throw new Error(response?.statusText, { cause: response });
    }
    return response;
};

export const rejectWithError = (reason) => {
    if (!(reason instanceof Error)) {
        throw new Error(reason);
    }
    return reason;
};

export const handle404OrRejectWithError = (error) => {
    switch (true) {
        case error?.cause?.status === 404:
            return [];
        case !(error instanceof Error):
            throw new Error(error);
        default:
            throw new Error(error);
    }
};
