import formatVehicleOptions from '@kbbsdk/global-sdk/utils/formatVehicleOptions';
import { fetchOptions } from '../../../fetchers';
import { SELECT_YOUR_OWN_OPTIONS, PRICE_STANDARD_OPTIONS } from '../../../reference';

export default function getOptionsCreator(editCarDuck) {
    return ({
        onSuccess = () => { },
        onError = () => { },
        vehicleId,
        selectHistory,
        color,
        isEditOptions = false,
        isVinLP = false,
    }) => async (dispatch) => {
        try {
            const options = await fetchOptions({ vehicleId, selectHistory });
            const selectedColor = options.colors.find((c) => c.optionName.toLowerCase() === color);

            dispatch(editCarDuck.creators.setSelectHistory(selectHistory));
            dispatch(editCarDuck.creators.hydrateOptions(options));

            if (selectedColor && selectedColor?.vehicleOptionId) {
                dispatch(editCarDuck.creators.updateSelectedColor({ colorId: selectedColor.vehicleOptionId }));
            }

            if (isEditOptions && selectHistory && selectHistory?.length) {
                const selectHistoryArr = formatVehicleOptions(selectHistory);
                const optionsType = (selectHistoryArr.length > 1) || isVinLP ? SELECT_YOUR_OWN_OPTIONS : PRICE_STANDARD_OPTIONS;

                dispatch(editCarDuck.creators.setOptionsType(optionsType));
            }

            onSuccess();
        } catch (error) {
            onError();
            throw new Error(error);
        }
    };
}
