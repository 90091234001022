import indexArray from 'index-array';
import { batchActions } from '@bonnet/core/redux';
import get from 'lodash.get';
import { getPixallId } from 'atc-js';
import { ATC_BRAND, KBB_BRAND } from 'reaxl-brand/lib/brands';
import { fetchDeleteConsumerUserApi } from '../fetchers/fetchConsumer.js';
import {
    blinkrFetcher,
    emailFetcher,
    emailFriendFetcher,
    fetchDealerLot,
    fetchMarketLocation,
    fetchPreferences,
    fetchRecommendedInventory,
    fetchSavedInventory,
    mobileMessageFetcher,
    validateProfileFetcher,
    fetchSavedSearches,
    getUserApiConsumer,
    getUserApiSavedInventoryBookmarks,
} from '../fetchers';
import Route from '../reference/routes.js';
import {
    adsDuck,
    analyticsDuck,
    dealerEmailDuck,
    dealerLotDuck,
    emailLinkDuck,
    inventoryDuck,
    inventoryResultsDuck,
    mobileMessageDuck,
    myAtcDuck,
    preferencesSelectionsDuck,
    userDuck,
    savedInventoryDuck,
} from '../ducks';

import { buildElotQuery, redirectExternal } from '../utilities';

import adProcessor from '../utilities/adProcessor';
import { getMappedPersonalizationData } from '../context-middleware/withAdParameters.js';

const errorMsg = 'We\'re sorry there has been an error. Please try again later.';

function updateSavedInventory(savedInventory) {

    return (dispatch, getState) => {

        // Send new analytics page event
        // dispatch(analyticsDuck.creators.sendPageEvent(dataIsland));

        // Notify page of loading
        dispatch(inventoryResultsDuck.creators.setLoading());

        // Destructure the listings response
        const {
            listings = [],
            totalResultCount = listings.length,
        } = savedInventory;
        const activeResults = listings.map((listing) => listing.id);
        dispatch(batchActions([
            // Update the inventory collection
            inventoryDuck.creators.addInventory(indexArray(listings, 'id')),
            // Map the order to display results
            inventoryResultsDuck.creators.setActiveResults(activeResults),
            // Update the result count
            inventoryResultsDuck.creators.setCount(totalResultCount),
        ], 'UPDATE_COMPLETE'));

    };

}

function processAds({
    inventory = {},
    search = {},
    vehicle = {},
    brand,
    intent,
} = {}) {
    return (dispatch, getState) => {
        const {
            personalizationEngine,
        } = getState() || {};

        const updatedTargeting = adProcessor({
            inventory,
            search,
            vehicle,
            brand,
            personalizationEngine,
            intent,
        });

        if (brand === KBB_BRAND) {
            getMappedPersonalizationData(personalizationEngine, updatedTargeting);
        }

        dispatch(adsDuck.creators.setPageLevelTargeting({
            targeting: updatedTargeting,
            showAds: true,
        }));
    };
}

function getUserInventory(options) {
    const { brand = ATC_BRAND } = options || {};
    return async (dispatch) => {

        dispatch(myAtcDuck.creators.setKey({
            key: 'inventoryFetched',
            value: true,
        }));

        await Promise.all([getUserApiSavedInventoryBookmarks('KBB'), getUserApiSavedInventoryBookmarks('AT')])
            .then((response = []) => {
                const combinedSavedInventory = [...(!response[0].errors ? response[0] : []), ...(!response[1].errors ? response[1] : [])];
                const inventoryArray = [];
                const newSavedInventoryIds = [];
                const emailAlertsEnabledIds = [];
                combinedSavedInventory.forEach((item) => {
                    item.searchCriteria = {
                        carMileage: item.mileage,
                        carModelYear: item.year,
                        carNewFlag: item.listingType === 'NEW' ? 'Y' : 'N',
                    };
                    if (item.priceDropSubscriptions?.length > 0 && item.priceDropSubscriptions?.some((subscription) => subscription.notificationType === 'EMAIL')) {
                        emailAlertsEnabledIds.push(item.listingId);
                    }
                    inventoryArray.push({
                        [item.listingId]: { ...item },
                    });
                    newSavedInventoryIds.push({
                        listingId: Number(item.listingId),
                        bookmarkId: item.bookmarkId,
                        version: item.version,
                    });
                });
                const savedInventoryIds = combinedSavedInventory.map(((item) => item.listingId));
                // Update the inventory collection
                if (emailAlertsEnabledIds.length) {
                    dispatch(userDuck.creators.setEmailAlertsItems(emailAlertsEnabledIds));
                }
                dispatch(savedInventoryDuck.creators.setSavedInventoryItems(newSavedInventoryIds));
                dispatch(userDuck.creators.setInventoryBookmarks(indexArray(combinedSavedInventory, 'listingId')));
                // dispatch saved inventory items
                if (savedInventoryIds.length > 0) {
                    fetchSavedInventory(savedInventoryIds).then(
                        (inventory) => {
                            if (!inventory) {
                                dispatch(myAtcDuck.creators.setKey({
                                    key: 'serverError',
                                    value: true,
                                }));
                            }
                            const firstItemId = savedInventoryIds[0];

                            dispatch(processAds({
                                inventory: indexArray(inventory.listings, 'id')[firstItemId] || {},
                                search: inventoryArray[0] ? inventoryArray[0][firstItemId].searchCriteria : {},
                                brand,
                            }));
                            dispatch(updateSavedInventory(inventory));
                        }
                    );
                } else {
                    dispatch(processAds({ brand }));
                }

                dispatch(myAtcDuck.creators.setKey({
                    key: 'inventoryLoaded',
                    value: true,
                }));
            }).catch((err) => {
                dispatch(myAtcDuck.creators.setKey({
                    key: 'serverError',
                    value: true,
                }));
                // eslint-disable-next-line no-console
                console.error('fetchListings Error: ', err);
            });
    };
}

function setAlert({
    listing,
    show,
    index,
    restoreInventory,
    brand,
} = {}) {
    return (dispatch, getState) => {
        // If there was a previous alert that had not permanently deleted vehicle,
        // go ahead and delete it to clear room for the new alert cache
        const previousAlert = get(getState(), 'domain.myatc.alert', false);

        if ((previousAlert.show && show) || (!show && !restoreInventory)) {
            const {
                inventory,
                savedInventory,
            } = getState();
            const savedInv = get(savedInventory, 'inventory', {});
            if (previousAlert.index === 0 && savedInv.length > 1) {
                const nextListingId = savedInv[0]?.listingId;
                // dispatch the next listing to the adProcessor
                dispatch(processAds({
                    inventory: inventory[nextListingId],
                    search: get(inventory.inventoryBookmarks, [[nextListingId], 'searchCriteria'], {}),
                    brand,
                }));
            }
        }
        dispatch(myAtcDuck.creators.setKey({
            key: 'alert',
            value: {
                show,
                index: index || 0,
                key: listing ? listing.id : 0,
                ...(listing ? { listing } : {}),
            },
        }));

    };
}

function deleteLocalResults(listing, index) {
    return (dispatch, getState) => {
        // Update local save count in redux
        const activeResults = get(getState(), 'results.activeResults', '');
        const newActiveResults = activeResults.filter((item) => item !== listing.id);
        dispatch(inventoryResultsDuck.creators.setActiveResults(newActiveResults));
        dispatch({
            type: savedInventoryDuck.types.REMOVE_SAVED_INVENTORY_ITEM,
            payload: listing.id,
        });
        dispatch(setAlert({
            listing,
            show: true,
            index,
        }));
        dispatch(savedInventoryDuck.creators.unsaveInventoryItem(listing));
    };
}

function restoreLocalInventory(alert = {}) {
    return (dispatch, getState) => {
        const {
            index,
            listing,
        } = alert;
        const activeResults = get(getState(), 'results.activeResults', '');
        activeResults.push(listing.id);
        dispatch(inventoryResultsDuck.creators.setActiveResults(activeResults));
        dispatch(savedInventoryDuck.creators.saveInventoryItem(listing));
        dispatch(savedInventoryDuck.creators.setSavedInventoryItemByIndex({
            index,
            id: listing.id,
        }));
        dispatch(setAlert({
            restoreInventory: true,
        }));
    };
}

function sendEmail(data, showConfirmation = () => { }) {
    return (dispatch, getState) => emailFetcher({
        comments: data.message,
        contactType: 'MYATC',
        firstName: data.firstName,
        fromEmailAddress: data.emailAddress,
        lastName: data.lastName,
        ownerId: data.id,
        phoneNumber: data.phoneNumber && parseInt(data.phoneNumber.replace(/\D+/g, ''), 10),
        searchZip: data.zip,
        subject: 'General Vehicle Info',
        optIn: data.optIn,
        textPot: data.textPot || '',
        partnerName: 'ConsumerSite',
        pixallThirdPartyId: data.pixallThirdPartyId,
    })
        .then((response) => {
            if (response.status === 'SUCCESS') {
                dispatch(analyticsDuck.creators.sendClickEvent(null, {
                    cmp: 'snd_eml',
                    c: data.id,
                }));
                showConfirmation();
            } else {
                dispatch(dealerEmailDuck.creators.showServerError(`Hmmm, something went wrong. We're working on it and will get it fixed as soon as possible. In the meantime, you can contact them directly at ${data.phoneNumber}`));
            }
        })
        .catch((err) => {
            dispatch(dealerEmailDuck.creators.showServerError(`Hmmm, something went wrong. We're working on it and will get it fixed as soon as possible. In the meantime, you can contact them directly at ${data.phoneNumber}`));
            // eslint-disable-next-line no-console
            console.error(err);
        });
}

function updateRecommendedListings(listing, index) {
    return async (dispatch, getState) => {
        const { user } = getState();
        const pixallId = getPixallId() || 'nl';

        // Update local save count in redux
        const query = {
            searchRadius: get(user, 'location.searchRadius', '50'),
            zip: user.location.zip || '',
            pixallId,
            minListingPriority: 30,
        };
        fetchRecommendedInventory(query)
            .then((results) => {
                if (results) {
                    dispatch(batchActions([
                        inventoryDuck.creators.addInventory(indexArray(results.listings, 'id')),
                        userDuck.creators.setRecommendedInventory(results),
                    ]));
                }
            });
    };
}

const auditBeacon = (beaconType, data) => {
    const search = new URLSearchParams(Object.entries({ beaconType, ...data })
        .map(([k, v]) => [k, typeof v === 'object' ? JSON.stringify(v) : v]));
    const url = `/account/services/beacon?${search}`;
    if (!globalThis.navigator.sendBeacon?.(url)) {
        fetch(url);
    }
};

function updateDealerLot(route, bookmark, zip) {
    return async (dispatch, getState) => {
        const { user } = getState();
        const searchRadius = user?.location?.searchRadius || '50';
        const query = buildElotQuery(route, bookmark, zip, searchRadius);
        auditBeacon('fetchMarketLocation query', {
            zip: query.zip,
            route: route.path,
        });
        const location = await fetchMarketLocation({
            zip: query.zip,
            route: route.path,
        });
        auditBeacon('fetchDealerLot query', query);
        fetchDealerLot(query)
            .then((response) => {
                // Replace listing link to be domain agnostic
                response.listings = response.listings?.map((listing) => {
                    const link = listing.website.href;
                    return {
                        ...listing,
                        website: {
                            href: link.substring(link.indexOf('/cars-for-sale')),
                        },
                    };
                });
                auditBeacon('show eLot', {
                    listingsFromLSC: response.listings?.length,
                    route: route.path,
                });
                dispatch(batchActions([
                    inventoryDuck.creators.addInventory(indexArray(response.listings, 'id')),
                    dealerLotDuck.creators.setKeys({
                        ...response,
                        location,
                    }),
                ]));

            });
    };
}

function updatePreferences(selections, queryEmail, setAnimatedButtonState) {
    return async (dispatch, getState) => {
        const requestBody = {
            selections: Object.fromEntries(selections.map((item) => [item, true])),
            ...(queryEmail && { emailToken: queryEmail }),
        };
        fetchPreferences(requestBody)
            .then((response) => {
                if (response === 200) {
                    dispatch(myAtcDuck.creators.setKey({
                        key: 'preferencesAlert',
                        value: {
                            show: true,
                        },
                    }));
                    const preferencesSelections = get(getState(), 'domain.preferencesSelections', '');
                    dispatch(preferencesSelectionsDuck.creators.removeSelectionsByName(preferencesSelections[0].name));

                    // Create a query params to pass an array of unsubscribed items
                    const queryPath = `unsubscribes=${selections.join(',')}`;
                    const unsubscribeResultPath = `${Route.UNSUBSCRIBE_RESULT.path}?${queryPath}`;
                    setAnimatedButtonState('');
                    redirectExternal(unsubscribeResultPath, { replace: true });
                } else {
                    dispatch(myAtcDuck.creators.setKey({
                        key: 'preferencesAlert',
                        value: {
                            show: true,
                            error: errorMsg,
                        },
                    }));
                    setAnimatedButtonState('');
                }

                // eslint-disable-next-line no-console
            }).catch((error) => {
                // eslint-disable-next-line no-console
                console.error('Preferences Fetch Error:', error);
                dispatch(myAtcDuck.creators.setKey({
                    key: 'preferencesAlert',
                    value: {
                        show: true,
                        error: errorMsg,
                    },
                }));
                setAnimatedButtonState('');
            });
    };
}

const updateAlerts = (data) => async (dispatch, getState) => {
    const { user } = getState();
    const {
        listing,
        alertByEmail,
        alertByText,
        bookmark = {},
    } = data;

    const inventoryBookmarks = get(user, 'bookmarks.inventoryBookmarks', {});
    const alerts = {
        ...(alertByEmail ? { hasEmailAlerts: true } : {}),
        ...(alertByText ? { hasTextAlerts: true } : {}),
        ...(listing.title !== bookmark.title ? { bookmarkTitle: bookmark.title } : {}),
    };
    if (Object.keys(alerts).length > 0) {
        const updatedBookmarks = { ...inventoryBookmarks };

        updatedBookmarks[listing.id] = {
            ...inventoryBookmarks[listing.id],
            ...alerts,
        };
        dispatch(userDuck.creators.setInventoryBookmarks(updatedBookmarks));
    }
};

// on AT page load, this fetches the KBB and AT saved search bookmarks for the account
function updateSavedSearches() {
    return async (dispatch) => {
        await Promise.all([fetchSavedSearches('KBB'), fetchSavedSearches('AT')])
            .then((response = []) => {
                const kbbSavedSearches = [...(!response[0].errors ? response[0].myAtcSearchViewBeans : [])];
                const atSavedSearches = [...(!response[1].errors ? response[1].myAtcSearchViewBeans : [])];

                // temp: add brand field to each search since it's needed to enable/disable alerts in AT tab
                const kbbSavedSearchesWithBrand = kbbSavedSearches.map((search) => ({ ...search, brand: 'KBB' }));
                const atSavedSearchesWithBrand = atSavedSearches.map((search) => ({ ...search, brand: 'AT' }));

                const combinedSavedSearches = [...kbbSavedSearchesWithBrand, ...atSavedSearchesWithBrand].sort((d1, d2) => Date.parse(d2.createdDate) - Date.parse(d1.createdDate));
                const activeSearches = indexArray(combinedSavedSearches, 'bookmarkId');
                const savedIds = combinedSavedSearches.map((el) => el.bookmarkId);
                dispatch(batchActions([
                    userDuck.creators.setSearchBookmarks(activeSearches),
                    myAtcDuck.creators.setKey({ // is this necessary
                        key: 'savedSearches',
                        value: activeSearches,
                    }),
                    myAtcDuck.creators.setKey({
                        key: 'activeSearches',
                        value: savedIds,
                    }),
                ]));
                dispatch(processAds({
                    search: combinedSavedSearches[0]?.searchCriteria,
                }));
            }).catch(() => {
                dispatch(myAtcDuck.creators.setKey({
                    key: 'activeSearches',
                    value: [],
                }));
            });
    };
}

// on AT, enables or disables saved searches bookmark email/text alerts
const updateSearchAlerts = ({
    alert,
    id,
    enabled,
    phone,
    searchName,
    brand,
}) => async (dispatch, getState) => {
    dispatch(userDuck.creators.updateSearchAlerts({
        bookmarkId: id,
        enabledFlag: enabled,
        targets: [alert.toUpperCase()],
        phone,
        searchName,
        brand,
    }));
};

// on AT, updates saved searches bookmark notes
const updateSearch = ({
    id,
    phone,
    notes = null,
    brand,
}) => async (dispatch, getState) => {
    const savedSearches = { ...get(getState(), 'user.bookmarks.searchBookmarks', {}) };
    const {
        bookmarkNotes = '',
        bookmarkTitle,
    } = savedSearches[id];
    const userSearchData = {
        phone,
        bookmark: {
            id: id.toString(),
            notes: notes || bookmarkNotes,
            title: bookmarkTitle,
        },
        brand,
    };
    const updatedSearches = { ...savedSearches };
    if (notes !== null) {
        updatedSearches[id].bookmarkNotes = notes;
    }
    const updatedSearch = await dispatch(userDuck.creators.updateSavedSearchItem(userSearchData));

    if (updatedSearch.bookmark.isSaved) {
        if (updatedSearch.bookmark.version) {
            updatedSearches[id].version = updatedSearch.bookmark.version;
        }

        dispatch(batchActions([
            userDuck.creators.setAlertsModal(false),
            myAtcDuck.creators.setKey({
                key: 'searchUpdated',
                value: {
                    show: true,
                    key: id,
                },
            }),
            userDuck.creators.setSearchBookmarks(updatedSearches),
        ]), 'UPDATE_COMPLETE');
    } else {
        dispatch(myAtcDuck.creators.setKey({
            key: 'errors',
            value: {
                show: true,
                message: 'We\'re sorry, there has been an error while updating your search. Please try again later.',
            },
        }));
    }

};

// on AT, cancel all saved search bookmark email and text alerts
const cancelSearchAlerts = (id) => async (dispatch, getState) => {
    dispatch(userDuck.creators.cancelSearchAlerts(id));
};

const deleteSavedSearch = ({ bookmarkId, version }) => async (dispatch, getState) => {
    const savedSearchIds = [...get(getState(), 'domain.myatc.activeSearches', [])];
    const searchBookmarks = { ...get(getState(), 'user.bookmarks.searchBookmarks', {}) };

    savedSearchIds.splice(savedSearchIds.indexOf(bookmarkId), 1);
    delete searchBookmarks[bookmarkId];

    await dispatch(userDuck.creators.unsaveSearchItem({ id: bookmarkId, version }));
    dispatch(batchActions([
        userDuck.creators.setSearchBookmarks(searchBookmarks),
        userDuck.creators.setSavedSearchIds(savedSearchIds),
        myAtcDuck.creators.setKey({
            key: 'activeSearches',
            value: savedSearchIds,
        }),
    ]));
};

const getBlinkrStatus = () => async (dispatch) => {
    const blinkrResponse = await blinkrFetcher();
    const {
        hasBlinkerListings = false,
    } = blinkrResponse;
    dispatch(myAtcDuck.creators.setKey({
        key: 'isSycActive',
        value: hasBlinkerListings,
    }));
};

export function sendMessage(data) {
    return async (dispatch) => {
        try {
            const response = await mobileMessageFetcher(data);
            if (response.errorMessage) {
                dispatch(mobileMessageDuck.creators.showServerError(response.errorMessage));
            } else {
                dispatch(mobileMessageDuck.creators.showSuccess(response.success));
            }
        } catch (err) {
            dispatch(mobileMessageDuck.creators.showServerError(err.errorMessage));
        }
    };
}

export function emailFriend(data) {
    const requestData = {
        originPageUrl: data.shareLink,
        listingId: data.listingId,
        ownerId: data.ownerId,
        name: `${data.firstName ? data.firstName : ''} ${data.lastName ? data.lastName : ''}`,
        toEmail: data.to,
        toEmailAddress: data.to,
        emailAddress: data.from,
        fromEmailAddress: data.from,
        originPage: 'FYC_VDP_EMAIL_A_FRIEND',
        partnerName: 'ConsumerSite',
        lnxCode: 'VDPEMLSHRE',
        carIds: [
            data.listingId,
        ],
        leadType: 'EMAILTOFRIEND',
        myAtcUserLoggedIn: true,
    };
    return async (dispatch) => {
        try {
            const response = await emailFriendFetcher(requestData);
            if (response.errorMessage) {
                dispatch(emailLinkDuck.creators.showServerError(response.errorMessage));
            } else {
                dispatch(emailLinkDuck.creators.showSuccess(response));
            }
        } catch (err) {
            dispatch(emailLinkDuck.creators.showServerError(err.errorMessage));
        }
    };
}

const errorCancelAccountMsg = 'We\'re sorry, but we have encountered an error. Please try again later.';
function cancelAccount(setAnimatedButtonState, data = {}, onCancelAccountSuccess) {
    return async (dispatch) => {
        const isCancelAccountOK = await fetchDeleteConsumerUserApi(data);
        dispatch(userDuck.creators.setCancelAccountConfirmation({
            success: isCancelAccountOK,
            message: isCancelAccountOK ? '' : errorCancelAccountMsg,
        }));
        if (isCancelAccountOK) {
            onCancelAccountSuccess?.();
        } else {
            setAnimatedButtonState('');
        }
    };
}

function getUserInfo(profile = {}) {
    return async (dispatch) => {
        const {
            email = '',
            firstname = '',
            lastname = '',
            zip = '',
            phone = '',
            version,
        } = Object.keys(profile).length ? profile : await getUserApiConsumer();
        const displayName = [firstname, lastname].filter(Boolean).join(' ');

        dispatch(userDuck.creators.setUserProfile({
            firstname,
            lastname,
            email,
            phone,
            displayName,
            zip,
            version,
        }));
    };
}

const validatePhoneNumberAndZipcode = (currentPhoneNumber, userProfile) => async () => {

    const validData = await validateProfileFetcher({
        currentPhoneNumber,
        updatedPhoneNumber: userProfile.phone,
        zipCode: userProfile.zip,
    });

    const errorMessage = {};

    if (validData.phoneNumberError) {
        errorMessage.phoneNumber = validData.phoneNumberError;
    }

    if (validData.zipCodeError) {
        errorMessage.inputZip = validData.zipCodeError;
    }

    return errorMessage;
};

const updateAnimatedButtonState = (stateValue) => (dispatch, getState) => {
    dispatch({
        type: savedInventoryDuck.types.SET_ANIMATED_BUTTON_STATE,
        payload: stateValue,
    });
};

export default {
    auditBeacon,
    cancelAccount,
    cancelSearchAlerts,
    deleteLocalResults,
    deleteSavedSearch,
    emailFriend,
    getBlinkrStatus,
    getUserInfo,
    getUserInventory,
    restoreLocalInventory,
    setAlert,
    sendEmail,
    sendMessage,
    updateAlerts,
    updateDealerLot,
    updatePreferences,
    updateRecommendedListings,
    updateSavedInventory,
    updateSavedSearches,
    updateSearch,
    updateSearchAlerts,
    validatePhoneNumberAndZipcode,
    processAds,
    updateAnimatedButtonState,
};
