import fetchFromBFF from '../utilities/fetchFromBFF.js';
import { getJSON, rejectNonOkResponses } from '../utilities/fetchHandlers.js';

export default (vehicle, vehicleOwnershipId) => fetchFromBFF(`/account/auth-user/vehicle/id/${vehicleOwnershipId}`, {
    method: 'PUT',
    headers: {
        accesstoken: 'temptoken',
        idtoken: 'temptoken',
    },
    body: JSON.stringify(vehicle),
}).then(rejectNonOkResponses)
    .then(getJSON);
