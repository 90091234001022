import fetchFromBFF from '../utilities/fetchFromBFF.js';
import { getJSON, rejectNonOkResponses } from '../utilities/fetchHandlers.js';

export default (searchId) => fetchFromBFF(`/account/auth-user/saved-search/id/${searchId}`, {
    method: 'DELETE',
    headers: {
        accesstoken: 'temptoken',
        idtoken: 'temptoken',
    },
}).then(rejectNonOkResponses)
    .then(getJSON);
