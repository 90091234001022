import { getResearchableApiData,
    userApiToOwnedDuckMapper,
    researchableApiDataToOwnedDuckMapper,
    getPriceAdvisorApiData,
    updateUserApiZipCode,
    updateUserApiMileage,
    updateUserApiCondition,
    geOwnersAppRoutes,
} from '../helpers';
import { getUserApiOwnedVehicles } from '../../../fetchers';

/* eslint-disable no-unused-vars */
export function updateOwnedVehicleZipCodeActionCreator(ownedVehicleDuck) {
    return ({
        updatedZipCode,
        onUpdateSuccess = () => { },
        onUpdateFailure = () => { },
    }) => async (dispatch, getState) => {
        try {
            const selectedVehicle = ownedVehicleDuck.selectors.getSelectedOwnedVehicle(getState());
            const selectedVehicleTransformed = ownedVehicleDuck.selectors.getUserApiFormattedOwnedVehicle(getState());

            selectedVehicle.vehicleOwnershipData.latestZipcode = updatedZipCode;

            const priceAdvisorApiPromise = getPriceAdvisorApiData(selectedVehicle?.vehicleOwnershipData);
            const optionsRoutePromise = geOwnersAppRoutes(selectedVehicle);
            const [_, priceAdvisorApiData, optionsRoute] = await Promise.all([
                updateUserApiZipCode(selectedVehicleTransformed, updatedZipCode),
                priceAdvisorApiPromise,
                optionsRoutePromise
            ]);

            const ownedVehicles = ownedVehicleDuck.selectors.getOwnedVehicles(getState());
            const updatedVehicles = ownedVehicles.map((vehicle) => {
                if (vehicle.vehicleOwnershipId === selectedVehicle.vehicleOwnershipId) {
                    vehicle.zipCode = updatedZipCode;
                    vehicle.vehicleOwnershipData.latestZipcode = updatedZipCode;
                    return vehicle;
                }
                return vehicle;
            });
            dispatch(ownedVehicleDuck.creators.hydrateOwnedVehicle(updatedVehicles));
            dispatch(ownedVehicleDuck.creators.setSelectedOwnedVehiclePAData({
                vehicleOwnershipId: selectedVehicle.vehicleOwnershipId, 
                priceAdvisorData: priceAdvisorApiData
            }));
            if (optionsRoute) {
                dispatch(ownedVehicleDuck.creators.setSelectedOwnedVehicleRoutes({
                    vehicleOwnershipId: selectedVehicle.vehicleOwnershipId, 
                    routes: optionsRoute
                }));
            }

            onUpdateSuccess();
        } catch (error) {
            onUpdateFailure();
            throw Error(error);
        }
    };
}

/* eslint-disable no-unused-vars */
export function updateOwnedVehicleMileageActionCreator(ownedVehicleDuck) {
    return ({
        mileage,
        onUpdateSuccess = () => { },
        onUpdateFailure = () => { },
    }) => async (dispatch, getState) => {
        try {
            const selectedVehicle = ownedVehicleDuck.selectors.getSelectedOwnedVehicle(getState());
            const selectedVehicleTransformed = ownedVehicleDuck.selectors.getUserApiFormattedOwnedVehicle(getState());

            const mileageInt = Number.parseInt(mileage.replace(/,/g, ''), 10);
            selectedVehicle.vehicleOwnershipData.latestMileage = mileageInt;

            const priceAdvisorApiPromise = getPriceAdvisorApiData(selectedVehicle?.vehicleOwnershipData);
            const optionsRoutePromise = geOwnersAppRoutes(selectedVehicle);
            const [_, priceAdvisorApiData, optionsRoute] = await Promise.all([
                updateUserApiMileage(selectedVehicleTransformed, mileageInt),
                priceAdvisorApiPromise,
                optionsRoutePromise
            ]);
            const ownedVehicles = ownedVehicleDuck.selectors.getOwnedVehicles(getState());
            const updatedVehicles = ownedVehicles.map((vehicle) => {
                if (vehicle.vehicleOwnershipId === selectedVehicle.vehicleOwnershipId) {
                    vehicle.vehicleOwnershipData.latestMileage = mileageInt;
                    vehicle.mileage = mileageInt;
                    return vehicle;
                }
                return vehicle;
            });

            dispatch(ownedVehicleDuck.creators.hydrateOwnedVehicle(updatedVehicles));
            dispatch(ownedVehicleDuck.creators.setSelectedOwnedVehiclePAData({
                vehicleOwnershipId: selectedVehicle.vehicleOwnershipId, 
                priceAdvisorData: priceAdvisorApiData
            }));
            if (optionsRoute) {
                dispatch(ownedVehicleDuck.creators.setSelectedOwnedVehicleRoutes({
                    vehicleOwnershipId: selectedVehicle.vehicleOwnershipId, 
                    routes: optionsRoute
                }));
            }

            onUpdateSuccess();
        } catch (error) {
            onUpdateFailure();
            throw Error(error);
        }
    };
}

/* eslint-disable no-unused-vars */
export function updateOwnedVehicleConditionActionCreator(ownedVehicleDuck) {
    return ({
        condition,
        onUpdateSuccess = () => { },
        onUpdateFailure = () => { },
    }) => async (dispatch, getState) => {
        try {
            const selectedVehicle = ownedVehicleDuck.selectors.getSelectedOwnedVehicle(getState());
            const selectedVehicleTransformed = ownedVehicleDuck.selectors.getUserApiFormattedOwnedVehicle(getState());

            selectedVehicle.vehicleOwnershipData.latestCondition = condition;

            const priceAdvisorApiPromise = getPriceAdvisorApiData(selectedVehicle?.vehicleOwnershipData);
            const optionsRoutePromise = geOwnersAppRoutes(selectedVehicle);
            const [_, priceAdvisorApiData, optionsRoute] = await Promise.all([
                updateUserApiCondition(selectedVehicleTransformed, condition), 
                priceAdvisorApiPromise,
                optionsRoutePromise
            ]);
            
            const ownedVehicles = ownedVehicleDuck.selectors.getOwnedVehicles(getState());
            const updatedVehicles = ownedVehicles.map((vehicle) => {
                if (vehicle.vehicleOwnershipId === selectedVehicle.vehicleOwnershipId) {
                    vehicle.vehicleOwnershipData.latestCondition = condition;
                    return vehicle;
                }
                return vehicle;
            });
            dispatch(ownedVehicleDuck.creators.hydrateOwnedVehicle(updatedVehicles));
            dispatch(ownedVehicleDuck.creators.setSelectedOwnedVehiclePAData({
                vehicleOwnershipId: selectedVehicle.vehicleOwnershipId, 
                priceAdvisorData: priceAdvisorApiData
            }));
            if (optionsRoute) {
                dispatch(ownedVehicleDuck.creators.setSelectedOwnedVehicleRoutes({
                    vehicleOwnershipId: selectedVehicle.vehicleOwnershipId, 
                    routes: optionsRoute
                }));
            }

            onUpdateSuccess();
        } catch (error) {
            onUpdateFailure();
            throw Error(error);
        }
    };
}

/* eslint-disable no-unused-vars */
export function getUpdatedDataForSelectedVehicle(ownedVehicleDuck) {
    return () => async (dispatch, getState) => {
        try {
            const ownedVehicles = ownedVehicleDuck.selectors.getOwnedVehicles(getState());
            const selectedVehicle = ownedVehicleDuck.selectors.getSelectedOwnedVehicle(getState());
            const selectedVehicleIndex = ownedVehicleDuck.selectors.getSelectedOwnedVehicleIndex(getState());

            const updatedOwnedVehicles = await getUserApiOwnedVehicles();
            const updatedVehicleIndex = updatedOwnedVehicles.findIndex((veh) => veh.kbb_vehicle_id === selectedVehicle.vehicleId);

            const updatedSelectedVehicle = {
                ...selectedVehicle,
                ...userApiToOwnedDuckMapper(updatedOwnedVehicles[updatedVehicleIndex]),
            };

            let updatedVehicles = ownedVehicles;
            updatedVehicles[selectedVehicleIndex] = updatedSelectedVehicle;

            const priceAdvisorApiPromise = getPriceAdvisorApiData(updatedSelectedVehicle?.vehicleOwnershipData);
            const optionsRoutePromise = geOwnersAppRoutes(updatedSelectedVehicle);
            const [priceAdvisorApiData, optionsRoute] = await Promise.all([
                priceAdvisorApiPromise,
                optionsRoutePromise,
            ]);

            dispatch(ownedVehicleDuck.creators.hydrateOwnedVehicle(updatedVehicles));
            dispatch(ownedVehicleDuck.creators.setSelectedOwnedVehiclePAData({
                vehicleOwnershipId: updatedSelectedVehicle?.vehicleOwnershipId,
                priceAdvisorData: priceAdvisorApiData,
            }));

            if (optionsRoute) {
                dispatch(ownedVehicleDuck.creators.setSelectedOwnedVehicleRoutes({
                    vehicleOwnershipId: updatedSelectedVehicle?.vehicleOwnershipId,
                    routes: optionsRoute,
                }));
            }

        } catch (error) {
            throw Error(error);
        }
    };
}
