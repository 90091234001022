import { fetchJSONWithCircuitBreaker } from '../utilities';

export default function fetchDataIsland(params = {}, fallback = {}) {
    return fetchJSONWithCircuitBreaker(
        '/account/services/dataisland',
        { query: params },
        'fetchDataIsland Error: ',
        fallback,
        'fetchDataIsland'
    );
}
