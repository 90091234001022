import { getHeaderAuthToken } from '../utilities/getHeaderAuthToken';
import { fetchJSONWithCircuitBreaker } from '../utilities';

export default function blinkrFetcher(fallback = {}) {
    return getHeaderAuthToken().then((req) => fetchJSONWithCircuitBreaker(
        '/account/blinkr/listings/myAtc',
        {
            method: 'POST',
            body: {
                myAtcToken: req.idtoken,
            },
            credentials: Object.keys(req).length > 0 ? 'omit' : 'same-origin',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
            },
        },
        'fetchBlinkr Error: ',
        fallback,
        'blinkrFetcher'
    ));
}
