import fetchFromBFF from '../utilities/fetchFromBFF.js';
import { getJSON, rejectNonOkResponses } from '../utilities/fetchHandlers.js';

export default (vehicleId) => fetchFromBFF(`/account/auth-user/vehicle/id/${vehicleId}`, {
    method: 'DELETE',
    headers: {
        accesstoken: 'temptoken',
        idtoken: 'temptoken',
    }
}).then(rejectNonOkResponses)
    .then(getJSON);
