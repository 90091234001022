import { isYmmFormValid } from './helpers';

export default (state, action, { initialState, types }) => {

    const { payload } = action;

    switch (action.type) {

        case types.SET_PICKER_TYPE: {
            return {
                ...state,
                pickerType: payload.pickerType,
            };
        }

        case types.SET_YEARS: {
            return {
                ...state,
                ymm: {
                    ...state.ymm,
                    years: payload.years,
                },
            };
        }

        case types.SET_MAKES: {
            return {
                ...state,
                ymm: {
                    ...state.ymm,
                    makes: payload,
                },
            };
        }

        case types.SET_MODELS: {
            return {
                ...state,
                ymm: {
                    ...state.ymm,
                    models: payload.models,
                },
            };
        }

        case types.SELECT_YEAR: {
            return {
                ...state,
                ymm: {
                    ...state.ymm,
                    selectedYear: payload,
                    selectedMake: null,
                    selectedModel: null,
                    models: [],
                },
            };
        }

        case types.SELECT_MAKE: {
            return {
                ...state,
                ymm: {
                    ...state.ymm,
                    selectedMake: payload,
                    selectedModel: null,
                },
            };
        }

        case types.SELECT_MODEL: {
            return {
                ...state,
                ymm: {
                    ...state.ymm,
                    selectedModel: payload,
                    enableSubmit: isYmmFormValid({
                        ...state.ymm,
                        selectedModel: payload,
                    }),
                },
            };
        }

        case types.SET_MILEAGE: {
            return {
                ...state,
                ymm: {
                    ...state.ymm,
                    mileage: payload,
                    enableSubmit: isYmmFormValid({
                        ...state.ymm,
                        mileage: payload,
                    }),
                },
            };
        }

        case types.SET_ZIPCODE: {
            return {
                ...state,
                ymm: {
                    ...state.ymm,
                    zip: payload,
                    enableSubmit: isYmmFormValid({
                        ...state.ymm,
                        zip: payload,
                    }),
                },
            };
        }

        case types.SET_ZIP_ERROR: {
            return {
                ...state,
                ymm: {
                    ...state.ymm,
                    zipError: payload
                },
            };
        }

        case types.SET_YMM_ERROR: {
            return {
                ...state,
                ymm: {
                    ...state.ymm,
                    error: payload,
                },
            };
        }

        case types.SET_VIN_LP_DATA: {
            const { 
                ...rest 
            } = payload;
            return {
                ...state,
                ...rest,
            };
        }

        case types.SET_ENTERED_VIN: {
            const {
                isValid,
                error,
                errorMessage,
                ...rest
            } = payload;

            return {
                ...state,
                vin: {
                    isValid,
                    error,
                    errorMessage,
                    ...rest,
                },
            };
        }

        case types.RESET_STATE: {
            return initialState;
        }

        default:
            return state;
    }
};
