/* eslint-disable import/prefer-default-export */
import formatVehicleOptions from '@kbbsdk/global-sdk/utils/formatVehicleOptions';

export const formatCarToAddDataForSave = (carToAdd) => ({
    vin: carToAdd.vin || '',
    kbb_vehicle_id: carToAdd.vehicleId.toString(),
    save_name: `${carToAdd.year} ${carToAdd.make} ${carToAdd.model} ${carToAdd.trim}`,
    year: carToAdd.year,
    kbb_make_name: carToAdd.make,
    kbb_model_name: carToAdd.model,
    kbb_trim_name: carToAdd.trim,
    brand: carToAdd.brand,
    vehicle_details: {
        kbb_vehicle_options: formatVehicleOptions(carToAdd.selectHistory),
        latest_zipcode: carToAdd.zip,
        latest_condition: carToAdd.condition,
        latest_mileage: carToAdd.mileage,
        vehicle_color: carToAdd.extColor,
    },
    license_plate: carToAdd.licensePlate || carToAdd.lp || '',
});