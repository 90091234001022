import fetch from '@bonnet/core/fetch/fetch';
import { parseKbbOptionsFromUrl } from '../../utilities/parseKbbOptionsFromUrl.js';
import { parseParamFromUrl } from '../../utilities';
import { updateUserApiOwnedVehicle, getRoutesFromRouter, fetchPrice } from '../../fetchers/index';
import getOptionIds from '../../utilities/getOptionIds.js';
import { friendlyUrl, generateOptionsQuery } from '../../component/argo/utils/urlUtils.js';

export async function updateUserApiZipCode(vehicle, updatedZipCode) {
    vehicle.vehicle_details.latest_zipcode = updatedZipCode;
    await updateUserApiOwnedVehicle(vehicle, vehicle?.vehicleid);
}

export async function updateUserApiMileage(vehicle, updatedMileage) {
    vehicle.vehicle_details.latest_mileage = updatedMileage;
    await updateUserApiOwnedVehicle(vehicle, vehicle?.vehicleid);
}

export async function updateUserApiCondition(vehicle, updatedCondition) {
    vehicle.vehicle_details.latest_condition = updatedCondition;
    return updateUserApiOwnedVehicle(vehicle, vehicle?.vehicleid);
}

// First level only
/* eslint-disable camelcase */
function formatVehicleDetailsFromVehicleOwnership(vehicle_details) {
    return Object.keys(vehicle_details).reduce((obj, key) => {
        const newKey = key.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
        return { ...obj, [newKey]: vehicle_details[key] };
    }, {});
}

export function ownedDuckToUserApiMapper(vehicle) {
    if (!vehicle) {
        return;
    }

    return {
        vehicleid: vehicle.vehicleOwnershipId,
        consumerid: vehicle.consumerId,
        vin: vehicle?.vehicleOwnershipData?.vin,
        kbb_vehicle_id: vehicle?.vehicleOwnershipData?.kbbVehicleId,
        license_plate: vehicle?.vehicleOwnershipData?.licensePlate,
        save_name: vehicle?.vehicleOwnershipData?.saveName,
        kbb_make_name: vehicle?.vehicleOwnershipData?.kbbMakeName || null,
        kbb_model_name: vehicle?.vehicleOwnershipData?.kbbModelName || null,
        kbb_trim_name: vehicle?.vehicleOwnershipData?.kbbTrimName || null,
        year: vehicle?.vehicleOwnershipData?.year || null,
        vehicle_details: {
            kbb_vehicle_options: vehicle?.vehicleOwnershipData?.kbbVehicleOptions,
            latest_zipcode: vehicle?.vehicleOwnershipData?.latestZipcode,
            latest_condition: vehicle?.vehicleOwnershipData?.latestCondition,
            latest_mileage: vehicle?.vehicleOwnershipData?.latestMileage,
            vehicle_color: vehicle?.vehicleOwnershipData?.vehicleColor,
        },
        valuation: vehicle?.vehicleOwnershipData?.valuation,
    };
}

/* eslint-disable camelcase */
export function userApiToOwnedDuckMapper(userApiOwnedVehicle) {
    const { vehicleid = '', consumerid = '', vehicle_details = {}, ...rest } = userApiOwnedVehicle;
    const formattedVehicleDetailsFromVehicleOwnership = formatVehicleDetailsFromVehicleOwnership(vehicle_details);

    return {
        vehicleOwnershipId: vehicleid,
        consumerId: consumerid,
        vehicleOwnershipData: {
            ...formatVehicleDetailsFromVehicleOwnership(rest),
            ...formattedVehicleDetailsFromVehicleOwnership,
        },
        mileage: formattedVehicleDetailsFromVehicleOwnership?.latestMileage,
        zipCode: formattedVehicleDetailsFromVehicleOwnership?.latestZipcode,
    };
}

export function researchableApiDataToOwnedDuckMapper(vehicleResearchableApiData) {
    return {
        defaultImage: vehicleResearchableApiData?.defaultImage,
        shortName: vehicleResearchableApiData?.name,
        ...vehicleResearchableApiData,
    };
}

export function priceAdvisorApiDataToOwnedDuckMapper(vehiclePriceAdvisorApiData) {
    return { ...vehiclePriceAdvisorApiData };
}

export function vinsightsSupplyDemandDataToOwnedDuckMapper(vinsightsSupplyDemandApiData) {
    return {
        vinsightsSupplyDemandData: {
            ...vinsightsSupplyDemandApiData?.supplyAndDemandRetail,
        },
    };
}

export async function getResearchableApiData(kbbVehicleIds) {
    if (kbbVehicleIds.length === 0) return [];

    const response = await fetch('/account/services/researchableVehicleData', { 
        query: kbbVehicleIds,
        retries: 1,
    });
    const responseJson = await response.json();

    if (responseJson?.statuscode > 200) {
        throw new Error(responseJson?.message)
    }

    return responseJson;
}

export async function getResearchableApiRecallData(vehicleId, signal) {
    if (!vehicleId) return [];

    const response = await fetch('/account/services/researchableRecallData', {
        query: {
            vehicleId,
        },
        signal,
        retries: 1, 
    });

    const responseJson = await response.json();

    if (responseJson?.statuscode > 200) {
        throw new Error(responseJson?.message)
    }

    return responseJson;
}

export async function geOwnersAppRoutes(vehicle) {
    if (!vehicle) return [];

    const {
        year,
        make,
        model,
        vehicleId,
        mileage,
        vehicleName,
        vehicleOwnershipData: {
            kbbVehicleOptions,
            vin,
            latestCondition,
            licensePlate,
            latestZipcode,
            vehicleColor
        } = {},
    } = vehicle;

    let formattedOptions = null;

    if (kbbVehicleOptions && (kbbVehicleOptions || []).length > 0) {
        formattedOptions = generateOptionsQuery(kbbVehicleOptions);
    }

    let result = null;
    try {
        result = await getRoutesFromRouter(
            'owners-argo',
            [{
                routeName: 'options',
                parameters: {
                    make: friendlyUrl(make || ''),
                    year,
                    model: friendlyUrl(model || ''),
                    trim: friendlyUrl(vehicleName || ''),
                    vehicleid: vehicleId,
                    mileage: mileage?.toString().replace(',', '') || null,
                    condition: latestCondition || 'good',
                    lp: licensePlate || null,
                    vin: vin || null,
                    options: formattedOptions,
                    zipcode: latestZipcode || null,
                    intent: 'trade-in-sell',
                    pricetype: 'trade-in',
                    extcolor: vehicleColor || '',
                },
            }]
        );
    } catch(e) {
        console.log(`geOwnersAppRoutes ${e}`);
    }

    return result;
}

export async function getServiceAndRepairRoutes(vehicle) {
    if (!vehicle) return [];

    const {
        year,
        make,
        model,
        vehicleId,
        mileage,
        vehicleOwnershipData: {
            kbbVehicleOptions: options,
            vin,
        } = {},
        condition = 'good',
    } = vehicle;

    return getRoutesFromRouter(
        'Ownership',
        [{
            routeName: 'recall_ymm',
            parameters: {
                year,
                make,
                model,
                tab: 'recalls',
                vehicleId,
                mileage,
                options,
                condition,
                vin,
            },
        }, {
            routeName: 'repair_ymm',
            parameters: {
                year,
                make,
                model,
                tab: 'auto-repair',
                vehicleId,
                mileage,
                options,
                condition,
                vin,
            },
        }, {
            routeName: 'service_centers_slp_redirect',
            parameters: {},
        },
        ]
    );
}
export async function getPriceAdvisorApiData(vehicle) {
    if (!vehicle) {
        return null;
    }

    const optionIds = await getOptionIds(vehicle);

    return await fetchPrice({vehicle, shoppersVehicle: false, optionIds });
}

export async function getVinDemandData(vin, signal) {
    if (!vin) return null;

    const response = await fetch('/account/services/vinsightsSupplyDemandService', {
        query: {
            vin,
        },
        signal,
        retries: 1, // returns error data from fetcher
    });

    const responseJson = await response.json();

    if (responseJson?.statuscode > 200) {
        throw new Error(responseJson?.message)
    }

    return responseJson;
}

export const formatVehicleDetails = (vehicles, zip) => {
    const defaultVehicleDetails = {
        vehicle_color: '',
        latest_zipcode: zip, // may or may not be available
        latest_condition: 'good',
        latest_mileage: 0,
        kbb_vehicle_options: [],
    };

    const formattedVehicles = vehicles.map((v) => {
        const { vehicle_details } = v;

        return {
            ...v,
            vehicle_details: {
                vehicle_color: vehicle_details?.vehicle_color || defaultVehicleDetails?.vehicle_color,
                latest_zipcode: vehicle_details?.latest_zipcode || defaultVehicleDetails?.latest_zipcode,
                latest_condition: vehicle_details?.latest_condition || defaultVehicleDetails?.latest_condition,
                latest_mileage: vehicle_details?.latest_mileage || defaultVehicleDetails?.latest_mileage,
                kbb_vehicle_options: vehicle_details?.kbb_vehicle_options || [],
            },
        };
    }).filter((v) => v.vehicle_details.latest_zipcode && v.kbb_vehicle_id);
    
    // Filter out vehicles with no zip code & no kbb vehilce id
    return formattedVehicles;
};

export const formatStashDataForSave = (stashData, zip) => ({
    vin: stashData.vin || '',
    kbb_vehicle_id: stashData.vehicleId.toString(),
    valuation: null,
    save_name: stashData.name,
    vehicle_details: {
        kbb_vehicle_options: stashData.vehicleOptions || [],
        latest_zipcode: zip || '92618',
        latest_condition: stashData.condition || 'good',
        latest_mileage: stashData.mileage || '',
        vehicle_color: stashData.extColor || '',
    },
    license_plate: stashData.licensePlate || '',
});
