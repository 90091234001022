import { Pixall, getBrandUnit } from 'reaxl-analytics-handlers';
import { USER_ACCOUNT_EVENT_SOURCE } from './utils';

const getFeatureElement = (brand) => {
    if (!brand) {
        return '';
    }

    return brand.toLowerCase() === 'kbb' ? 'my-kbb' : 'my-at';
};

const getPageIdElement = (brand) => {
    if (!brand) {
        return '';
    }

    return brand.toLowerCase() === 'kbb' ? 'mykbb_ms' : 'myat_ms';
};

const savedSearchesClick = (ctaText) => ({
    dataLayer = {},
    state = {},
    data = {},
} = {}, option) => {
    const { brand = '' } = state;
    const { selection = '' } = data;

    Pixall.sendClickEvent({
        eventSource: USER_ACCOUNT_EVENT_SOURCE,
        pageId: getPageIdElement(brand),
        label: `${ctaText}${selection ? `::${selection}` : ''}`,
        pageType: getBrandUnit(brand),
        feature: getFeatureElement(brand),
        hasListingType: false,
    }, dataLayer, option);

    return { ctaText };
};

export default savedSearchesClick;
