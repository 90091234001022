import { useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { adBlockDetect } from 'atc-js';
import { getMarket } from '@atc/bonnet-reference';
import { useDevice } from '@bonnet/core/device';
import { getActiveExperiments } from 'reaxl-optimizely';
import { getSignedInCookie } from 'reaxl-auth';
import PersonalizationEngineModule from '../modules/PersonalizationEngineModule';
import {
    userDuck,
    brandDuck,
    inventoryDuck,
} from '../ducks';
import { isKBBBrand } from '../utilities/brands';

export default function useDataLayer() {
    const [cookies = {}] = useCookies(['pxa_id', 'pixall_abc', 'abc', 'pxa_realid', 'exp']);
    const query = useSelector((state) => state.query ?? {});
    const zip = useSelector(userDuck.selectors.getZip);
    const brand = useSelector(brandDuck.selectors.getDuckState);
    const device = useDevice();
    const isAdBlocker = async () => (typeof window !== 'undefined') && adBlockDetect();
    const activeExperiments = getActiveExperiments();
    const { success, payload: market } = async () => getMarket(zip);
    const inventory = useSelector(inventoryDuck.selectors.getDuckState);
    const personalization = useSelector(PersonalizationEngineModule.duck.selectors.selectConsumerInsights);
    const preferences = useSelector(PersonalizationEngineModule.duck.selectors.selectPreferences);
    const consumerAdTargets = useSelector(PersonalizationEngineModule.duck.selectors.selectConsumerAdTargets);
    const consumerAdTargetsWithoutCAMPBias = useSelector(PersonalizationEngineModule.duck.selectors.selectConsumerAdTargetsWithoutCAMPBias);
    const consumerMarketingSegments = useSelector(PersonalizationEngineModule.duck.selectors.selectConsumerMarketingSegments);
    // TODO: state.domain.myatc is always myatc, even on MyKBB, change this here when it's changed.
    // const domainKey = isKBBBrand(brand) ? 'mykbb' : 'myatc';
    const domainKey = 'myatc';
    const environmentName = useSelector((state) => state.domain?.[domainKey]?.env ?? {});

    return {
        dataLayerVersion: '1',
        catalog: brand,
        consumerInsights: {
            ...personalization,
            consumerCampaignPreferencesAddedByRule: preferences?.topRankedVehicle?.addedByRule,
        },
        consumerAdTargets,
        consumerAdTargetsWithoutCAMPBias,
        consumerMarketingSegments,
        application: {
            name: 'web',
            businessUnit: isKBBBrand(brand) ? 'kelley blue book' : 'autotrader',
            environment: environmentName === 'prod' ? 'production' : environmentName,
            version: isKBBBrand(brand) ? 'kbb 1.0.0' : 'autotrader 1.0.0',
        },

        get user() {
            return {
                get isLoggedIn() {
                    return (typeof window !== 'undefined') ? getSignedInCookie() : '0';
                },
                isInternalUser: false,
                adBlockerState: isAdBlocker ? 'on' : 'off',
                // TODO: DEV how do we get this?
                type: null,
                // TODO: DEV how do we get this?
                bridgeUser: null,
                // TODO: DEV how do we get this?
                sessionControl: null,
                // TODO: DEV how do we get this?
                consumerSessionId: null,
                zipCode: zip,
                dma: success ? market.dma : null,
            };
        },
        page: {
            // bonnet page name
            siteSection: null,
            detailPageName: null,
            pageType: null,
            pageName: null,
            event: null,
            eventElement: null,
            experimentId: activeExperiments,
            // TODO: DEV how do we get this?
            leadTrackingId: null,
            // TODO: DEV how do we get this?
            experimentVariant: null,
            pixallId: cookies.pxa_id,
            pixallABC: cookies.pixall_abc || cookies.abc || cookies.pxa_realid || cookies.pxa_id,
            pixallPageInstanceId: null,
        },
        device,
        query,
        inventory,
    };

}
