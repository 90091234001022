import { optionsLoader } from '../services/service-utilities/optionsLoader';

const getOptionIds = async (vehicle) => {
    const vehicleId = vehicle.kbbVehicleId || vehicle.kbb_vehicle_id;
    const defaultSelectHistory = vehicle.kbbVehicleOptions || vehicle.vehicle_preferences?.kbb_vehicle_options || [];

    if (defaultSelectHistory.join('').includes('|')) {
        const selectHistory = defaultSelectHistory.join('|');
        const userSelectHistory = await optionsLoader.load(`${vehicleId}:${selectHistory}`);

        return userSelectHistory;
    }

    return defaultSelectHistory;
};

export default getOptionIds;
