import React from 'react';
import { Footer as KBBFooter } from '@kbbsdk/global-sdk/navigation/web';
import { AnalyticsContext } from '@kbbsdk/global-sdk/analytics/react/context';
import FallbackProxy from '@kbbsdk/global-sdk/analytics/FallbackProxy';
import navLinks from '../reference/kbbGlobalNavV2.json';

const KBBFooterContainer = () => (
    <AnalyticsContext.Provider value={FallbackProxy}>
        <KBBFooter
            navigation={navLinks}
            enableGtm
            enableOneTrust
        />
    </AnalyticsContext.Provider>
);

export default KBBFooterContainer;
