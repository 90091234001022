import { fetchWithCircuitBreaker } from '../utilities';

export default (data) => fetchWithCircuitBreaker({
    path: '/account/gearbox/searchoptions',
    data: {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify(data),
    },

    id: 'searchOptionsFetch',

}).then((response) => response.json());
