import _get from 'lodash.get';
import { Cookies } from 'atc-js';
import deepmerge from 'deepmerge';
import { getMarket, getKbbDmaCode } from '@atc/bonnet-reference';
import addAdParameters from '@atc/bonnet-ctx-ad-params';
import withCtxMiddleware from '@bonnet/with-ctx-middleware';
import { getDataLayerLocation } from '@kbbsdk/global-sdk/zipcode-service/web/withDataLayerLocation';
import { ATC_BRAND, KBB_BRAND } from 'reaxl-brand/lib/brands';
import { adsDuck } from '../ducks';
import pageEventReference from '../analytics/tags/pageEvents';
import { isPROD } from '../utilities/getEnvironment';

const getClientAddress = (headers = {}) => {
    const xForwardedfor = _get(headers, 'x-forwarded-for', '').split(',')[0];
    const remote = _get(headers, ['connection', 'remoteAddress'], '');
    let clientAddr = 'nl';
    if (xForwardedfor) {
        clientAddr = xForwardedfor;
    } else if (remote) {
        clientAddr = remote;
    }
    return clientAddr;
};

const getBehaviourialTarget = (dxatc) => {
    if (dxatc) {
        let btc = decodeURIComponent(escape(dxatc));
        if (btc && btc.startsWith('=')) {
            btc = btc.slice(1);
        }
        return btc;
    }
    return 'nl';
};

const assignAdState = (params, adState, serverSide) => {
    if (!serverSide) {
        Object.assign(params, { pixallId: _get(adState, ['pageLevelTargeting', 'pixall'], '') });
        Object.assign(params, { language: _get(adState, ['pageLevelTargeting', 'lng'], '') });
        Object.assign(params, { anml: _get(adState, ['pageLevelTargeting', 'anml'], '') });
        Object.assign(params, { betaGroup: _get(adState, ['pageLevelTargeting', 'betagrp'], '') });
        Object.assign(params, { mcmid: _get(adState, ['pageLevelTargeting', 'mcmid'], '') });
        Object.assign(params, { ip: _get(adState, ['pageLevelTargeting', 'ip'], '') });
        Object.assign(params, { btc: _get(adState, ['pageLevelTargeting', 'btc'], '') });
        Object.assign(params, { os: _get(adState, ['pageLevelTargeting', 'os'], '') });
        Object.assign(params, { uuid: _get(adState, ['pageLevelTargeting', 'uuid'], '') });
    }
};

const getDataIsland = (data) => {
    const dataIsland = _get(data, 'dataIsland');
    if (dataIsland) {
        return dataIsland;
    }
    return _get(data, ['pageData', 'dataIsland'], {});
};

const getDeviceType = (isMobile, isTablet, isKbb = false) => {
    let deviceType = 'desktop';
    if (isTablet === 'true') {
        deviceType = isKbb ? 'browser_tablet' : 'tablet';
    } else if (isMobile === 'true') {
        deviceType = isKbb ? 'browser_phone' : 'phone';
    } else {
        deviceType = isKbb ? 'browser_unk' : 'desktop';
    }
    return deviceType;
};

const getStandardParams = async (params, dataIsland, req, cookies, brand, pageAttributes, optionalParams, pageInst) => {
    const {
        siteSection,
        pageType,
        detailPageName,
    } = pageAttributes;
    const headers = _get(req, 'headers', {});
    const akamai = new Cookies(_get(headers, 'x-akamai-device-characteristics', {}));
    const isMobile = _get(akamai, ['cookies', 'is_mobile'], 'false');
    const isTablet = _get(akamai, ['cookies', 'is_tablet'], 'false');
    const osid = _get(cookies, ['cookies', 'SessionId'], _get(cookies, ['cookies', 'kbbSessionId', '']));
    const { zip = '' } = getDataLayerLocation(cookies) || {};
    const { dyn = '' } = req?.query || {};
    const pixallId = _get(cookies, ['cookies', 'pxa_id'], 'nl');

    /**
     * Getting kbbdma. kbb dma via bonnet needs bonnet plugin and bonnet getKbbDma function.
     * AT dma can be grabbed from getMarket service
     */
    const kbbDmaCode = (brand === 'kbb' && zip) ? await getKbbDmaCode(zip, 'account') : 'nl';
    const { success ,payload: market } = zip && zip !== 'nl' ? await getMarket(zip) : {};
    const dmaCode = brand !== KBB_BRAND ? success ? market?.dma?.code : 'nl' : kbbDmaCode;

    Object.assign(params, { osid: osid || 'nl' });
    Object.assign(params, { pgInst: pageInst || 'nl' });
    Object.assign(params, { language: _get(headers, 'accept-language', 'en').substring(0, 2) });
    Object.assign(params, { ip: getClientAddress(headers) });
    Object.assign(params, { anml: _get(cookies, ['cookies', 'adbanml'], 'n') });
    Object.assign(params, { betaGroup: _get(cookies, ['cookies', 'exp'], '').replace(/~/g, '|') });
    Object.assign(params, { btc: getBehaviourialTarget(_get(cookies, ['cookies', 'dxatc'], '')) });
    Object.assign(params, { os: _get(akamai, ['cookies', 'device_os'], 'nl') });
    Object.assign(params, { uuid: _get(cookies, ['cookies', 'aam_uuid'], 'nl') });
    Object.assign(params, { device: getDeviceType(isMobile, isTablet, brand === 'kbb') });
    Object.assign(params, { zip });
    Object.assign(params, { dma: dmaCode });
    Object.assign(params, { pixallId });
    if (dyn) {
        Object.assign(optionalParams, { dyn });
    }

    // GA Tags
    Object.assign(optionalParams, { gasec: siteSection || 'nl' });
    Object.assign(optionalParams, { gapg: pageType || 'nl' });
    Object.assign(optionalParams, { gadpn: detailPageName || 'nl' });
};

function joinVehicleValues(arr, attribute) {
    const filterOutInconclusiveValues = arr && arr.filter((item) => item[attribute]?.desc?.toLowerCase() !== 'inconclusive');
    if (filterOutInconclusiveValues && filterOutInconclusiveValues.length > 0) {
        return filterOutInconclusiveValues.map((item) => item[attribute]?.desc).join(',');
    }
    return 'inconclusive';
}

function getConsumerAdTargetsData({ consumerAdTargets }) {
    if (consumerAdTargets && Object.keys(consumerAdTargets).length > 0) {
        const {
            targetModels,
            targetMakes,
            targetIntent,
            targetTradeInVehicle,
            targetSegments,
            targetInsightsAvailable,
        } = consumerAdTargets;

        return {
            altTargetModel: targetModels?.models?.[1]?.model?.desc,
            targetInsightsAvailable,
            targetIntent,
            targetTradeInVehicle,
            targetTradeInMake: targetTradeInVehicle?.make?.desc,
            targetTradeInModel: targetTradeInVehicle?.model?.desc,
            targetMakes: joinVehicleValues(targetMakes?.makes, 'make'),
            targetModels: joinVehicleValues(targetModels?.models, 'model'),
            targetSegments: joinVehicleValues(targetSegments?.segments, 'segment'),
        };
    }

    return null;
}

function getConsumerAdTargetsWithoutCAMPBiasData({ consumerAdTargetsWithoutCAMPBias }) {
    if (consumerAdTargetsWithoutCAMPBias && Object.keys(consumerAdTargetsWithoutCAMPBias).length > 0) {
        const {
            targetModels,
            targetMakes,
            targetIntent,
            targetTradeInVehicle,
            targetSegments,
            targetInsightsAvailable,
        } = consumerAdTargetsWithoutCAMPBias;

        return {
            preferredMakes: joinVehicleValues(targetMakes?.makes, 'make'),
            preferredModel: targetModels?.models?.[0]?.model?.desc,
            secondPreferredModel: targetModels?.models?.[1]?.model?.desc,
            subCategory: joinVehicleValues(targetSegments?.segments, 'segment'),
            intent: targetIntent,
            tradeInMake: targetTradeInVehicle?.make?.desc,
            tradeInModel: targetTradeInVehicle?.model?.desc,
            targetInsightsAvailable,
        };
    }

    return null;
}

function getField(obj, nestedStr, defaultValue = 'inconclusive') {
    return (nestedStr.split('.').reduce((newObj, key) => (
        (typeof newObj === 'undefined' || newObj === null) ? newObj : newObj[key] || newObj[key.toLowerCase()]
    ), obj))?.toString().toLowerCase() || defaultValue;
}

export function getMappedPersonalizationData(personalizationData, params) {
    const peDataForAds = {
        personalization: personalizationData?.personalization,
        consumerMarketingSegments: personalizationData?.consumerMarketingSegments,
        consumerAdTargets: getConsumerAdTargetsData(personalizationData),
        consumerAdTargetsWithoutCAMPBias: getConsumerAdTargetsWithoutCAMPBiasData(personalizationData),
    };

    const personalizationParams = {
        // Consumer Insights - Buyers Signals
        mkofprefmdl: getField(peDataForAds, 'personalization.makeOfPreferredModel'),
        prefmdl: getField(peDataForAds, 'personalization.preferredModel'),
        prefmdlconf: getField(peDataForAds, 'personalization.preferredModelConfidence'),
        mkofsecprefmdl: getField(peDataForAds, 'personalization.makeOfSecondPreferredModel'),
        secprefmdl: getField(peDataForAds, 'personalization.secondPreferredModel'),
        mktlevel: getField(peDataForAds, 'personalization.marketLevel'),
        prefprice: getField(peDataForAds, 'personalization.preferredPrice'),
        prefvehtype: getField(peDataForAds, 'personalization.preferredVehicleType'),
        pricesensitivity: getField(peDataForAds, 'personalization.priceSensitivity'),
        prefmk: getField(peDataForAds, 'personalization.preferredMake'),
        prefbstyl: getField(peDataForAds, 'personalization.preferredBodystyle'),
        fixedops: getField(peDataForAds, 'personalization.fixedOps'),
        prefmileage: getField(peDataForAds, 'personalization.preferredMileage'),
        recprefmk: getField(peDataForAds, 'personalization.recentActivityPreferredMake'),
        recprefmdl: getField(peDataForAds, 'personalization.recentActivityPreferredModel'),
        mkofrecprefmdl: getField(peDataForAds, 'personalization.makeOfRecentActivityPreferredModel'),
        dractivity: getField(peDataForAds, 'personalization.drActivity'),

        // Consumer Ad Targets
        pe_mod: getField(peDataForAds, 'consumerAdTargets.targetmodels'),
        pe_alt_mod: getField(peDataForAds, 'consumerAdTargets.altTargetModel'),
        pe_mk: getField(peDataForAds, 'consumerAdTargets.targetMakes'),
        pe_subcat: getField(peDataForAds, 'consumerAdTargets.targetSegments'),
        pe_intent: getField(peDataForAds, 'consumerAdTargets.targetIntent'),
        pe_own_mk: getField(peDataForAds, 'consumerAdTargets.targetTradeInMake'),
        pe_own_mod: getField(peDataForAds, 'consumerAdTargets.targetTradeInModel'),
        pe_ins_exists: getField(peDataForAds, 'consumerAdTargets.targetInsightsAvailable', 'false'),

        // Consumer Ad Targets Without Camp
        pe_cons_mod: getField(peDataForAds, 'consumerAdTargetsWithoutCAMPBias.preferredModel'),
        pe_cons_alt_mod: getField(peDataForAds, 'consumerAdTargetsWithoutCAMPBias.secondPreferredModel'),
        pe_cons_mk: getField(peDataForAds, 'consumerAdTargetsWithoutCAMPBias.preferredMakes'),
        pe_cons_subcat: getField(peDataForAds, 'consumerAdTargetsWithoutCAMPBias.subCategory'),
        pe_cons_intent: getField(peDataForAds, 'consumerAdTargetsWithoutCAMPBias.intent'),
        pe_cons_own_mk: getField(peDataForAds, 'consumerAdTargetsWithoutCAMPBias.tradeInMake'),
        pe_cons_own_mod: getField(peDataForAds, 'consumerAdTargetsWithoutCAMPBias.tradeInModel'),

        // Consumer Marketing Segments
        pe_demographics: getField(peDataForAds, 'consumerMarketingSegments.demographics'),
        pe_lifeevents: getField(peDataForAds, 'consumerMarketingSegments.lifeEvents'),
        pe_clusters: getField(peDataForAds, 'consumerMarketingSegments.clusters'),
    };

    if (params) {
        Object.assign(params, { ...personalizationParams });
    }

    return personalizationParams;
}

export default function withAdParameters({ page, pageReferenceKey }) {
    return async (ctx) => {
        const {
            req,
            data = {},
            store,
        } = ctx;
        const state = store.getState();
        const params = {};
        const optionalParams = {};
        const { pageInst } = data;

        const brand = _get(data, 'brand', ATC_BRAND);
        const pageAttributes = _get(pageEventReference, [brand, pageReferenceKey], {});

        const serverSide = _get(ctx, 'req', false);
        const headers = _get(req, 'headers', {});
        const cookie = _get(headers, 'cookie', {});
        const cookies = new Cookies(cookie);
        const dataIsland = getDataIsland(data); // TODO: replace once bonnet-ctx-data-island is compete

        await getStandardParams(params, dataIsland, req, cookies, brand, pageAttributes, optionalParams, pageInst);
        getMappedPersonalizationData(state.personalizationEngine, params);

        if (brand === KBB_BRAND) {
            Object.assign(params, { adUnit: `3030/kbb/values/${page}` });
        } else {
            Object.assign(params, { adUnit: `18353239/${isPROD() ? 'atc/myatc' : 'testatc/myatc'}/${page}` });
            Object.assign(params, { lazyLoadingOffset: { showCaseOffset: '-100', leaderBoardOffset: '-20' } });
        }
        assignAdState(params, adsDuck.selectors.getDuckState(state), serverSide);

        if (optionalParams) {
            Object.assign(params, { optionalParams });
        }

        await withCtxMiddleware([
            addAdParameters({
                params,
            }),
        ], ctx);
        ctx.data.ads = deepmerge({ ...params }, ctx.data.ads);
    };
}
