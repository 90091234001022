import Route from './routes.js';

const pagesMeta = {
    atc: {
        [Route.SIGN_IN]: {
            canonicalUrl: 'https://www.autotrader.com/account/sign-in',
            description: 'Save cars and searches, get notified when prices drop, '
                + 'manage preferences and more! Accessible on any device, wherever you are.',
            descriptionName: 'Autotrader account sign-in',
            title: 'Autotrader Account Sign In | Autotrader',
            siteName: 'AUTOTRADER',
        },
        [Route.SIGN_OUT]: {
            canonicalUrl: 'https://www.autotrader.com/account/sign-in',
            descriptionName: 'Autotrader account sign-out',
            title: 'Autotrader Account Sign Out | Autotrader',
            siteName: 'AUTOTRADER',
        },
        [Route.PROFILE]: {
            canonicalUrl: 'https://www.autotrader.com/account/profile',
            descriptionName: 'Autotrader account profile',
            title: 'Autotrader Account Profile | Autotrader',
            siteName: 'AUTOTRADER',
        },
        [Route.CARS]: {
            canonicalUrl: 'https://www.autotrader.com/account/cars',
            description: 'Save cars and searches, get notified when prices drop, '
                + 'manage preferences and more! Accessible on any device, wherever you are.',
            descriptionName: 'Autotrader Saved Cars',
            title: 'Autotrader Saved Cars | Autotrader',
            siteName: 'AUTOTRADER',
        },
        [Route.SEARCHES]: {
            canonicalUrl: 'https://www.autotrader.com/account/searches',
            descriptionName: 'Autotrader Saved Searches',
            title: 'Autotrader Saved Searches | Autotrader',
            siteName: 'AUTOTRADER',
        },
        [Route.MESSAGES]: {
            canonicalUrl: 'https://www.autotrader.com/account/messages',
            descriptionName: 'Autotrader Messages',
            title: 'Autotrader Messages | Autotrader',
            siteName: 'AUTOTRADER',
        },
        [Route.PREFERENCES]: {
            canonicalUrl: 'https://www.autotrader.com/account/preferences',
            descriptionName: 'Autotrader Communication Preferences',
            title: 'Autotrader Communication Preferences | Autotrader',
            siteName: 'AUTOTRADER',
        },
        [Route.DEALER_REVIEWS]: {
            canonicalUrl: 'https://www.autotrader.com/account/dealer-reviews',
            descriptionName: 'Autotrader Dealer Reviews',
            title: 'Autotrader Account Profile | Autotrader',
            siteName: 'AUTOTRADER',
        },
    },
    kbb: {
        [Route.SIGN_IN]: {
            canonicalUrl: 'https://www.kbb.com/account/',
            description: 'Save cars and searches, get notified when prices drop, '
                + 'manage preferences and more! Accessible on any device, wherever you are.',
            descriptionName: 'Kelley Blue Book account sign-in',
            title: 'Kelley Blue Book Account Sign In | Kelley Blue Book',
            siteName: 'KBB',
        },
        [Route.SIGN_UP]: {
            canonicalUrl: 'https://www.kbb.com/account/',
            description: 'Save cars and searches, get notified when prices drop, '
                + 'manage preferences and more! Accessible on any device, wherever you are.',
            descriptionName: 'Kelley Blue Book account sign-up',
            title: 'Kelley Blue Book Account Sign Up | Kelley Blue Book',
            siteName: 'KBB',
        },
        [Route.SIGN_OUT]: {
            canonicalUrl: 'https://www.kbb.com/account/',
            descriptionName: 'Kelley Blue Book account sign-out',
            title: 'Kelley Blue Book Account Sign Out | Kelley Blue Book',
            siteName: 'KBB',
        },
        [Route.CARS]: {
            canonicalUrl: 'https://www.kbb.com/account/cars',
            description: 'Save cars and searches, get notified when prices drop, '
                + 'manage preferences and more! Accessible on any device, wherever you are.',
            descriptionName: 'Kelley Blue Book Saved Cars',
            title: 'Kelley Blue Book Saved Cars | Kelley Blue Book',
            siteName: 'KBB',
        },
        [Route.MYCARS]: {
            canonicalUrl: 'https://www.kbb.com/account/mycars',
            description: 'Save cars and searches, get notified when prices drop, '
              + 'manage preferences and more! Accessible on any device, wherever you are.',
            descriptionName: 'Kelley Blue Book Saved Cars',
            title: 'Kelley Blue Book Saved Cars | Kelley Blue Book',
            siteName: 'KBB',
        },
        [Route.PROFILE]: {
            canonicalUrl: 'https://www.kbb.com/account/',
            descriptionName: 'Kelley Blue Book account profile',
            title: 'Kelley Blue Book Account Profile | Kelley Blue Book',
            siteName: 'KBB',
        },
        [Route.DEALER_REVIEWS]: {
            canonicalUrl: 'https://www.kbb.com/account/',
            descriptionName: 'Kelley Blue Book Dealer Reviews',
            title: 'Kelley Blue Book Account Profile | Kelley Blue Book',
            siteName: 'KBB',
        },
    },
};

export default pagesMeta;
