import { PRICE_STANDARD_OPTIONS } from '../../../reference';

export default function setSelectedOptionsType(editCarDuck) {
    return (
        optionType
    ) => async (dispatch, getState) => {
        const colorId = editCarDuck.selectors.getColorId(getState());
        dispatch(editCarDuck.creators.setOptionsType(optionType));
        if (optionType === PRICE_STANDARD_OPTIONS) {
            const colorSelected = colorId ? `${colorId}|true` : '';
            dispatch(editCarDuck.creators.setSelectHistory(`${colorSelected}`));
        }
    };
}
