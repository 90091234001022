import fetchFromBFF from '../utilities/fetchFromBFF.js';
import { getJSON, rejectNonOkResponses, handle404OrRejectWithError } from '../utilities/fetchHandlers.js';

export default (brand) => fetchFromBFF('/account/auth-user/saved-search', {
    headers: {
        accesstoken: 'temptoken',
        idtoken: 'temptoken',
    },
    query: {
        brand,
    },
}).then(rejectNonOkResponses)
    .then(getJSON)
    .catch(handle404OrRejectWithError);
