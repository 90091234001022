/* eslint-disable no-console */
import axios from 'axios';
import getConfig from '@bonnet/core/config';
import { getHeaderAuthToken } from '../utilities/getHeaderAuthToken';

const getUID = () => Date.now().toString(36);

export default async (email) => {
    try {
        const { publicRuntimeConfig } = getConfig();
        const apiKey = publicRuntimeConfig?.dealerRatingsApiKey;
        const baseUrl = `${publicRuntimeConfig?.dealerRatingsCoreApi}`;
        // call core api to determine if user has written a review
        const { data: { data, errorMessage } } = await axios.get(baseUrl, {
            headers: {
                'x-api-key': apiKey,
            },
            params: {
                email,
                random: getUID(),
            },
        });

        if (errorMessage) {
            throw new Error(errorMessage);
        }
        return data?.totalCount || 0;
    } catch (err) {
        console.error('fetch reviews count error:', err);
    }

    return 0;
};

export const getDealerReviewsIFrame = async () => {
    const { publicRuntimeConfig } = getConfig();
    const {
        accesstoken: cognitoaccesstoken,
        idtoken: cognitoidtoken,
        'x-api-key': cognitoapiKey,
    } = await getHeaderAuthToken();
    const url = new URL(publicRuntimeConfig?.dealerRatingsResponseAppUrl);
    url.pathname = '/user';
    url.search = new URLSearchParams({
        cognitoaccesstoken,
        cognitoidtoken,
        cognitoapiKey,
    });

    return url;
};

export const getWriteReviewIFrame = async ({ brand }) => {
    const { publicRuntimeConfig } = getConfig();
    const url = new URL(publicRuntimeConfig?.writeReviewUrl);
    url.search = new URLSearchParams({
        hostWebsite: brand,
    });

    return url;
};
