/* eslint-disable no-trailing-spaces */
import { fetchJSON } from '@bonnet/core/fetch';
import { MAX_RESULT } from '../../services/service-utilities/researchableDataLoader';
import { RESEARCHABLE_MAPPERS } from '../../utilities/constants';

const formatBodyStyleParam = (param) => {
    let finalParam = '';
    finalParam = param.replaceAll('&', '');
    finalParam = finalParam.replaceAll(' ', '-');

    return finalParam;
}

export default async (args) => {
    const {
        vehicleClass = 'UsedCar',
        yearId,
        makeName,
        modelName,
    } = args || {};

    try {
        let bodyStyles;

        const formatMake = formatBodyStyleParam(makeName);
        const formatModel = formatBodyStyleParam(modelName);

        if (yearId && makeName && modelName) {
            bodyStyles = await fetchJSON(
                `/account/services/researchableReferenceDataService/bodystyles?mapper=${RESEARCHABLE_MAPPERS.BODYSTYLES}&vehicleClass=${vehicleClass}&yearId=${yearId}&makeName=${formatMake}&modelName=${formatModel}&limit=${MAX_RESULT}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                    },
                }
            );
        }

        return bodyStyles;

    } catch (err) {
        console.error('fetchBodyStyles error:', err);
    }
    return null;
};
