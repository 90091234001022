import { getWalletConfiguration } from '@atc/bonnet-reference';
import paymentsDuck from '../ducks/paymentsDuck';

const lnxMarketingCodes = ['CRMATCWLCM1', 'MYATCALERTSEML', 'BESTDEALS', 'INSIDER'];

export default function withWalletConfigs() {
    return async (ctx) => {
        const { query, cookies } = ctx;
        const { brand } = ctx.data;

        const marketingParam = brand === 'kbb' ? 'psid' : 'LNX';

        if (typeof window === 'undefined') {
            try {
                const myWalletConfigs = await getWalletConfiguration();

                const displayValue = query[marketingParam] && lnxMarketingCodes.includes(query[marketingParam]);
                const cookieValue = cookies?.get('WalletCookie');

                const myWalletUpdates = {
                    configs: myWalletConfigs,
                };

                if (displayValue && !cookieValue) {
                    myWalletUpdates.displayModal = true;
                    cookies?.set('WalletCookie', 'true', 'Session');
                }

                ctx.store.dispatch(paymentsDuck?.creators?.updateMyWallet(myWalletUpdates));

            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Error fetching wallet configuration: ', error);
            }
        }

    };
}
