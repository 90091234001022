import { objectDuckCreator } from '@atc/modular-redux';
import get from 'lodash.get';

export default objectDuckCreator({
    namespace: 'PAGE-DATA',
    store: 'dataIsland',
}).extend({
    reducer: (state, action, { types }) => {
        switch (action.type) {

            case 'LOAD_SERVERDATA': {

                const dataIsland = get(action, ['payload', 'dataIsland'], {});
                return {
                    ...state,
                    ...dataIsland,
                };
            }

            default:
                return state;
        }
    },
});
