module.exports = {
    default: {
        name: 'sortBy',
        options: [
            {
                value: 'titleAZ',
                label: 'Sort By: Title A-Z',
            },
            {
                value: 'titleZA',
                label: 'Sort By: Title Z-A',
            },
            {
                value: 'dateNewest',
                label: 'Sort By: Date - Newest',
            },
            {
                value: 'dateOldest',
                label: 'Sort By: Date - Oldest',
            },
        ],
        value: 'titleAZ',
    },
    searchResults: {
        name: 'showResults',
        label: 'Show search results',
        options: [
            {
                value: 'ALL',
                label: 'All',
            },
            {
                value: 'last_searched',
                label: 'Since last searched',
            },
            {
                value: 'within_day',
                label: 'Within last day',
            },
            {
                value: 'within_week',
                label: 'Within last week',
            },
        ],
        value: 'last_searched',

    },
    alertsFrequency: {
        name: 'Frequency',
        label: 'Alert Frequency',
        options: [
            {
                value: 'daily',
                label: 'Daily',
            },
            {
                value: 'weekly',
                label: 'Weekly',
            },
        ],

    },

};
