import { createAction, objectDuckCreator } from '@atc/modular-redux';

const adsDuck = objectDuckCreator({
    namespace: 'MY_ATC',
    store: 'domain.ads',
    initialState: {
        pageLevelTargeting: {},
        adUnit: '',
        prebidAdapters: [],
        showAds: false,
    },
}).extend({
    types: [
        'SET_PAGE_LEVEL_TARGETING',
        'DISABLE_ADS_REFRESH',
    ],
    creators: ({ types }) => ({
        setPageLevelTargeting: createAction(types.SET_PAGE_LEVEL_TARGETING),
        disableAdsRefresh: createAction(types.DISABLE_ADS_REFRESH),
    }),
    reducer: (state, action, { types }) => {
        switch (action.type) {

            case types.SET_PAGE_LEVEL_TARGETING: {
                return {
                    ...state,
                    pageLevelTargeting: {
                        ...state.pageLevelTargeting,
                        ...action.payload.targeting || {},
                    },
                    showAds: action.payload.showAds || false,
                };
            }
            case types.DISABLE_ADS_REFRESH: {
                return {
                    ...state,
                    showAds: false,
                };
            }

            default:
                return state;
        }
    },
});

export default adsDuck;
