import { fetchJSONWithCircuitBreaker } from '../utilities';

const fetchReview = async (vehicleId) => {
    try {
        const consumerReviewsResponse = await fetchJSONWithCircuitBreaker(
            '/account/services/researchableConsumerReviewsData',
            {
                query: {
                    vehicleId,
                },
            },
            'fetchConsumerReviews Error: ',
            {},
            'fetch ConsumerReviews'
        );
        return await consumerReviewsResponse;
    } catch (err) {
        console.error(`consumerReviewsData Error w/ vehicleId=${vehicleId}:`, err);
    }
    return null;
};

export default async (vehiclesId) => Promise.all(vehiclesId.map(async (vehicleId) => fetchReview(vehicleId)));
