import fetch from '@bonnet/core/fetch';
import { getHeaderAuthToken } from './getHeaderAuthToken';

const fetchFromBFF = async (url, options = {}) => {
    const authenticate = typeof options.authenticate === 'boolean' ? options.authenticate : true;
    let tokens = {
        idtoken: 'dummy value',
        accesstoken: 'dummy value',
    };

    if (authenticate === true) {
        tokens = await getHeaderAuthToken();
    }

    const finalOptions = {
        credentials: 'omit',
        method: 'GET',
        ...options,
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            ...options.headers,
            ...tokens,
        },
        retries: 1,
    };

    if (finalOptions.method !== 'GET') {
        finalOptions.body = finalOptions.body instanceof Object
            ? JSON.stringify(finalOptions.body)
            : (finalOptions.body || '{}');
    }

    return fetch(url, finalOptions);
};

export default fetchFromBFF;
