import { fetchJSON } from '@bonnet/core/fetch';

export default (value, fetchTotalPayment = false, id = 'paymentCalculation') => fetchJSON(`/rest/retailing/${fetchTotalPayment ? 'budget' : 'payments'}?` + value, {
    circuitBreaker: {
        id,
        timeout: 5000,
        resetTimeout: 300000,
        fallback: null,
    },
    headers: {
        'X-fwd-svc': 'atc',
    },
});
