import {
    getResearchableApiData,
    getPriceAdvisorApiData,
    userApiToShoppedDuckMapper,
    researchableApiDataToShoppedDuckMapper,
    priceAdvisorApiDataToShoppedDuckMapper,
    consumerReviewsDataToShopperDuckMapper,
    formatVehicleDetails,
} from '../helpers';
import { getUserApiShoppedVehicles, getUserApiConsumer, fetchConsumerReviews } from '../../../fetchers';
import remoteActions from '../../../actions/remoteActions.js';

export default function getShoppedVehicles(shoppedVehicleDuck) {
    return () => async (dispatch, getState) => {
        try {
            let kbbVehicleIds = [];
            const vehiclesShoppedData = [];
            const shoppedVehicles = await getUserApiShoppedVehicles();

            const { zip = '' } = await getUserApiConsumer();
            const shoppedVehiclesWithDetails = formatVehicleDetails(shoppedVehicles, zip);

            if (shoppedVehiclesWithDetails.length > 0) {
                kbbVehicleIds = shoppedVehiclesWithDetails?.map((vehicle) => vehicle.kbb_vehicle_id);
                const researchableApiData = await getResearchableApiData(kbbVehicleIds);
                const priceAdvisorApiData = await getPriceAdvisorApiData(shoppedVehiclesWithDetails);
                const consumerReviewData = await fetchConsumerReviews(kbbVehicleIds);

                if (priceAdvisorApiData?.length > 0) {
                    for (let i = 0; i < shoppedVehiclesWithDetails.length; i += 1) {
                        const vehicle = {
                            ...userApiToShoppedDuckMapper(shoppedVehiclesWithDetails[i]),
                            ...researchableApiDataToShoppedDuckMapper(researchableApiData[i]),
                            ...priceAdvisorApiDataToShoppedDuckMapper(priceAdvisorApiData[i]),
                            ...consumerReviewsDataToShopperDuckMapper(consumerReviewData[i]),
                        };

                        vehiclesShoppedData.unshift(vehicle);
                    }
                }

                if (vehiclesShoppedData.length === shoppedVehiclesWithDetails.length) {
                    dispatch(shoppedVehicleDuck.creators.hydrateShoppedVehicle(vehiclesShoppedData));
                }

            }
            dispatch(remoteActions.processAds());

            return vehiclesShoppedData;
        } catch (error) {
            throw new Error(error);
        }
    };
}
