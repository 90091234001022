import Route from './Route.js';

class MyATCRoute extends Route {
    // This should be private, but a babel issue seems to be preventing that from working.
    _screenUrl = '';

    generatePath(path, screenName) {
        if (screenName) {
            this._screenUrl = `/my-autotrader/${path}?defaultScreen=${screenName}`;
        }
        return `/my-autotrader/${path}`;
    }

    get screenUrl() {
        return this._screenUrl;
    }
}

export default MyATCRoute;
