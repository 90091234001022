import { fetchModels } from '../../../fetchers/index.js';

export default function getModelsCreator(vehiclePickerDuck) {
    return ({
        yearId,
        makeId,
        onSuccess = () => {},
        onError = () => {},
    }) => async (dispatch) => {
        try {
            const models = await fetchModels({ yearId, makeId });

            dispatch(vehiclePickerDuck.creators.setModels({ models }));
            if (models) {
                onSuccess();
            } else {
                onError();
            }
        } catch (error) {
            onError();
            throw new Error(error);
        }
    };
}