export default (state, action, { initialState, types }) => {

    const { payload } = action;

    switch (action.type) {

        case types.HYDRATE_SHOPPED_VEHICLE: {
            return {
                ...state,
                vehicles: [
                    ...payload,
                ],
            };
        }

        default:
            return state;
    }
};
