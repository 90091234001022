import fetchFromBFF from '../utilities/fetchFromBFF.js';
import { getJSON, rejectNonOkResponses, handle404OrRejectWithError } from '../utilities/fetchHandlers.js';

export default (vehicle) => fetchFromBFF(`/account/auth-user/hypothetical-vehicles/id/${vehicle.vehicleid}`, {
    method: 'PUT',
    headers: {
        accesstoken: 'temptoken',
        idtoken: 'temptoken',
    },
    body: JSON.stringify(vehicle)
}).then(rejectNonOkResponses)
    .then(getJSON)
    .catch(handle404OrRejectWithError);
