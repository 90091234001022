export default (state, action, { initialState, types }) => {

    const { payload } = action;

    switch (action.type) {

        case types.SET_BODYSTYLES: {
            return {
                ...state,
                bodyStyles: payload,
            };
        }
        case types.SELECT_CATEGORY: {
            return {
                ...state,
                selectedCategory: payload,
            };
        }
        case types.SELECT_STYLE: {
            return {
                ...state,
                selectedStyle: payload,
            };
        }
        case types.SELECT_VEHICLEID: {
            return {
                ...state,
                selectedVehicleId: payload,
            };
        }
        case types.RESET_STATE: {
            return initialState;
        }
        case types.SET_ERROR: {
            return {
                ...state,
                alertError: payload,
            };
        }

        default:
            return state;
    }
};
