/* eslint-disable no-unreachable */
import { Duck } from '@atc/modular-redux';

// Selectors
import selectors from './stylesCategoriesDuckSelectors';
import reducer from './stylesCategoriesDuckReducer';
import creators from './actionCreators';

const defaultState = {
    bodyStyles: [],
    selectedCategory: null,
    selectedStyle: '',
    selectedVehicleId: null,
    error: '',
};

function stylesCategoriesDuckCreator({
    namespace = '',
    store = 'stylesCategories',
    storePath,
    env = 'qa',
    ...rest
} = {}) {

    return new Duck({
        ...rest,
        namespace,
        store,
        storePath,
        initialState: {
            ...defaultState,
        },
        consts: {},
        types: [
            'SET_BODYSTYLES',
            'SELECT_CATEGORY',
            'SELECT_STYLE',
            'SELECT_VEHICLEID',
            'SET_ERROR',
            'RESET_STATE',
        ],
        creators: (duck) => creators(duck),
        reducer,
        selectors,
    });
}

export default stylesCategoriesDuckCreator();
