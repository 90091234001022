import {
    getPriceAdvisorApiData,
    priceAdvisorApiDataToShoppedDuckMapper,
    updateUserApiZipCode,
    updateUserApiMileage,
    updateUserApiCondition,
} from '../helpers';

/* eslint-disable no-unused-vars */
export function updateShoppedVehicleZipCodeActionCreator(shoppedVehicleDuck) {
    return ({
        consumerVehicleId,
        updatedZipcode,
        onUpdateSuccess = () => { },
        onUpdateFailure = () => { },
    }) => async (dispatch, getState) => {
        try {
            const vehicles = shoppedVehicleDuck.selectors.getUserApiFormattedShoppedVehicles(getState());
            const updatedVehicle = vehicles.find((item) => item.vehicleid === consumerVehicleId);
            updatedVehicle.vehicle_preferences.zipcode = updatedZipcode;
            const priceAdvisorApiPromise = getPriceAdvisorApiData([updatedVehicle]);
            const [_, priceAdvisorApiData] = await Promise.all([updateUserApiZipCode(getState, consumerVehicleId, updatedZipcode), priceAdvisorApiPromise]);
            const shoppedVehicles = shoppedVehicleDuck.selectors.getShoppedVehicles(getState());
            const updatedVehicles = shoppedVehicles.map((vehicle) => {
                if (vehicle.consumerVehicleId === consumerVehicleId) {
                    vehicle.zipcode = updatedZipcode;
                    vehicle.consumerVehicleData.zipcode = updatedZipcode;
                    return { ...vehicle, ...priceAdvisorApiDataToShoppedDuckMapper(priceAdvisorApiData.shift()) };
                }
                return vehicle;
            });

            dispatch(shoppedVehicleDuck.creators.hydrateShoppedVehicle(updatedVehicles));
            onUpdateSuccess();
        } catch (error) {
            onUpdateFailure();
            throw Error(error);
        }
    };
}

/* eslint-disable no-unused-vars */
export function updateShoppedVehicleMileageActionCreator(shoppedVehicleDuck) {
    return ({
        consumerVehicleId,
        mileage,
        onUpdateSuccess = () => { },
        onUpdateFailure = () => { },
    }) => async (dispatch, getState) => {
        try {
            const vehicles = shoppedVehicleDuck.selectors.getUserApiFormattedShoppedVehicles(getState());
            const updatedVehicle = vehicles.find((item) => item.vehicleid === consumerVehicleId);
            const mileageInt = Number.parseInt(mileage.replace(/,/g, ''), 10);
            updatedVehicle.vehicle_preferences.mileage = mileageInt;
            const priceAdvisorApiPromise = getPriceAdvisorApiData([updatedVehicle]);
            const [_, priceAdvisorApiData] = await Promise.all([updateUserApiMileage(getState, consumerVehicleId, mileageInt), priceAdvisorApiPromise]);
            const shoppedVehicles = shoppedVehicleDuck.selectors.getShoppedVehicles(getState());
            const updatedVehicles = shoppedVehicles.map((vehicle) => {
                if (vehicle.consumerVehicleId === consumerVehicleId) {
                    vehicle.consumerVehicleData.mileage = mileageInt;
                    vehicle.mileage = mileageInt;
                    return { ...vehicle, ...priceAdvisorApiDataToShoppedDuckMapper(priceAdvisorApiData.shift()) };
                }
                return vehicle;
            });
            dispatch(shoppedVehicleDuck.creators.hydrateShoppedVehicle(updatedVehicles));
            onUpdateSuccess();
        } catch (error) {
            onUpdateFailure();
            throw Error(error);
        }
    };
}

/* eslint-disable no-unused-vars */
export function updateShoppedVehicleConditionActionCreator(shoppedVehicleDuck) {
    return ({
        consumerVehicleId,
        condition,
        onUpdateSuccess = () => { },
        onUpdateFailure = () => { },
    }) => async (dispatch, getState) => {
        try {
            const vehicles = shoppedVehicleDuck.selectors.getUserApiFormattedShoppedVehicles(getState());
            const updatedVehicle = vehicles.find((item) => item.vehicleid === consumerVehicleId);
            updatedVehicle.vehicle_preferences.condition = condition;
            const priceAdvisorApiPromise = getPriceAdvisorApiData([updatedVehicle]);
            const [_, priceAdvisorApiData] = await Promise.all([updateUserApiCondition(getState, consumerVehicleId, condition), priceAdvisorApiPromise]);
            const shoppedVehicles = shoppedVehicleDuck.selectors.getShoppedVehicles(getState());
            const updatedVehicles = shoppedVehicles.map((vehicle) => {
                if (vehicle.consumerVehicleId === consumerVehicleId) {
                    vehicle.consumerVehicleData.condition = condition;
                    return { ...vehicle, ...priceAdvisorApiDataToShoppedDuckMapper(priceAdvisorApiData.shift()) };
                }
                return vehicle;
            });

            dispatch(shoppedVehicleDuck.creators.hydrateShoppedVehicle(updatedVehicles));
            onUpdateSuccess();
        } catch (error) {
            onUpdateFailure();
            throw Error(error);
        }
    };
}