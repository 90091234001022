/* eslint-disable no-console,consistent-return */
import {
    getVinDemandData
} from '../helpers.js';

export default function getDemandBarDataCreator(ownedVehicleDuck) {
    return () => async (dispatch, getState) => {
        const selectedVehicle = ownedVehicleDuck.selectors.getSelectedOwnedVehicle(getState());
        
        try {
            const selectedVehicleDemandBarData = ownedVehicleDuck.selectors.ownedVehicleDemandBarDataSelector(getState());

            if (selectedVehicleDemandBarData && !selectedVehicleDemandBarData.error) {
                return;
            }

            const data = await getVinDemandData(selectedVehicle?.vehicleOwnershipData?.vin);

            if (data) {
                dispatch(ownedVehicleDuck.creators.setSelectedOwnedVehicleDemandBarData({
                    vehicleOwnershipId: selectedVehicle.vehicleOwnershipId,
                    demandBarData: data
                }));
            }
        } catch (error) {
            dispatch(ownedVehicleDuck.creators.setSelectedOwnedVehicleDemandBarData({
                vehicleOwnershipId: selectedVehicle.vehicleOwnershipId,
                demandBarData: {
                    error: error?.message
                }
            }));
        }
    };
}