const MAX_NAVIGATION_DELAY_FOR_ANALYTICS_MS = 2000;
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const analyticsEventsInProgress = new Set();

export const analyticsStarted = () => {
    let done;
    const analyticsEventCompletionPromise = new Promise((resolve) => {
        done = resolve;
    });

    analyticsEventsInProgress.add(analyticsEventCompletionPromise);
    return () => {
        done();
        analyticsEventsInProgress.delete(analyticsEventCompletionPromise);
    };
};

// Add to the global namespace to allow Cypress tests to use the analyticsStarted function.
// Eventually Cypress tests should not need to access this directly because the analytics code should use this itself.
globalThis.analyticsStarted = analyticsStarted;

const ready = async () => analyticsEventsInProgress.size && Promise.race([
    delay(MAX_NAVIGATION_DELAY_FOR_ANALYTICS_MS),
    Promise.all(Array.from(analyticsEventsInProgress)),
]);

export const locationAssign = async (url) => {
    await ready();
    globalThis.location?.assign(url);
};
export const locationReplace = async (url) => {
    await ready();
    globalThis.location?.replace(url);
};
export const locationOpenTab = async (url) => {
    await ready();
    globalThis.open(url, '_blank');
};
