export default function addBrandCSS() {
    return async (ctx) => {
        // We are using webpack's dynamic import to generate individual CSS bundles for each brand.
        // Unfortunately the usage of dynamic import causes an ajax based client side inclusion this in turn causes a FOUC.

        // IMPORTANT: Wrapping the imports in unreachable code allows the client-manifest to be updated with each bundle but for the
        // client side loading to be canceled.
        // We need to apply the created CSS bundles in the app's custom _head file based off the brand detected to solve the FOUC.

        // Note:
        // It is possible that `webpackMode: 'weak' could be the correct (possibly more performant?) way to do this
        // but at this time I am unable to get this to work
        // I tried loading the bundle from the manifest with the weak setting on the dynamic bundle but it throws an error.
        // https://webpack.js.org/api/module-methods/#magic-comments

        if (false) { // eslint-disable-line no-constant-condition
            await import(
                /* webpackChunkName: "kbb" */
                /* webpackMode: "lazy" */
                '../scss/kbbui.scss'
            );

            await import(
                /* webpackChunkName: "atc" */
                /* webpackMode: "lazy" */
                '../scss/atc.scss'
            );
        }
    };
}
