import { fetchDataIsland } from '../fetchers';
import pageEventReference from '../analytics/tags/pageEvents';

export default function withDataIsland({
    pageReferenceKey = '',
    dataKey = 'dataIsland',
} = {}) {
    return async (ctx) => {

        const { brand } = ctx.data;
        const pageAttributes = pageEventReference[brand]?.[pageReferenceKey] || {};
        const dataIslandOptions = pageAttributes?.dataIsland || {};
        const dataIslandParams = {
            ...dataIslandOptions,
            brand,
        };
        ctx.data.pageAnalyticsData = { ...pageAttributes };
        ctx.data[dataKey] = await fetchDataIsland({ ...dataIslandParams });
    };
}
