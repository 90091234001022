import { useContext } from 'react';
import { ReactReduxContext } from 'react-redux';

function useAnalyticsDuck(analyticsDuck) {
    const { store } = useContext(ReactReduxContext);

    const sendClickEvent = (...args) => store.dispatch(analyticsDuck.creators.sendClickEvent(...args));

    return {
        sendClickEvent,
    };
}

export default useAnalyticsDuck;
