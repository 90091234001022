import fetchFromBFF from '../utilities/fetchFromBFF.js';
import { getJSON, rejectNonOkResponses } from '../utilities/fetchHandlers.js';

export default (vehicle) => fetchFromBFF('/account/auth-user/vehicles', {
    method: 'POST',
    headers: {
        accesstoken: 'temptoken',
        idtoken: 'temptoken',
    },
    body: JSON.stringify(vehicle)
}).then(rejectNonOkResponses)
.then(getJSON);
