import React from 'react';
import { useLocation } from 'react-router-dom';
import withBrand from '@atc/bonnet-ctx-brand';
import withCtxMiddleware from '@bonnet/with-ctx-middleware';
import { asyncRoute, addRoutes, Redirect } from '@bonnet/core/router';
import Route from './reference/routes.js';
import { isKBBBrand } from './utilities/brands.js';

const noop = () => {
};

const brandedLoader = (atLoader, kbbLoader) => async (ctx) => {
    ctx.data = ctx.data || {};
    ctx.query = ctx.query || {};

    const localhostOrigin = ctx.req?.query?.localhostOrigin;
    if (localhostOrigin?.endsWith?.('.autotrader.com') || localhostOrigin?.endsWith?.('.kbb.com')) {
        const url = new URL(localhostOrigin + ctx.req.url);
        url.searchParams.delete('localhostOrigin');
        ctx.res.redirect(url);
        return noop;
    }

    await withCtxMiddleware([
        withBrand(),
    ], ctx);

    return isKBBBrand(ctx.data.brand) ? kbbLoader() : atLoader();
};

const redirectTo = (route, preserveSearchParams) => () => {
    const { search } = useLocation();

    const destination = route.path + (preserveSearchParams ? search : '');
    return (
        <Redirect
            to={destination}
        />
    );
};

const RedirectScreens = () => {
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    // These may not all be needed because some screens are not reached by browser navigation.
    // See comment in routes.js
    const destRoute = {
        signup: Route.SIGN_UP,
        signout: Route.SIGN_OUT,
        welcome: Route.NEW_USER_WELCOME,
    }[searchParams.get('defaultScreen')?.toLowerCase()] || Route.SIGN_IN;

    searchParams.delete('defaultScreen');

    const destination = destRoute.path + (searchParams.toString() ? '?' + searchParams : '');
    return (
        <Redirect
            to={destination}
        />
    );
};

const brandedRedirect = (atRoute, kbbRoute) => brandedLoader(
    () => redirectTo(atRoute),
    () => redirectTo(kbbRoute),
);

const routes = addRoutes([
    // Pages -------------------------------------------------------------------------------------------------
    {
        path: Route.CARS.path,
        exact: true,
        component: asyncRoute(brandedLoader(
            () => import(/* webpackChunkName: "at-cars-page" */ './pages/MyCarsPage.js'),
            () => import(/* webpackChunkName: "kbb-cars-page" */ './pages/argo/CarsPage.js')
        )),
    },
    {
        path: Route.MYCARS.path,
        exact: true,
        component: asyncRoute(brandedLoader(
            () => import(/* webpackChunkName: "at-my-owned-cars-page" */ './pages/carsIOwn/MyCarsIOwnPage.js'),
            () => import(/* webpackChunkName: "kbb-my-owned-cars-page" */ './pages/argo/carsIOwn/CarsIOwnPage.js'),
        )),
    },
    {
        path: Route.MYCARS_ADD_CAR.path,
        exact: true,
        component: asyncRoute(brandedLoader(
            () => import(/* webpackChunkName: "at-my-owned-cars-page" */ './pages/carsIOwn/AddOwnedCarPage.js'),
            () => import(/* webpackChunkName: "kbb-my-owned-cars-page" */ './pages/argo/carsIOwn/AddOwnedCarPage.js'),
        )),
    },
    {
        path: Route.MYCARS_EDIT_CAR.path,
        exact: true,
        component: asyncRoute(brandedLoader(
            () => import(/* webpackChunkName: "at-my-owned-cars-page" */ './pages/carsIOwn/EditOwnedCarPage.js'),
            () => import(/* webpackChunkName: "kbb-my-owned-cars-page" */ './pages/argo/carsIOwn/EditOwnedCarPage.js'),
        )),
    },
    {
        path: Route.MYCARS_REMOVE_CAR.path,
        exact: true,
        component: asyncRoute(brandedLoader(
            () => import(/* webpackChunkName: "at-my-owned-cars-page" */ './pages/carsIOwn/RemoveOwnedCarPage.js'),
            () => import(/* webpackChunkName: "kbb-my-owned-cars-page" */ './pages/argo/carsIOwn/RemoveOwnedCarPage.js'),
        )),
    },
    {
        path: Route.SHOPPEDCARS.path,
        exact: true,
        component: asyncRoute(brandedLoader({}, () => import(/* webpackChunkName: "kbb-my-owned-cars-page" */ './pages/argo/SavedCarsPage.js'))),
    },
    {
        path: Route.SEARCHES.path,
        exact: true,
        component: asyncRoute(brandedLoader(
            () => import(/* webpackChunkName: "at-searches-page" */ './pages/MySearchesPage.js'),
            () => import(/* webpackChunkName: "kbb-searches-page" */ './pages/argo/SavedSearchesPage.js'),
        )),
    },
    {
        path: Route.DEALER_REVIEWS.path,
        exact: true,
        component: asyncRoute(brandedLoader(
            () => import(/* webpackChunkName: "at-dealer-reviews-page" */ './pages/DealerReviewsPage.js'),
            () => import(/* webpackChunkName: "kbb-dealer-reviews-page" */ './pages/argo/DealerReviewsPage.js')
        )),
    },
    {
        path: Route.MESSAGES.path,
        exact: false,
        component: asyncRoute(() => import(/* webpackChunkName: "at-messages-page" */ './pages/MyMessagesPage.js')),

    },
    {
        path: Route.PROFILE.path,
        exact: true,
        component: asyncRoute(brandedLoader(
            () => import(/* webpackChunkName: "at-profile-page" */ './pages/MyProfilePage.js'),
            () => import(/* webpackChunkName: "kbb-profile-page" */ './pages/argo/ProfilePage.js')
        )),
    },
    {
        path: Route.UPDATE_PROFILE.path,
        exact: true,
        component: asyncRoute(brandedLoader(
            () => import(/* webpackChunkName: "at-update-profile-page" */'./pages/UpdateProfilePage'),
            () => import(/* webpackChunkName: "kbb-update-profile-page" */ './pages/argo/UpdateProfilePage.js')
        )),
    },
    {
        path: Route.PREFERENCES.path,
        exact: true,
        component: asyncRoute(brandedLoader(
            () => import(/* webpackChunkName: "at-preferences-page" */ './pages/MyPreferencesPage.js'),
            () => import(/* webpackChunkName: "kbb-preferences-page" */ './pages/argo/PreferencesPage.js')
        )),
    },
    {
        path: Route.UNSUBSCRIBE_RESULT.path,
        exact: true,
        component: asyncRoute(() => import(/* webpackChunkName: "at-unsubscribe-result-page" */ './pages/UnsubscribeResultPage.js')),
    },

    // TODO: delete this route once Route.MYATC_AUTH is deleted
    // This is needed before the main Screen routing (the next route) because Route.screenPaths currently includes
    // '/my-autotrader/auth'
    {
        path: ['/my-autotrader/auth'],
        exact: true,
        component: RedirectScreens,
    },

    // Screens -----------------------------------------------------------------------------------------------
    {
        path: Route.screenPaths,
        exact: true,
        component: asyncRoute(() => import(/* webpackChunkName: "auth-page" */ './pages/AuthPage.js')),
    },

    // Redirects ---------------------------------------------------------------------------------------------
    {
        path: [
            '/',
            '/account',
            '/my-autotrader',
        ],
        exact: true,
        component: asyncRoute(brandedRedirect(Route.CARS, Route.PROFILE)),
    },
    {
        path: ['/my-autotrader/auth', '/account/auth'],
        exact: true,
        component: RedirectScreens,
    },
    {
        path: Route.MY_CARS.path,
        exact: true,
        component: redirectTo(Route.CARS),
    },
    {
        path: Route.MYCARS_ADD_CAR.path,
        exact: true,
        component: redirectTo(Route.CARS),
    },
    {
        path: Route.MYCARS_EDIT_CAR.path,
        exact: true,
        component: redirectTo(Route.CARS),
    },
    {
        path: Route.MYCARS_REMOVE_CAR.path,
        exact: true,
        component: redirectTo(Route.CARS),
    },
    {
        path: Route.MY_SEARCHES.path,
        exact: true,
        component: redirectTo(Route.SEARCHES),
    },
    {
        path: Route.MY_MESSAGES.path,
        exact: false,
        component: redirectTo(Route.MESSAGES),
    },
    {
        path: Route.MY_PROFILE.path,
        exact: true,
        component: redirectTo(Route.PROFILE),
    },
    {
        path: Route.MY_UPDATE_PROFILE.path,
        exact: true,
        component: redirectTo(Route.UPDATE_PROFILE),
    },
    {
        path: Route.MY_PREFERENCES.path,
        exact: true,
        component: redirectTo(Route.PREFERENCES, true),
    },
    {
        path: Route.MY_UNSUBSCRIBE_RESULT.path,
        exact: true,
        component: redirectTo(Route.UNSUBSCRIBE_RESULT, true),
    },
]);
export default routes;
