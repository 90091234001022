import savedSearchesClick from '../SavedSearchesHandlers';

const startNewSearchCtaClick = savedSearchesClick('cta::start-a-new-search');

const cancelAllSearchCtaClick = savedSearchesClick('text-link::cancel-all-search-alerts');

const deleteSearchCtaClick = savedSearchesClick('delete-saved-search');

const viewSearchResultsCtaClick = savedSearchesClick('cta::view-search-results');

const viewNotesCtaClick = savedSearchesClick('text-link::view-notes');

const selectEmailCheckboxCta = savedSearchesClick('checkbox::alert::email-search');

const selectTextCheckboxCta = savedSearchesClick('checkbox::alert::text-search');

const disselectCheckboxCta = savedSearchesClick('checkbox::unsubscribe::saved-search');

const showSearchResultsDropdownCta = savedSearchesClick('dropdown::show-search-results');

const updateNotesCtaClick = savedSearchesClick('cta::update-notes');

const cancelSearchAlertCtaClick = savedSearchesClick('cta::cancel-alerts');

const cancelSearchTextAlertCtaClick = savedSearchesClick('checkbox::alert::text-search');

export default {
    startNewSearchCtaClick,
    cancelAllSearchCtaClick,
    deleteSearchCtaClick,
    viewSearchResultsCtaClick,
    viewNotesCtaClick,
    selectEmailCheckboxCta,
    selectTextCheckboxCta,
    disselectCheckboxCta,
    showSearchResultsDropdownCta,
    updateNotesCtaClick,
    cancelSearchAlertCtaClick,
    cancelSearchTextAlertCtaClick,
};
