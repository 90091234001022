import { isKBBBrand } from '../../utilities/brands';

const googleResultMap = {
    signin: {
        eventString: 'login',
        adobe: {
            facebook: {
                co_txt_url: 'Sign in with Facebook',
                linkName: 'signinfacebook_mykbbsignin',
            },
            apple: {
                co_txt_url: 'Sign in with Apple',
                linkName: 'signinapple_mykbbsignin',
            },
            google: {
                co_txt_url: 'Sign in with Google',
                linkName: 'signingoogle_mykbbsingin',
            },
        },
    },
    signup: {
        eventString: 'register',
        adobe: {
            facebook: {
                co_txt_url: 'Sign up with Facebook',
                linkName: 'signupfacebook_mykbbsignup',
            },
            apple: {
                co_txt_url: 'Sign up with Apple',
                linkName: 'signupapple_mykbbsignup',
            },
            google: {
                co_txt_url: 'Sign up with Google',
                linkName: 'signupgoogle_mykbbsignup',
            },
        },
    },
};
const screenDefault = 'signin';
const onFederatedClick = (metadata, brand, sendClick, screen) => {

    const {
        provider,
        ...rest
    } = metadata.data;
    const federatedProvider = provider.toLowerCase();
    const {
        eventString = '',
        adobe = {},
    } = (googleResultMap[screen.toLowerCase()] || googleResultMap[screenDefault]);

    switch (federatedProvider) {
        case 'facebook': {
            sendClick('facebookFederatedClick', metadata.event, {
                ...(isKBBBrand(brand) && adobe?.facebook),
                ...rest,
                eventResult: `cta::${eventString}::facebook`,
            });
            break;
        }
        case 'signinwithapple': {
            sendClick('appleFederatedClick', metadata.event, {
                ...(isKBBBrand(brand) && adobe?.apple),
                ...rest,
                eventResult: `cta::${eventString}::apple`,
            });
            break;
        }
        case 'google': {
            sendClick('googleFederatedClick', metadata.event, {
                ...(isKBBBrand(brand) && adobe?.google),
                ...rest,
                eventResult: `cta::${eventString}::google`,
            });
            break;
        }
        default: /* noop */
    }

};

const onSignIn = (metadata, brand, sendClick) => {
    sendClick('signInClick', metadata.event, {
        ...metadata.data,
        ...(isKBBBrand(brand) && {
            co_txt_url: 'Sign in',
            linkName: 'signin_mykbbsignin',
        }),
    });
};

// eslint-disable-next-line no-shadow
export const onCarAlertsClick = (data, sendClick) => {
    if (data.alertByEmail || data.alertByText) {
        const eventResult = 'cta::alert::'
            + (data.alertByEmail ? 'email-car-vin' : '')
            + (data.alertByEmail && data.alertByText ? '|' : '')
            + (data.alertByText ? 'text-car-vin' : '');
        sendClick('carAlertsClick', undefined, { eventResult, vehicle: data.listing });
    }
};

const onSignUp = (metadata, brand, sendClick) => {
    sendClick('signUpClick', metadata.event, {
        ...metadata.data,
        ...(isKBBBrand(brand) && {
            co_txt_url: 'Create Account',
            linkName: 'creatacct_mykbbsingup',
        }),
    });
};

const onsendresetlinkclick = (metadata, brand, sendClick) => {
    sendClick('resendResetLinkClick', metadata.event, {
        ...metadata.data,
        ...(isKBBBrand(brand) && {
            co_txt_url: 'Forgot Your Password',
            linkName: 'forgotpassword_mykbbforgotpaswrd',
        }),
    });
};

// eslint-disable-next-line consistent-return
const clickAnalytics = (sendClick, sendImpressions, brand, screen) => (metadata) => {
    switch (metadata.type.toLowerCase()) {
        case 'signupctaclick':
            sendClick('signUpCtaClick', metadata.event, { ...metadata.data });
            break;
        case 'signinctaclick':
            sendClick('signInCtaClick', metadata.event, { ...metadata.data });
            break;
        case 'onsignin':
            onSignIn(metadata, brand, sendClick);
            break;
        case 'onsignup':
            onSignUp(metadata, brand, sendClick);
            break;
        case 'onresetpasswordclick':
            sendClick('resetPasswordClick', metadata.event, { ...metadata.data });
            break;
        case 'onresendverificationlinkclick':
            sendClick('resendVerificationLinkClick', metadata.event, { ...metadata.data });
            break;
        case 'onresendverificationbuttonclick':
            sendClick('resendVerificationButtonClick', metadata.event, { ...metadata.data });
            break;
        case 'onfederatedclick': // this case is for ATC federated login, not KBB
            onFederatedClick(metadata, brand, sendClick, screen);
            break;
        case 'onfederatedsigninclick':
            onFederatedClick(metadata, brand, sendClick, screen);
            break;
        case 'onfederatedsignupclick':
            onFederatedClick(metadata, brand, sendClick, screen);
            break;
        case 'onsendresetlinkclick':
            onsendresetlinkclick(metadata, brand, sendClick);
            break;
        case 'ongetstartedclick':
            sendClick('getStartedClick', metadata.event, { eventResult: 'register::success', co_txt_url: 'Get Started', ...metadata.data });
            break;
        // Temporary work-around until analytics for get started click is implemented on kbb
        case 'onkbbgetstartedclick':
            sendClick('getStartedClick', metadata.event, { eventResult: 'register::success', co_txt_url: 'Get Started', ...metadata.data });
            break;
        case 'onsigninsuccess':
            sendImpressions({
                name: 'logInSuccessImpression',
            });
            break;
        case 'onsigninfailure':
            sendImpressions({
                name: 'logInFailImpression',
            });
            break;
        case 'onsignupfailure':
            sendImpressions({
                name: 'registerFailImpression',
            });
            break;
        default:
            return () => { };
    }
    return false;
};

export default clickAnalytics;
