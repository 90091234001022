/* eslint-disable no-console */
import { fallbackInterestRates } from 'axl-config';

import { getInterestRates } from '@atc/bonnet-reference';
import interestRateDuck from '../ducks/interestRateDuck';

export default function withInterestRates() {
    return async (ctx) => {
        // Only run on initial page load
        if (typeof window === 'undefined') {
            const rates = await getInterestRates().catch((error) => {
                console.error('Error fetching interest rates: ', error);
            });

            if (rates) {
                rates.isFallback = false;
                await ctx.store.dispatch(interestRateDuck.creators.setKey(rates));
            } else {
                // If no rates or rates object is empty set fallbacks
                await ctx.store.dispatch(interestRateDuck.creators.setKey(fallbackInterestRates));
            }
        }
    };
}
