/* eslint-disable no-console,consistent-return */
import { Cookies } from 'atc-js';
import { saveUserApiShoppedVehicles, getUserApiConsumer } from '../../../fetchers/index';
import { formatStashDataForSave, formatPEDataForSave, parseIntentTypeFromStashUrl } from '../helpers';
import { STASH_DATA_TYPE } from '../constants';
export default function saveShoppedVehicleActionCreator(shoppedVehicleDuck) {
    return ({
        vehicleData,
        dataType,
        onSaveSuccess = () => {},
        onSaveFailure = () => {},
    }) => async (dispatch, getState) => {
        const { zip = '' } = await getUserApiConsumer();
        const cookies = new Cookies();
        const cookieZip = cookies.get('ZipCode');
        const finalZip = zip || cookieZip;

        const intent = dataType === STASH_DATA_TYPE ? parseIntentTypeFromStashUrl(vehicleData?.url) : '';
        const formattedVehicle = dataType === STASH_DATA_TYPE ? formatStashDataForSave(vehicleData, finalZip, intent) : formatPEDataForSave(vehicleData, finalZip);
        
        await saveUserApiShoppedVehicles(formattedVehicle).then(() => {
            const vehicles = shoppedVehicleDuck.selectors.getShoppedVehicles(getState());
            dispatch(shoppedVehicleDuck.creators.hydrateShoppedVehicle(vehicles));
            onSaveSuccess();
            return vehicles;
        }).catch((error) => {
            console.error('shopped vehicle stash data save error:', error);
            onSaveFailure();
            throw Error(error);
        });
    };
}