import get from 'lodash.get';
import Route from '../reference/routes.js';

export default function buildElotQuery(route, bookmark, zip, searchRadius) {
    const extractMyCarsQuery = () => {
        const year = get(bookmark, 'searchCriteria.carModelYear', '1981');
        const {
            carMakeCode,
            carModelCode,
            carPrice,
        } = get(bookmark, 'searchCriteria', {});

        return {
            zip,
            searchRadius,
            ...(carMakeCode ? { makeCode: carMakeCode } : {}),
            ...(carModelCode ? { modelCode: carModelCode } : {}),
            startYear: year || '1981',
            ...(carPrice ? { maxPrice: carPrice } : {}),
        };
    };

    const extractMySearchQuery = () => {
        const searchURL = get(bookmark, 'searchResultsURL', {});
        const parsedUrlQuery = JSON.parse(searchURL);
        const {
            makeCode,
            modelCode,
            startYear,
            endYear,
            vehicleStyleCode,
            searchRadius: bookmarkSearchRadius,
        } = parsedUrlQuery;
        return {
            zip,
            searchRadius: Number(bookmarkSearchRadius?.[0] || searchRadius),
            makeCode,
            modelCode,
            startYear: Number(startYear) || 1981,
            ...(endYear && { endYear: Number(endYear) }),
            bodyStyleCode: vehicleStyleCode,
        };
    };
    return (route === Route.CARS || route === Route.MYCARS_REMOVE_CAR) ? extractMyCarsQuery() : extractMySearchQuery();
}
