/* eslint-disable no-unreachable */
import { Duck } from '@atc/modular-redux';

// Selectors
import selectors from './vinLpDetailsDuckSelectors';
import reducer from './vinLpDetailsDuckReducer';
import creators from './actionCreators';

function vinLpDetailsDuckCreator({
    namespace = '',
    store = 'vinLpDetails',
    storePath,
    env = 'qa',
    ...rest
} = {}) {

    return new Duck({
        ...rest,
        namespace,
        store,
        storePath,
        initialState: {},
        consts: {},
        types: [
            'SET_VINLP_DETAILS',
            'SET_ERROR',
            'RESET_STATE',
        ],
        creators: (duck) => creators(duck),
        reducer,
        selectors,
    });
}

export default vinLpDetailsDuckCreator();