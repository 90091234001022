import { fetchJSONWithCircuitBreaker } from '../utilities';
import { MAX_SAVED_CARS } from '../reference/MiscConstants.js';

export default function fetchSavedInventory(listingIds, fallback = {}) {
    let listingIdsQuery = '';
    listingIds.forEach((id) => {
        if (id) {
            listingIdsQuery += `&listingId=${id}`;
        }
    });

    return fetchJSONWithCircuitBreaker(
        `/rest/lsc/listing?collapseOwners=true&collapseFilters=true&marketExtension=includevroom&numRecords=${MAX_SAVED_CARS}${listingIdsQuery}`,
        {
            method: 'GET',
            credentials: 'same-origin',
        },
        'fetchSavedInventory Error: ',
        fallback,
        'fetchSavedInventory'
    );
}
