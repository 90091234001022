import { updateUserApiSavedSearches, userApiDeleteNewInventory, userApiSaveNewInventory } from '../../../fetchers';

export function updateSavedSearchesNotesActionCreator(savedSearchesDuck) {
    return ({
        updatedNote,
        bookmarkId,
        onUpdateFailure = () => {},
    }) => async (dispatch, getState) => {
        try {
            const savedSearches = savedSearchesDuck.selectors.getSavedSearches(getState());
            const updatedSavedSearch = savedSearches.find((bookmark) => bookmark.bookmarkId === bookmarkId);
            updatedSavedSearch.notes = updatedNote;

            await updateUserApiSavedSearches({ updatedBookmark: updatedSavedSearch });

            const updatedBookmarks = savedSearches.map((bookmark) => {
                if (bookmark.bookmarkId === bookmarkId) {
                    bookmark.notes = updatedNote;
                    return bookmark;
                }
                return bookmark;
            });

            dispatch(savedSearchesDuck.creators.hydrateSavedSearches(updatedBookmarks));

        } catch (error) {
            console.error(error);
            onUpdateFailure();
        }
    };
}

export function updateSavedSearchesAlertsActionCreator(savedSearchesDuck) {
    return ({
        enabledAlert,
        bookmarkId,
        onUpdateSuccess = () => {},
        onUpdateFailure = () => {},
    }) => async (dispatch, getState) => {
        try {
            const savedSearches = savedSearchesDuck.selectors.getSavedSearches(getState());
            const updatedSavedSearch = savedSearches.find((bookmark) => bookmark.bookmarkId === bookmarkId);
            const { newInventorySubscriptions } = updatedSavedSearch;

            let response = [];

            if (enabledAlert) {
                response = await userApiSaveNewInventory(bookmarkId);
            } else {
                const emailSubscription = newInventorySubscriptions.find((subscription) => subscription.notificationType === 'EMAIL');
                await userApiDeleteNewInventory(emailSubscription);
            }

            const updatedBookmarks = savedSearches.map((bookmark) => {
                if (bookmark.bookmarkId === bookmarkId) {
                    if (response?.subscriptionId) {
                        const updatedInventory = {
                            ...response,
                            subscriptionVersion: '1',
                            notificationType: 'EMAIL',
                        };

                        bookmark.newInventorySubscriptions.push(updatedInventory);
                        return bookmark;
                    }
                    bookmark.newInventorySubscriptions = [];
                    return bookmark;

                }
                return bookmark;
            });

            dispatch(savedSearchesDuck.creators.hydrateSavedSearches(updatedBookmarks));
            onUpdateSuccess();

        } catch (error) {
            console.error(error);
            onUpdateFailure();
        }
    };
}
