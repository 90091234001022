import { MAX_RESULT } from "../services/service-utilities/researchableDataLoader";
import { filterByEligibleVehicleClass, sort } from "../utilities";
import { fetchJSON } from '@bonnet/core/fetch';

export default async (args) => {
    const {
        vehicleClass = 'UsedCar',
        yearId,
        makeId,
    } = args || {};

    try {
        const data = await fetchJSON(
            `/account/services/researchableReferenceDataService/models?yearid=${yearId}&makeid=${makeId}&limit=${MAX_RESULT}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                },
            }
        )
        
        if (data) {
            return sort.ascend(filterByEligibleVehicleClass(data, vehicleClass), 'modelName')
            .map(({ modelId, modelName }) => ({
              value: modelId,
              text: modelName
            }));
        }
    } catch (err) {
        console.error('models ', err);
    }
    return null;
};
