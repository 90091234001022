/* eslint-disable no-unreachable */
import { Duck } from '@atc/modular-redux';

import selectors from './savedSearchesDuckSelectors';
import reducer from './savedSearchesDuckReducer';
import creators from './actionCreators/index';

const defaultState = {
    searches: [],
};

// export personalization only for testing
// eslint-disable-next-line import/no-mutable-exports

function savedSearchesDuckCreator({
    namespace = '',
    store = 'savedSearches',
    storePath,
    ...rest
} = {}) {

    return new Duck({
        ...rest,
        namespace,
        store,
        storePath,
        initialState: {
            ...defaultState,
        },
        consts: {},
        types: [
            'HYDRATE_SAVED_SEARCHES',
        ],

        creators: (duck) => creators(duck),
        reducer,
        selectors,
    });
}

const savedSearchesVehicleDuck = savedSearchesDuckCreator();

export default savedSearchesVehicleDuck;
