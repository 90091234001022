export default (isoDate) => {
    if (isoDate && typeof isoDate === 'string') {
        const dateFormat = new Date(isoDate);
        const finalDateDformat = dateFormat.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            timeZone: 'UTC',
        });
        return finalDateDformat;
    }
    return '';
};
