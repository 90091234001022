export const SEARCH_FORM_PATH = '/cars-for-sale/advanced-search';
export const SELL_CAR_PATH = '/sell-my-car';
export const MANAGE_MY_AD_PATH = '/sell-my-car/marketplace/manage';
export const MAX_SAVED_CARS = '100';

export const DEFAULT_ALERT_STATE = {
    show: false,
    type: 'error',
};

export const FEDERATED_SIGN_UP_ERROR = {
    msg: 'We couldn’t sign you in. You might want to check your social account or browser settings. Otherwise, sign in or sign up with email to continue.',
    type: 'danger',
};

export const WEB_OWNED_VEHICLE_ERROR = {
    text: 'We’re unable to display your vehicle details due to a technical issue. Please check back later.',
    actionType: 'static',
    enableCapitalize: false,
};

export const EDIT_OPTIONS_SAVE_ERROR = {
    text: 'We were unable to save the changes you made to your account due to a technical issue. Please try again later.',
    actionType: 'static',
    enableCapitalize: false,
};

export const REMOVE_OWNED_VEHICLE_SUCCESS = {
    type: 'confirmation',
    text: 'Your car has been successfully removed.',
    actionType: 'dismissible',
    enableCapitalize: false,
};

export const EDIT_ZIP_CODE_ERROR = {
    heading: 'Sorry',
    text: 'We’re unable to save your ZIP code due to a technical issue. Please try again later.',
    type: 'error',
    actionType: 'dismissible',
};

export const EDIT_MILEAGE_ERROR = {
    heading: 'Sorry',
    text: 'We’re unable to save your mileage due to a technical issue. Please try again later.',
    type: 'error',
    actionType: 'dismissible',
};

export const EDIT_CONDITION_ERROR = {
    heading: 'Sorry',
    text: 'We’re unable to save your vehicle condition due to a technical issue. Please try again later.',
    type: 'error',
    actionType: 'dismissible',
};

export const MAJOR_REPAIR_ERROR = {
    noData: {
        heading: 'Risk of Major Repair Data Unavailable',
        text: 'Not enough data is available on this vehicle to calculate its repair risk.',
        type: 'information',
        actionType: 'static',
    },
    error: {
        heading: 'Sorry',
        text: 'We’re unable to display your Risk of Major Repair Data due to a technical issue. Please check back later.',
        type: 'caution',
        actionType: 'static',
    },
};

export const CARS_I_OWN_HEADER_COPY = {
    autotrader: {
        showKbbLogo: true,
        subHeading: 'The easy way to track your car\'s value.',
    },
    kbb: {
        showKbbLogo: false,
        subHeading: 'Kelley makes it easy to track your values.',
    },
};

export const COMMUNICATION_PREFERENCES_UNSUBSCRIBE_ALERTS = {
    success: {
        type: 'confirmation',
        heading: 'Preferences Saved',
        text: 'Please note, it may take up to 24 hours to complete your request.',
        actionType: 'dismissible',
        enableCapitalize: false,
    },
    error: {
        type: 'error',
        heading: '',
        text: 'We’re sorry, we ran into a technical issue regarding your request. Please try again later.',
        actionType: 'dismissible',
        enableCapitalize: false,
    },
};

export const SAVED_SEARCH_GENERAL_ERROR = {
    text: 'We’re sorry, we ran into a technical issue regarding your request. Please try again later.',
    type: 'error',
    actionType: 'dismissible',
};

export const EDIT_NOTES_ERROR = {
    text: 'We’re sorry, there has been an error while updating your search. Please try again later.',
    type: 'error',
    actionType: 'dismissible',
};

export const REMOVE_SAVED_SEARCH_SUCCESS = {
    text: 'Your search has been successfully removed.',
    type: 'confirmation',
    actionType: 'dismissible',
};

export const EMAIL_ALERTS_ENABLED_INFO = {
    text: 'Email alerts enabled.',
    type: 'information',
    actionType: 'dismissible',
};

export const EMAIL_ALERTS_DISABLED_INFO = {
    text: 'Email alerts disabled.',
    type: 'information',
    actionType: 'dismissible',
};
