import { fetchJSON } from '@bonnet/core/fetch';

export default async (args) => {
    const {
        vehicleId,
    } = args || {};

    try {
        const ymmt = await fetchJSON(
            `/account/services/optionsPageService?vehicleId=${vehicleId}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                },
            }
        );

        return ymmt;
    } catch (err) {
        console.error('options ', err);
    }
    return null;
};
