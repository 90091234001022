module.exports = [
    'searchZipRadius',
    'yearRange',
    'price',
    'make',
    'model',
    'trim',
    'style',
    'listingTypes',
    'dealTypes',
    'mileage',
    'series',
    'sellerType',
    'transmission',
    'engine',
    'drive',
    'doors',
    'fuel',
    'exteriorColor',
    'interiorColor',
    'features',
    'mpgRanges',
    'vehicleHistoryTypes',
];
