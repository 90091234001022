import { fetchMakes } from '../../../fetchers/index.js';

export default function getMakesCreator(vehiclePickerDuck) {
    return ({
        yearId,
        onSuccess = () => {},
        onError = () => {},
    }) => async (dispatch) => {
        try {
            const data = await fetchMakes({ yearId });

            dispatch(vehiclePickerDuck.creators.setMakes(data));
            if (data) {
                onSuccess();
            } else {
                onError();
            }
        } catch (error) {
            onError();
            throw new Error(error);
        }
    };
}