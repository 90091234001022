import React, {
    Fragment,
    useCallback,
    Suspense,
} from 'react';
import { mapDispatchToActionProp } from '@atc/modular-redux';
import { connect, useSelector } from 'react-redux';
import { ErrorBoundary } from 'reaxl-molecules';
import { atcAuthModule } from 'reaxl-auth';
import { Header as ATCHeader } from 'reaxl-header';
import { useAnalytics } from 'reaxl-analytics';
import { AuthenticationHeader } from 'reaxl-organisms';
import { redirectExternal } from '../utilities';

import {
    userDuck,
    dealsDuck,
    analyticsDuck,
    paymentsDuck,
    savedInventoryDuck,
    recentSearchDuck,
    ownedVehicleDuck,
} from '../ducks';

import remoteActions from '../actions/remoteActions';

import useAnalyticsDuck from '../useAnalyticsDuck';
import { onCarAlertsClick } from '../analytics/tags/authenticationClickEvents';

const HeaderContainer = ({
    actions,
    isLoggedIn = false,
}) => {
    const { sendClickEvent } = useAnalyticsDuck(analyticsDuck);
    const { sendClick, sendImpressions } = useAnalytics();
    const ownedVehicles = useSelector(ownedVehicleDuck.selectors.getOwnedVehicles);

    const handleClick = (e, data) => {
        sendClick('headerGeneralEventHandler', e, { ...data });
    };

    const onSponsorContentReady = (e, data) => {
        sendImpressions({ name: 'headerGeneralEventHandler', data: { ...data, linkContext: 'PartnersImpression' } });
    };

    const { updateAlerts } = actions;

    const handleUpdateAlerts = (data) => {
        updateAlerts(data);
        onCarAlertsClick(data, sendClick);
    };

    const handleQuickSaveItemClick = useCallback((event, { id }) => redirectExternal(`/cars-for-sale/vehicledetails.xhtml?listingId=${id}`), []);

    const handleQuickSaveConversion = useCallback(({ createdSaves, deletedSaves }) => {

        createdSaves.forEach((item) => {
            sendClickEvent(null, {
                cmp: 'qs_conv',
                c: item.ownerId,
                v: item.listingId,
                co_txt_url: 'Save',
            });
        });

        deletedSaves.forEach((item) => {
            sendClickEvent(null, {
                cmp: 'my_dtc',
                c: item.ownerId,
                v: item.listingId,
                co_txt_url: 'Delete',
            });
        });
    }, [sendClickEvent]);

    const handleQuickSaveItemToggle = useCallback((event, listing, wasSaved) => {

        sendClickEvent(event, {
            c: listing.ownerId,
            v: listing.listingId,
            cmp: wasSaved ? 'qs_delt_v' : 'qs_v',
        });
    }, [sendClickEvent]);

    const renderPersonalizationHeader = useCallback(() => (
        <Fragment>
            <Suspense fallback={null}>
                <ErrorBoundary>
                    <AuthenticationHeader
                        isLoggedIn={isLoggedIn}
                        useAuthRedirect
                        userDuck={userDuck}
                        dealsDuck={dealsDuck}
                        recentSearchDuck={recentSearchDuck}
                        savedInventoryDuck={savedInventoryDuck}
                        paymentsDuck={paymentsDuck}
                        onQuickSaveItemClick={handleQuickSaveItemClick}
                        onQuickSaveConversion={handleQuickSaveConversion}
                        onQuickSaveItemToggle={handleQuickSaveItemToggle}
                        onSaveInventoryUpdate={handleUpdateAlerts}
                        onClick={handleClick}
                        enableMyWallet
                        isTredEnable
                        showPsxNotificationsCount
                        carsIOwnCount={ownedVehicles?.length || 0}
                    />
                </ErrorBoundary>
            </Suspense>
        </Fragment>
    ), [
        handleQuickSaveItemClick,
        handleQuickSaveConversion,
        handleQuickSaveItemToggle,
        updateAlerts,
    ]);

    //  on client, renders (injects) AuthenticationHeader into mountnode w/ id = "header-content" in Header from server
    return (
        <ATCHeader
            headerContent={(typeof window !== 'undefined') && renderPersonalizationHeader()}
            onClick={handleClick}
            onSponsorContentReady={onSponsorContentReady}
        />
    );

};
function mapStateToProps(state) {
    const isLoggedIn = atcAuthModule.duck.selectors.isLoggedIn(state);
    const { screen } = atcAuthModule.duck.selectors.getAuthState(state);
    return {
        isLoggedIn: isLoggedIn && screen !== 'signOut',
    };
}
const mapDispatchToProps = mapDispatchToActionProp({
    updateAlerts: remoteActions.updateAlerts,
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
