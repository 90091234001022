import Screen from '../../constants/screen.js';
import Route from '../../reference/routes';

const pageEventReference = {
    kbb: {
        [Screen.SIGN_IN]: {
            siteSection: 'my-kbb',
            pageType: 'my_sign-in',
            detailPageName: 'my_sign-in',
            event: 'my_sign-in',
            eventElement: 'auth',
            dataIsland: {
                page: 'mykbb_landing-page',
                pageName: 'mykbb_landing-page',
                category: 'new',
                searchEngineIndentifier: '7-googleNatural',
                pageType: 'my_sign-in',
            },
        },
        [Screen.SIGN_UP]: {
            siteSection: 'my-kbb',
            pageType: 'my_sign-up',
            detailPageName: 'my_sign-up',
            event: 'my_sign-up',
            eventElement: 'auth',
            dataIsland: {
                page: 'mykbb_sign-up',
                pageName: 'mykbb_sign-up',
                category: 'new',
                searchEngineIndentifier: '7-googleNatural',
            },
        },
        [Screen.WELCOME]: {
            siteSection: 'my-kbb',
            pageType: 'my_email-verified',
            detailPageName: 'my_email-verified',
            event: 'my_email-verified',
            eventElement: 'auth',
            dataIsland: {
                page: 'mykbbconfirm',
                pageName: 'mykbbconfirm',
                category: 'new',
                searchEngineIndentifier: '7-googleNatural',
            },
        },
        [Route.CARS.displayName]: {
            siteSection: 'my-kbb',
            pageType: 'my_cars',
            detailPageName: 'my_cars',
            event: 'my_cars',
            eventElement: 'auth',
            dataIsland: {
                page: 'mykbb_mc',
                pageName: 'mykbb_mc',
                category: 'new',
                searchEngineIndentifier: '7-googleNatural',
            },
        },
        [Route.PROFILE.displayName]: {
            siteSection: 'my-kbb',
            pageType: 'my_profile',
            detailPageName: 'my_profile',
            event: 'my_profile',
            eventElement: 'auth',
            dataIsland: {
                page: 'mykbbacct',
                pageName: 'mykbbacct',
                category: 'new',
                searchEngineIndentifier: '7-googleNatural',
            },
        },
        [Route.PREFERENCES.displayName]: {
            siteSection: 'my-kbb',
            pageType: 'my_preference',
            detailPageName: 'my_preference',
            event: 'my_preference',
            eventElement: 'auth',
            dataIsland: {
                page: 'my_preferences',
                pageName: 'my_preferences',
                category: 'new',
                searchEngineIndentifier: '7-googleNatural',
            },
        },
        [Route.MYCARS.displayName]: {
            siteSection: 'my-kbb',
            pageType: 'my_cars_owned',
            detailPageName: 'my_cars_owned',
            event: 'my_cars_owned',
            eventElement: 'auth',
            dataIsland: {
                page: 'mykbb_cars_owned',
                pageName: 'mykbb_cars-owned',
                category: 'used',
                searchEngineIndentifier: '7-googleNatural',
            },
        },
        [Route.MYCARS_ADD_CAR.displayName]: {
            siteSection: 'my-kbb',
            pageType: 'my_cars_owned',
            detailPageName: 'owners-funnel',
            event: 'my_cars_owned',
            eventElement: 'auth',
            dataIsland: {
                page: 'mykbb_owners-funnel',
                pageName: 'mykbb_owners-funnel',
                category: 'used',
                searchEngineIndentifier: '7-googleNatural',
            },
        },
        [Route.MYCARS_EDIT_CAR.displayName]: {
            siteSection: 'my-kbb',
            pageType: 'my_cars_owned',
            detailPageName: 'owners-funnel',
            event: 'mykbb_owners-funnel',
            eventElement: 'auth',
            dataIsland: {
                page: 'mykbb_cars-owned',
                pageName: 'mykbb_owners-funnel',
            },
        },
        [Route.MYCARS_REMOVE_CAR.displayName]: {
            siteSection: 'my-kbb',
            pageType: 'my_cars_owned',
            detailPageName: 'my_cars_owned',
            event: 'my_cars_owned',
            eventElement: 'auth',
            dataIsland: {
                page: 'mykbb_cars-owned',
                pageName: 'mykbb_cars-owned',
            },
        },
        [Route.SHOPPEDCARS.displayName]: {
            siteSection: 'my-kbb',
            pageType: 'saved_cars',
            detailPageName: 'saved_cars',
            event: 'saved_cars',
            eventElement: 'auth',
            dataIsland: {
                page: 'mykbb_mc',
                pageName: 'mykbb_mc',
                category: 'new',
                searchEngineIndentifier: '7-googleNatural',
            },
        },
        [Route.DEALER_REVIEWS.displayName]: {
            siteSection: 'my-kbb',
            pageType: 'my_dealer_reviews',
            detailPageName: 'my_dealer_reviews',
            eventElement: 'auth',
            dataIsland: {
                page: 'mykbb_dr',
                pageName: 'mykbb_dr',
                category: 'new',
                searchEngineIndentifier: '7-googleNatural',
            },
        },
        [Route.SEARCHES.displayName]: {
            siteSection: 'my-kbb',
            pageType: 'my_searches',
            detailPageName: 'my_searches',
            event: 'my_searches',
            eventElement: 'auth',
            dataIsland: {
                page: 'mykbb_ms',
                pageName: 'mysearches',
            },
        },
    },
    atc: {
        [Screen.SIGN_IN]: {
            siteSection: 'my-at',
            pageType: 'my_sign-in',
            detailPageName: 'my_sign-in',
            event: 'my_sign-in',
            eventElement: 'auth',
            dataIsland: {
                page: 'myat_landing-page',
                pageName: 'MyATC Sign In',
                pageType: 'my_sign-in',
            },
        },
        [Screen.SIGN_UP]: {
            siteSection: 'my-at',
            pageType: 'my_sign-up',
            detailPageName: 'my_sign-up',
            event: 'my_sign-up',
            eventElement: 'auth',
            dataIsland: {
                page: 'mya_sign_up',
                pageName: 'MyATC Sign Up',
            },
        },
        [Screen.WELCOME]: {
            siteSection: 'my-at',
            pageType: 'my_email-verified',
            detailPageName: 'my_email-verified',
            event: 'my_email-verified',
            eventElement: 'auth',
            dataIsland: {
                page: 'eml_verftn',
                pageName: 'Email Verification',
            },
        },
        [Route.CARS.displayName]: {
            siteSection: 'my-at',
            pageType: 'my_cars',
            detailPageName: 'my_cars',
            event: 'my_cars',
            eventElement: 'auth',
            dataIsland: {
                page: 'mya_mc',
                pageName: 'mycars',
            },
        },
        [Route.SEARCHES.displayName]: {
            siteSection: 'my-at',
            pageType: 'my_searches',
            detailPageName: 'my_searches',
            event: 'my_searches',
            eventElement: 'auth',
            dataIsland: {
                page: 'mya_ms',
                pageName: 'mysearches',
            },
        },
        [Route.MESSAGES.displayName]: {
            siteSection: 'my-at',
            pageType: 'my_messages',
            detailPageName: 'my_messages',
            event: 'my_messages',
            eventElement: 'auth',
            dataIsland: {
                page: 'mya_mm',
                pageName: 'mymessages',
            },
        },
        [Route.PROFILE.displayName]: {
            siteSection: 'my-at',
            pageType: 'my_profile',
            detailPageName: 'my_profile',
            event: 'my_profile',
            eventElement: 'auth',
            dataIsland: {
                page: 'mya_mp',
                pageName: 'myprofile',
            },
        },
        [Route.PREFERENCES.displayName]: {
            siteSection: 'my-at',
            pageType: 'my_preference',
            detailPageName: 'my_preference',
            event: 'my_preference',
            eventElement: 'auth',
            dataIsland: {
                page: 'my_preferences',
                pageName: 'my_preferences',
            },
        },
        [Route.MYCARS.displayName]: {
            siteSection: 'my-at',
            pageType: 'my_cars_owned',
            detailPageName: 'my_cars_owned',
            event: 'my_cars_owned',
            eventElement: 'auth',
            dataIsland: {
                page: 'myat_cars-owned',
                pageName: 'myat_cars-owned',
            },
        },
        [Route.MYCARS_ADD_CAR.displayName]: {
            siteSection: 'my-at',
            pageType: 'my_cars_owned',
            detailPageName: 'owners-funnel',
            event: 'myat_owners-funnel',
            eventElement: 'auth',
            dataIsland: {
                page: 'myat_cars-owned',
                pageName: 'myat_owners-funnel',
            },
        },
        [Route.MYCARS_EDIT_CAR.displayName]: {
            siteSection: 'my-at',
            pageType: 'my_cars_owned',
            detailPageName: 'owners-funnel',
            event: 'myat_owners-funnel',
            eventElement: 'auth',
            dataIsland: {
                page: 'myat_cars-owned',
                pageName: 'myat_owners-funnel',
            },
        },
        [Route.MYCARS_REMOVE_CAR.displayName]: {
            siteSection: 'my-at',
            pageType: 'my_cars_owned',
            detailPageName: 'my_cars_owned',
            event: 'my_cars_owned',
            eventElement: 'auth',
            dataIsland: {
                page: 'myat_cars-owned',
                pageName: 'myat_cars-owned',
            },
        },
        [Route.DEALER_REVIEWS.displayName]: {
            siteSection: 'my-at',
            pageType: 'my_dealer_reviews',
            detailPageName: 'my_dealer_reviews',
            eventElement: 'auth',
            dataIsland: {
                page: 'myat_dr',
                pageName: 'myat_dr',
            },
        },
    },
};
export default pageEventReference;
