import pagesMeta from '../reference/pagesMeta';
import { myAtcDuck } from '../ducks';

export default function setPageMeta(route) {
    return async (ctx) => {
        ctx.data.meta = pagesMeta[ctx.data.brand][route] || {};
        ctx.store.dispatch(myAtcDuck.creators.setKey({
            page: {
                meta: ctx.data.meta,
            },
        }));
    };
}
