import { createSelector, DuckSelector } from '@atc/modular-redux';
import _get from 'lodash/get';
import { shoppedDuckToUserApiMapper } from './helpers';

export default {
    getShoppedVehicles: (state) => _get(state, 'shoppedVehicle.vehicles', {}),
    getShoppedVehicleIds: new DuckSelector((selectors) => createSelector(
        selectors.getShoppedVehicles,
        (vehicles = []) => vehicles.map((vehicle) => {
            if (typeof vehicle === 'object') {
                return vehicle.vehicleId;
            }

            return vehicle;
        })
    )),
    getShoppedVehicleCount: new DuckSelector((selectors) => createSelector(
        selectors.getShoppedVehicles,
        (ids) => ids.length
    )),
    getUserApiFormattedShoppedVehicles: new DuckSelector((selectors) => createSelector(
        selectors.getShoppedVehicles,
        (vehicles = []) => vehicles.map((vehicle) => shoppedDuckToUserApiMapper(vehicle))
    )),
};
