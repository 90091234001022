import {
    userApiToOwnedDuckMapper,
    researchableApiDataToOwnedDuckMapper,
    getResearchableApiData,
    formatVehicleDetails,
} from '../helpers.js';

import { getUserApiOwnedVehicles, getUserApiConsumer } from '../../../fetchers/index.js';
import remoteActions from '../../../actions/remoteActions.js';
import filterQueryParamVehicle from '../../../utilities/filterQueryParamVehicle.js';

export default function getOwnedVehicles(ownedVehicleDuck) {
    return ({
        onSuccess = (args) => {},
        onError = () => {},
        search,
    }) => async (dispatch) => {
        try {
            let kbbVehicleIds = [];
            const vehiclesIOwnWithMoreData = [];

            const { zip = '' } = await getUserApiConsumer();
            const vehiclesFromVehicleOwnershipApi = await getUserApiOwnedVehicles();

            const vehiclesWithDetails = formatVehicleDetails(vehiclesFromVehicleOwnershipApi, zip);

            if (vehiclesWithDetails.length > 0) {
                kbbVehicleIds = vehiclesWithDetails.map((vehicle) => vehicle.kbb_vehicle_id);

                const researchableApiData = await getResearchableApiData(kbbVehicleIds);

                if (vehiclesWithDetails?.length) {
                    for (let i = 0; i < vehiclesWithDetails.length; i += 1) {
                        const vehicle = {
                            ...userApiToOwnedDuckMapper(vehiclesWithDetails[i]),
                            ...researchableApiDataToOwnedDuckMapper(researchableApiData[i]),
                        };

                        vehiclesIOwnWithMoreData.unshift(vehicle);
                    }
                }
            }

            const vehicleFromQueryParam = filterQueryParamVehicle(vehiclesIOwnWithMoreData, search);
            const selectedOwnedVehicleId = vehicleFromQueryParam
                ? vehicleFromQueryParam.vehicleOwnershipId
                : vehiclesIOwnWithMoreData?.[0]?.vehicleOwnershipId;

            dispatch(ownedVehicleDuck.creators.hydrateOwnedVehicle(vehiclesIOwnWithMoreData));
            dispatch(ownedVehicleDuck.creators.setSelectedOwnedVehicleId(selectedOwnedVehicleId));
            dispatch(remoteActions.processAds());

            onSuccess(vehiclesIOwnWithMoreData);

            return vehiclesWithDetails;
        } catch (error) {
            onError();
            throw new Error(error);
        }
    };
}
