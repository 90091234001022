import { createLoader, request } from './researchable';
const MISSING_IMAGE_URL = 'https://file.kbb.com/kbb/images/image_coming_soon.png';

export const MAX_RESULT = 250;
export const vehicleDataArrayImageMapper = (data) => {
    const res = [];
    if (data?.length > 0) {
        for (let i = 0; i < data?.length; i += 1) {
            const {
                yearId,
                makeName,
                modelName,
                vehicleName,
                vehicleId,
                croppedImagePath,
                sideFrontAngleCroppedNoLogoImagePath,
                frontRightThreeQuartersImagePath,
                imagePath,
                bodyStyle,
                engineType,
                chromeStyleIds,
                makeId,
                modelId,
            } = data[i] || {};

            if (vehicleName) {
                res.push({
                    defaultImage:
                        croppedImagePath
                        || sideFrontAngleCroppedNoLogoImagePath
                        || frontRightThreeQuartersImagePath
                        || imagePath
                        || MISSING_IMAGE_URL,
                    name: `${yearId} ${makeName} ${modelName}`,
                    vehicleId: vehicleId?.toString(),
                    vehicleName,
                    year: yearId?.toString(),
                    make: makeName,
                    model: modelName,
                    bodyStyle,
                    chromeStyleId: chromeStyleIds && chromeStyleIds.length > 0
                        ? chromeStyleIds[0] || null
                        : null,
                    engineType,
                    makeId: makeId?.toString(),
                    modelId: modelId?.toString(),
                });
            }
        }
        return res;
    }
    return null;
};

export const vehicleDataMapper = (data) => {
    const {
        yearId,
        makeName,
        modelName,
        trimName,
        trimId,
        vehicleName,
        vehicleId,
        chromeStyleIds,
        nationalBaseDefaultPrice,
        characterBlurb140,
        croppedImagePath,
        sideFrontAngleCroppedNoLogoImagePath,
        frontRightThreeQuartersImagePath,
        imagePath,
        priceRanges,
        tradeInPrice,
        ppPrice,
    } = data || {};

    if (vehicleName) {
        return {
            url: null,
            defaultImage:
                croppedImagePath
                || sideFrontAngleCroppedNoLogoImagePath
                || frontRightThreeQuartersImagePath
                || imagePath
                || MISSING_IMAGE_URL,
            name: `${yearId} ${makeName} ${modelName}`,
            chromeStyleId:
                chromeStyleIds && chromeStyleIds.length > 0
                    ? chromeStyleIds[0] || null
                    : null,
            makeName,
            modelName,
            trimName,
            trimId,
            vehicleId,
            year: yearId,
            nationalBaseDefaultPrice: nationalBaseDefaultPrice || null,
            characterBlurb140: characterBlurb140 || null,
            priceRanges,
            tradeInPrice,
            ppPrice,
        };
    }
    return null;
};

const getRequestPathParameters = (reqPath) => {
    // Researchable API paths commonly utilize the following format:
    // "api/[endpoint_group]/[endpoint_name]/[additional_path_parameters]?[query_parameters]"
    const reqPathElements = reqPath.split(/[/?]/).filter((el) => el);

    return {
        endpointGroup: reqPathElements[1],
        endpointName: reqPathElements[2],
    };
};

export const getDataWithMapper = async (reqPath, mapper, maxResults) => {
    if (!reqPath || typeof reqPath !== 'string') {
        throw TypeError('The first parameter "reqPath" of type "string" is required.');
    }

    if (mapper && typeof mapper !== 'function') {
        throw TypeError('The second parameter "mapper" must be of type "function".');
    }

    if (maxResults && typeof maxResults !== 'number') {
        throw TypeError('The third parameter "maxResults" must be of type "number".');
    }

    const RESEARCHABLE_BASE_URL = process.env.RESEARCHABLE_API_BASE_URL || '';
    const RESEARCHABLE_API_KEY = process.env.RESEARCHABLE_API_KEY || '';
    let reqUrl;

    try {
        const { endpointGroup, endpointName } = getRequestPathParameters(reqPath);
        reqUrl = `${RESEARCHABLE_BASE_URL}/${reqPath}`;

        // By default, with the exception of the 'vehicles' endpoint, return all results for both 'reference data' and 'motorcycle' endpoint groups,
        // as well as the 'active campaign' personalization endpoint
        if (maxResults || endpointName === 'adbook-active-campaigns' || (endpointName !== 'vehicles' && (endpointGroup === 'reference' || endpointGroup === 'motorcycles'))) {
            let responseList = [];
            let nextUrl = reqUrl;
            while (nextUrl && (!maxResults || responseList.length < maxResults)) {

                // eslint-disable-next-line no-await-in-loop
                const response = await request(nextUrl, { headers: { 'x-api-key': RESEARCHABLE_API_KEY } });
                if (!response.data?.items) {
                    // In the scenario where no 'items' attribute exists, return response immediately.
                    // Endpoint data is limited and not designed to be paginated.
                    // eslint-disable-next-line no-await-in-loop
                    return mapper ? await mapper(response.data) : response.data;
                }

                responseList.push(...response.data.items);
                nextUrl = response.next;
            }

            if (mapper) {
                responseList = await mapper(responseList);
            }
            return maxResults ? responseList.slice(0, maxResults) : responseList;
        }

        // For all other endpoints, return results based on default limits provided by the API
        const response = await request(reqUrl, { headers: { 'x-api-key': RESEARCHABLE_API_KEY } });
        const result = response && (response.data.items || response.data);
        return mapper ? await mapper(result) : result;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.warn('Failed to load', reqUrl, err);
        // Throw the error to ensure that we do not cache the result
        throw err;
    }
};

export default (props) => createLoader({
    functionToCache: getDataWithMapper,
    ...props,
});

// export default createResearchableDataLoader();
