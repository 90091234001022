/* eslint-disable no-console */
import axios from 'axios';
import { hasEmptyContent } from './utils';

export default async (endpoint, options) => {
    // const url = options.ssr ? `${process.env.PROTOCOL}://${process.env.HOST}:${process.env.PORT}${endpoint}` : endpoint;
    const url = options.ssr ? `${process.env.PROTOCOL}://${process.env.HOST}:${process.env.PORT}${endpoint}` : endpoint;
    const response = await axios({
        url,
        ...options,
    });
    if (response.status >= 400) {
        throw new Error(`Request Error: ${response.message}`);
    } else if (hasEmptyContent(response)) {
        return null;
    }

    return response;
};
