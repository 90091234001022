import { createAction } from '@atc/modular-redux';

// Action Creators
import removeShoppedVehicleActionCreator from './removeShoppedVehicleCreator';
import {
    updateShoppedVehicleZipCodeActionCreator,
    updateShoppedVehicleConditionActionCreator,
    updateShoppedVehicleMileageActionCreator,
} from './updateShoppedVehicleCreator';
import getShoppedVehicles from './getShoppedVehiclesCreator';
import saveShoppedVehicleActionCreator from './saveShoppedVehicleCreator';
import getSelectedVehiclePADataCreator from './getSelectedVehiclePADataCreator';

export default (duck) => ({
    hydrateShoppedVehicle: createAction(duck.types.HYDRATE_SHOPPED_VEHICLE),
    getShoppedVehicles: getShoppedVehicles(duck),
    removeShoppedVehicle: removeShoppedVehicleActionCreator(duck),
    updateShoppedVehicleZipCode: updateShoppedVehicleZipCodeActionCreator(duck),
    updateShoppedVehicleCondition: updateShoppedVehicleConditionActionCreator(duck),
    updateShoppedVehicleMileage: updateShoppedVehicleMileageActionCreator(duck),
    saveShoppedVehicle: saveShoppedVehicleActionCreator(duck),
    getSelectedVehiclePAData: getSelectedVehiclePADataCreator(duck),
});
