import { fetchJSONWithCircuitBreaker } from '../utilities';

export default function fetchDealerLot(criteria, fallback = {}) {
    return fetchJSONWithCircuitBreaker(
        '/rest/lsc/dealerlot/v2/multiple',
        { query: criteria },
        'fetchDealerLot Error: ',
        fallback,
        'fetchDealerLot'
    );
}
