import buildSearchSummaryData from './searchSummaryOutputBuilder';

const convertTypeStringToArray = (data) => {
    if (!data) return undefined;
    return (typeof (data) === 'string') ? data.split(',') : data;
};

const safeJSONParse = (data) => {
    try {
        const parsedData = JSON.parse(data);
        if (typeof parsedData !== 'object') {
            return JSON.parse(parsedData);// some usecases, it still returns string after parsing
        }
        return parsedData;
    } catch {
        return false;
    }
};

export const parseQueryToCriteria = (searchCriteria) => {
    const { makeCode = [], modelCode = [], trimCode = [], doorCode = [], driveGroup = [], engineCode = [], extColorSimple = [],
        intColorSimple = [], featureCode = [], fuelTypeGroup = [], listingType = [], maxMileage = [], sellerType = [], seriesCode = [],
        dealType = [], vehicleStyleCode = [], transmissionCode = [], mpgRange = [], searchRadius = ['0'], homeServices = [], vehicleHistoryType = [],
    } = searchCriteria;

    return {
        ...searchCriteria,
        makeCodeList: convertTypeStringToArray(makeCode),
        modelCodeList: convertTypeStringToArray(modelCode),
        trimCodeList: convertTypeStringToArray(trimCode),
        doorCodes: convertTypeStringToArray(doorCode),
        driveGroup: convertTypeStringToArray(driveGroup),
        engineCodes: convertTypeStringToArray(engineCode),
        extColorsSimple: convertTypeStringToArray(extColorSimple),
        interiorColorsSimple: convertTypeStringToArray(intColorSimple),
        featureCodes: convertTypeStringToArray(featureCode),
        fuelTypeGroup: convertTypeStringToArray(fuelTypeGroup),
        listingTypesList: convertTypeStringToArray(listingType),
        maxMileage: convertTypeStringToArray(maxMileage),
        sellerTypes: convertTypeStringToArray(sellerType),
        seriesCodeList: convertTypeStringToArray(seriesCode),
        dealTypesList: convertTypeStringToArray(dealType),
        vehicleStyleCodes: convertTypeStringToArray(vehicleStyleCode),
        transmissionCodes: convertTypeStringToArray(transmissionCode),
        mpgRanges: convertTypeStringToArray(mpgRange),
        searchRadius: convertTypeStringToArray(searchRadius),
        homeServicesList: convertTypeStringToArray(homeServices),
        vehicleHistory: convertTypeStringToArray(vehicleHistoryType),
    };
};

export function buildSearchBookmarkElement(bookmarkItem, searchOptions) {
    const {
        bookmarkId,
        searchQuery,
    } = bookmarkItem;

    const searchCriteria = searchQuery ? parseQueryToCriteria(safeJSONParse(searchQuery)) : searchQuery;

    if (!searchCriteria || !Object.keys(searchCriteria).length) {
        console.log('searchQuery is not valid for search bookmark', bookmarkId);
    }
    // Set default values for searchSummary && numNewSearchResultCount when either searchCriteria or searchOptions are empty
    const searchSummary = searchCriteria && searchOptions ? buildSearchSummaryData(searchCriteria, searchOptions) : {};

    return {
        ...bookmarkItem,
        searchCriteria: searchSummary,
    };
}
