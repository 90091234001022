export default (state, action, { initialState, types }) => {

    const { payload } = action;

    switch (action.type) {

        case types.SET_VINLP_DETAILS: {
            return {
                ...state,
                ...payload,
            };
        }
        case types.RESET_STATE: {
            return initialState;
        }
        case types.SET_ERROR: {
            return {
                ...state,
                alertError: payload,
            };
        }

        default:
            return state;
    }
};