export const NONPROD = 'NONPROD';
export const PROD = 'PROD';

/* */
export const getEnvironment = () => {

    if ([
        'speedy.autotrader.com',
        'www.autotrader.com',
        'www.kbb.com',
    ].includes(globalThis.location?.hostname)
    || globalThis.location?.hostname.includes('awscsuser.kbb.com')
    || globalThis.location?.hostname.includes('awscsuser.autotrader.com')
    || process.env.ENVIRONMENT === 'prod') {
        return PROD;
    }

    return NONPROD;
};

export const isNONPROD = () => getEnvironment() === NONPROD;
export const isPROD = () => getEnvironment() === PROD;

export default getEnvironment;
