import { fetchJSON } from '@bonnet/core/fetch';

export default (pixAllId, debug, id = 'kbbTradeInsFetcher') => fetchJSON(`/account/cbh/history/${pixAllId}?pagetypefilter=search${debug ? '&debug=true' : ''}`, {
    circuitBreaker: {
        id,
        timeout: 5000,
        resetTimeout: 300000,
        fallback: null,
    },
});
