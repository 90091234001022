import { createAction } from '@atc/modular-redux';
import saveCarToAddActionCreator from './saveCarToAddActionCreator';
import getAddOptionsCreator from './getAddOptionsCreator';
import getUpdatedOptionsCreator from './getUpdatedOptionsCreator';
import setSelectedColor from './setSelectedColor';
import setSelectedOptionsType from './setSelectedOptionsType';
// Action Creators

export default (duck) => ({
    setAddCar: createAction(duck.types.SET_ADD_CAR),
    setCurrentStep: createAction(duck.types.SET_CURRENT_STEP),
    saveCarToAdd: saveCarToAddActionCreator(duck),
    // options
    hydrateOptions: createAction(duck.types.HYDRATE_OPTIONS),
    setSelectHistory: createAction(duck.types.SET_SELECT_HISTORY),
    updateOptions: getUpdatedOptionsCreator(duck),
    setOptionsType: createAction(duck.types.SET_OPTIONS_TYPE),
    setSelectedOptionsType: setSelectedOptionsType(duck),
    updateSelectedColor: setSelectedColor(duck),
    setColorId: createAction(duck.types.SET_COLOR_ID),
    setError: createAction(duck.types.SET_ERROR),
    getAddOptions: getAddOptionsCreator(duck),
    setCondition: createAction(duck.types.SET_CONDITION),
    setExtColor: createAction(duck.types.SET_EXT_COLOR),
    resetState: createAction(duck.types.RESET_STATE),
});
