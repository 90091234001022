import getConfig from '@bonnet/core/config';
import importAuthUtilityBundle from './importAuthUtilityBundle';
import localCache from './localCache';


export async function getHeaderAuthToken() {
    const { getCognitoIdToken, getCognitoAccessToken } = await importAuthUtilityBundle();
    const idtoken = await getCognitoIdToken();
    const accesstoken = await getCognitoAccessToken();

    const { publicRuntimeConfig } = getConfig();
    const USER_API_KEY = publicRuntimeConfig?.USER_API_KEY;

    if (!accesstoken || !idtoken) {
        localCache.removeLocalUser();
    }
    return (accesstoken && idtoken) ? {
        accesstoken,
        idtoken,
        'x-api-key': USER_API_KEY,
    } : { 'x-api-key': USER_API_KEY };
}

