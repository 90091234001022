/* eslint-disable no-undef */
import { BrowserStorage } from 'atc-js';
import redirectExternal from './redirectExternal';

const refUrlKey = 'federatedRefUrl';
const blinkerKey = 'BlinkerMyAtcUser';
const blinkerStorage = new BrowserStorage(blinkerKey, {});
const refUrlStorage = new BrowserStorage(refUrlKey, {});
const MyConsumerSignedIn = 'MyConsumerSignedIn';
const createOrUpdateBlinkerUser = (myAtcToken = {}) => {
    const iat = Date.now();
    const dataToStore = {
        issueAt: iat,
        token: myAtcToken.hash,
        id: myAtcToken.user.myAtcId,
    };
    blinkerStorage.setItem(JSON.stringify(dataToStore));
};

const getBlinkerUser = () => JSON.parse(blinkerStorage.getItem());

const removeBlinkerUser = () => blinkerStorage.removeItem();

const refUrlHandler = {
    set: (url = '') => { refUrlStorage.setItem(url); },
    get: () => refUrlStorage.getItem(),
    delete: () => { refUrlStorage.removeItem(); },
};
const refUrlRedirectHandler = (refUrl, replaceState = false, replacementPathname = null) => {
    const localStorageRefUrl = refUrlHandler.get();
    if (localStorageRefUrl) {
        // remove local storage item after it has been used
        refUrlHandler.delete();
    }
    const finalUrl = (localStorageRefUrl || refUrl);
    if (!replaceState || finalUrl?.indexOf('/account/') < 0) {
        redirectExternal(decodeURIComponent(finalUrl), { replace: true });
    } else {
        global.history.replaceState(global.history.state, '', ((replacementPathname || global.location.pathname) + '?refUrl=' + encodeURIComponent(finalUrl)));
    }
};

// Remove the signed-in state cookie
const removeSignedInCookie = () => {
    document.cookie = `${MyConsumerSignedIn}=true; path=/; max-age=-1`;
};

const removeLocalUser = () => {
    removeSignedInCookie();
    // Clean localStorage because we don't support multiple users staying "signed-in" at once.
    for (let i = 0; i < localStorage.length; i += 1) {
        if (localStorage.key(i).startsWith('CognitoIdentityServiceProvider.')) {
            localStorage.removeItem(localStorage.key(i));
        }
    }
};

export default {
    createOrUpdateBlinkerUser,
    getBlinkerUser,
    removeBlinkerUser,
    refUrlHandler,
    refUrlRedirectHandler,
    removeLocalUser,
};
