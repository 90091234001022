import { sortDuckCreator } from 'axl-ducks';

const myAtcSortDuck = sortDuckCreator({
    namespace: '',
    store: 'sortBy',
}).extend({
    reducer: (state, action, { types }) => {
        switch (action.type) {

            case 'LOAD_SERVERDATA': {
                return myAtcSortDuck.buildInitialState(action.payload);
            }

            default:
                return state;
        }
    },
});

myAtcSortDuck.buildInitialState = ({ requestParams }) => requestParams.sortBy || 'titleAZ';

export default myAtcSortDuck;
