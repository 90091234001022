/* eslint-disable no-console,consistent-return */
import formatVehicleOptions from '@kbbsdk/global-sdk/utils/formatVehicleOptions';
import { updateUserApiOwnedVehicle, getUserApiOwnedVehicles } from '../../../fetchers/index';
import { PRICE_STANDARD_OPTIONS } from '../../../reference';
import { ownedVehicleDuck } from '../..';

export const formatVehicle = (vehicle) => ({
    vin: vehicle?.vin || '',
    license_plate: vehicle?.licensePlate || '',
    vehicle_details: {
        latest_zipcode: vehicle?.latestZipcode,
        latest_condition: vehicle?.latestCondition,
        latest_mileage: vehicle?.latestMileage,
    },
    save_name: vehicle?.saveName,
});

export default function saveUpdatedOptions(editCarDuck) {
    return ({
        onSaveSuccess = () => {},
        onSaveFailure = () => {},
    }) => async (_, getState) => {
        const vehicleid = editCarDuck.selectors.getVehicleId(getState());
        const extColor = editCarDuck.selectors.getExtColor(getState());
        const optionType = editCarDuck.selectors.getOptionsType(getState());
        const colorId = editCarDuck.selectors.getColorId(getState());

        const selectHistory = optionType === PRICE_STANDARD_OPTIONS
            ? `${colorId}|true`
            : editCarDuck.selectors.getSelectHistory(getState());
        const kbbVehicleOptions = formatVehicleOptions(selectHistory);
        const condition = editCarDuck.selectors.getCondition(getState());

        const currentVehicle = ownedVehicleDuck.selectors.getSelectedOwnedVehicle(getState());
        let vehicleOwnershipId = currentVehicle?.vehicleOwnershipId;
        let selectedVehicle = {};

        if (!currentVehicle) {
            const vehiclesFromVehicleOwnershipApi = await getUserApiOwnedVehicles();
            const fetchedVehicle = vehiclesFromVehicleOwnershipApi.find((vehicle) => vehicle?.kbb_vehicle_id === vehicleid) || null;
            vehicleOwnershipId = fetchedVehicle?.vehicleid;

            selectedVehicle = {
                vin: fetchedVehicle?.vin,
                license_plate: fetchedVehicle?.license_plate,
                vehicle_details: {
                    latest_zipcode: fetchedVehicle?.vehicle_details?.latest_zipcode,
                    latest_condition: fetchedVehicle?.vehicle_details?.latest_condition,
                    latest_mileage: fetchedVehicle?.vehicle_details?.latest_mileage,
                    kbb_vehicle_options: kbbVehicleOptions,
                    vehicle_color: extColor,
                },
                save_name: fetchedVehicle?.save_name,
                kbb_vehicle_id: fetchedVehicle?.kbb_vehicle_id,
            };

        } else {
            const vehicleInfo = formatVehicle(currentVehicle?.vehicleOwnershipData);

            selectedVehicle = {
                ...vehicleInfo,
                kbb_vehicle_id: vehicleid.toString(),
                vehicle_details: {
                    ...vehicleInfo.vehicle_details,
                    kbb_vehicle_options: kbbVehicleOptions,
                    vehicle_color: extColor,
                    latest_condition: condition,
                },
            };
        }

        if (selectedVehicle && vehicleOwnershipId) {
            await updateUserApiOwnedVehicle(selectedVehicle, vehicleOwnershipId).then(() => {
                onSaveSuccess();
            }).catch((error) => {
                console.error('update car error:', error);
                onSaveFailure();
                throw Error(error);
            });
        } else {
            onSaveFailure();
            throw Error('No vehicleid');
        }
    };
}