import { fetchJSON } from '@bonnet/core/fetch';
import generateUrl from '../services/service-utilities/routerService/generateUrl';

export default async (microservice, routeName, parameters = null) => {
    try {
        const { s3Data } = await fetchJSON('/account/services/routerService', { query: { microservice } });
        if (Object.keys(s3Data).length !== 0) {
            if (Reflect.getOwnPropertyDescriptor(s3Data, routeName)) {
                return generateUrl(Reflect.get(s3Data, routeName), parameters);
            }
            throw new Error(`No route definition found for ${microservice}/${routeName}`);
        }
        throw new Error(`No route definition found for ${microservice}`);
    } catch (err) {
        console.error('RouterService::: Error fetching data', err);
    }
    return null;
};

export const getRoutesFromRouter = async (microservice, routes) => {
    try {
        const { s3Data } = await fetchJSON('/account/services/routerService', { query: { microservice } });
        if (Object.keys(s3Data).length !== 0) {
            return routes.reduce((acc, { routeName, parameters }) => {
                if (Reflect.getOwnPropertyDescriptor(s3Data, routeName)) {
                    return {
                        [routeName]: generateUrl(Reflect.get(s3Data, routeName), parameters),
                        ...acc,
                    };
                }
                return acc;

            }, {});
        }
        throw new Error(`No route definition found for ${microservice}`);
    } catch (err) {
        console.error('RouterService::: Error fetching data', err);
    }
    return null;
};
