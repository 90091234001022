/* eslint-disable no-console */
import _get from 'lodash.get';

import {
    page,
    shareClick,
    messagesHeaderLinkClick,
    inventoryImpressions,
    inventoryClick,
    inventorySave,
    inventoryCarouselPagination,
    ownerEmailSent,
    ownerCall,
    compareButtonClick,
    findSimilarVehiclesClick,
    removeVehiclesClick,
    saveChangesClick,
    findCarForSaleClick,
    sellMyCarClick,
    carAlertsClick,
    signUpCtaClick,
    signInCtaClick,
    footerLinkClick,
    headerGeneralEventHandler,
    browseByBudgetWalletContinueClick,
    myWalletCarouselBudgetClick,
    myWalletSummaryLinkClick,
    myWalletSearchByBudgetClick,
    myWalletFilterToggleClick,
    myWalletTradeInClickLink,
    myWalletTradeInSubmit,
    myWalletKbbTradeInIndicator,
    myWalletIcoLinkClick,
    applyValueToWalletClick,
    myWalletInteractionClick,
    myWalletInputChange,
    myWalletSearchAvailableVehiclesSubmit,
    myWalletSellYourCarClick,
    myDealSeeDetailsClick,
    seeDetailsMyWalletDealClick,
    offsiteBrowserPushNotificationImpression,
    offsiteBrowserPushNotificationClick,
    paymentOptionClick,
    walletAutoSyncSuccess,
    verifyCreditRangeClick,
    addCarsCtaClick,
    addAnotherCarClick,
    removeCarByIconClick,
    nextCtaClick,
    getMyIcoClick,
    tradeInMyCarClick,
    sellMyCarPrivatelyClick,
    sellMyCarPrivatelyTextLinkClick,
    shopCarsTextLinkClick,
    demandBarInfoClick,
    editZipForCarIOwnClick,
    editMileageForCarIOwnClick,
    editOptionsForCarIOwnClick,
    infoPriceForCarIOwnClick,
    infoPriceForCarIOwnImpression,
    addSavedCarsCtaClick,
    addAnotherSavedCarClick,
    removeSavedCarClick,
    seeSavedCarVehicleDetailsClick,
    buildAndPriceClick,
    buildAndPriceThisCarClick,
    importantInfoClick,
    getLocalPriceQuoteClick,
    seePrivatePartyListingsClick,
    seeCpoListingsClick,
    viewPricingDetailsClick,
    editSavedCarZipCodeClick,
    editSavedCarOptionsClick,
    seeDealerListingsClick,
    savedCarMileageClick,
    seeCarsForSaleClick,
    sellMyCurrentVehicleClick,
    vehicleNameTabClick,
    carIOwnVehicleTabClick,
    carsIOwnTabClick,
    savedCarsTabClick,
    savedListingsTabClick,
    savedSearchesTabClick,
    dealerReviewsTabClick,
    profileTabClick,
    myAtSavedListingsTabClick,
    myAtSavedSearchesTabClick,
    myAtCarsIOwnTabClick,
    myAtDealerReviewsTabClick,
    myAtMessagesTabClick,
    myAtProfileTabClick,
    myAtCommunicationPreferencesTabClick,
    recallsTileClick,
    repairEstimatorTileClick,
    findAutoShopCtaClick,
    serviceImpInfoClick,
    moreAboutOurDataClick,
    getServiceEstimatesClick,
    addThisCarCtaClick,
    saveThisCarCtaClick,
    findCarsForSaleCtaClick,
    sellMyCarCtaClick,
    manageMyAdCtaClick,
    priceHeaderInfoClick,
    cancelAccountClick,
    deleteAccountClick,
    deleteAccountSubmitClick,
    consumerRatingClick,
    consumerRatingImpression,
    myWalletOpenPageLoad,
    myWalletEngagementClick,
    myWalletGridTradeInIndicator,
} from 'reaxl-analytics';

import {
    logInSuccessImpression,
    logInFailImpression,
    registerSuccessImpression,
    registerFailImpression,
    resendResetLinkClick,
    signInClick,
    signUpClick,
    resetPasswordClick,
    resendVerificationLinkClick,
    resendVerificationButtonClick,
    facebookFederatedClick,
    appleFederatedClick,
    googleFederatedClick,
    getStartedClick,
} from './authenticationPageHandlers';

import {
    editAccountClick,
    changePasswordClick,
    editAccountSubmitClick,
} from './myProfilePageHandlers';

import AddEditRemoveCarEvents from './tags/AddEditRemoveCarEvents';
import CommunicationsPrefsEvents from './tags/CommunicationsPrefsEvents';
import SavedSearchesEvents from './tags/SavedSearchesEvents';

import {
    paymentsDuck,
    userDuck,
} from '../ducks';

export default {
    // Standard page event callback
    page,
    messagesHeaderLinkClick,
    inventoryCarouselPagination,
    inventoryImpressions: (metadata, ...arg) => inventoryImpressions({
        ...metadata,
        userDuck,
    }, ...arg),
    logInSuccessImpression,
    logInFailImpression,
    registerSuccessImpression,
    registerFailImpression,
    footerLinkClick,
    headerGeneralEventHandler,
    browseByBudgetWalletContinueClick,
    myWalletCarouselBudgetClick,
    myWalletSummaryLinkClick,
    myWalletSearchByBudgetClick,
    myWalletFilterToggleClick,
    myWalletTradeInClickLink,
    myWalletTradeInSubmit,
    myWalletKbbTradeInIndicator,
    myWalletIcoLinkClick,
    applyValueToWalletClick,
    myWalletInteractionClick,
    myWalletInputChange,
    myWalletSearchAvailableVehiclesSubmit,
    myDealSeeDetailsClick,
    offsiteBrowserPushNotificationImpression,
    offsiteBrowserPushNotificationClick,
    seeDetailsMyWalletDealClick,
    verifyCreditRangeClick,
    paymentOptionClick,
    walletAutoSyncSuccess,
    addCarsCtaClick,
    carIOwnVehicleTabClick,
    addAnotherCarClick,
    removeCarByIconClick,
    nextCtaClick,
    getMyIcoClick,
    tradeInMyCarClick,
    sellMyCarPrivatelyClick,
    sellMyCarPrivatelyTextLinkClick,
    shopCarsTextLinkClick,
    demandBarInfoClick,
    editZipForCarIOwnClick,
    editMileageForCarIOwnClick,
    editOptionsForCarIOwnClick,
    infoPriceForCarIOwnClick,
    infoPriceForCarIOwnImpression,
    addSavedCarsCtaClick,
    addAnotherSavedCarClick,
    removeSavedCarClick,
    seeSavedCarVehicleDetailsClick,
    buildAndPriceClick,
    buildAndPriceThisCarClick,
    importantInfoClick,
    getLocalPriceQuoteClick,
    seePrivatePartyListingsClick,
    seeCpoListingsClick,
    viewPricingDetailsClick,
    editSavedCarZipCodeClick,
    editSavedCarOptionsClick,
    seeDealerListingsClick,
    savedCarMileageClick,
    seeCarsForSaleClick,
    sellMyCurrentVehicleClick,
    vehicleNameTabClick,
    carsIOwnTabClick,
    savedCarsTabClick,
    savedListingsTabClick,
    savedSearchesTabClick,
    dealerReviewsTabClick,
    profileTabClick,
    myAtSavedListingsTabClick,
    myAtSavedSearchesTabClick,
    myAtCarsIOwnTabClick,
    myAtDealerReviewsTabClick,
    myAtMessagesTabClick,
    myAtProfileTabClick,
    myAtCommunicationPreferencesTabClick,
    recallsTileClick,
    repairEstimatorTileClick,
    findAutoShopCtaClick,
    serviceImpInfoClick,
    moreAboutOurDataClick,
    getServiceEstimatesClick,
    addThisCarCtaClick,
    saveThisCarCtaClick,
    findCarsForSaleCtaClick,
    sellMyCarCtaClick,
    manageMyAdCtaClick,
    priceHeaderInfoClick,
    cancelAccountClick,
    deleteAccountClick,
    deleteAccountSubmitClick,
    consumerRatingClick,
    consumerRatingImpression,
    ...AddEditRemoveCarEvents,
    ...CommunicationsPrefsEvents,
    ...SavedSearchesEvents,
    myWalletOpenPageLoad,
    myWalletGridTradeInIndicator,
    myWalletEngagementClick: (metadata, option) => myWalletEngagementClick({
        ...metadata,
        paymentsDuck,
    }, option),

    myWalletSellYourCarClick: (metadata, option) => myWalletSellYourCarClick({
        ...metadata,
        paymentsDuck,
    }, option),

    inventoryClick: (metadata, ...arg) => inventoryClick({
        ...metadata,
        userDuck,
    }, ...arg),

    inventorySave: (metadata, ...arg) => inventorySave({
        ...metadata,
        userDuck,
        dataIsland: _get(metadata, 'state.birf.pageData', {}),
    }, ...arg),

    ownerCall: (metadata, ...arg) => ownerCall({
        ...metadata,
        userDuck,
    }, ...arg),

    ownerEmailSent: ({ data, ...metadata }, ...arg) => ownerEmailSent({
        ...metadata,
        data: {
            ...data,
        },
        userDuck,
    }, ...arg),
    compareButtonClick: (metadata, ...arg) => compareButtonClick({
        ...metadata,
        userDuck,
    }, ...arg),
    findSimilarVehiclesClick: (metadata, ...arg) => findSimilarVehiclesClick({
        ...metadata,
        userDuck,
    }, ...arg),
    removeVehiclesClick: (metadata, ...arg) => removeVehiclesClick({
        ...metadata,
        userDuck,
    }, ...arg),
    saveChangesClick: (metadata, ...arg) => saveChangesClick({
        ...metadata,
        userDuck,
    }, ...arg),
    findCarForSaleClick: (metadata, ...arg) => findCarForSaleClick({
        ...metadata,
        userDuck,
    }, ...arg),
    sellMyCarClick: (metadata, ...arg) => sellMyCarClick({
        ...metadata,
        userDuck,
    }, ...arg),
    resendResetLinkClick: (metadata, ...arg) => resendResetLinkClick({
        ...metadata,
        userDuck,
    }, ...arg),
    signInClick: (metadata, ...arg) => signInClick({
        ...metadata,
        userDuck,
    }, ...arg),
    signUpClick: (metadata, ...arg) => signUpClick({
        ...metadata,
        userDuck,
    }, ...arg),
    signUpCtaClick: (metadata, ...arg) => signUpCtaClick({
        ...metadata,
        userDuck,
    }, ...arg),
    signInCtaClick: (metadata, ...arg) => signInCtaClick({
        ...metadata,
        userDuck,
    }, ...arg),
    resetPasswordClick: (metadata, ...arg) => resetPasswordClick({
        ...metadata,
        userDuck,
    }, ...arg),
    resendVerificationLinkClick: (metadata, ...arg) => resendVerificationLinkClick({
        ...metadata,
        userDuck,
    }, ...arg),
    resendVerificationButtonClick: (metadata, ...arg) => resendVerificationButtonClick({
        ...metadata,
        userDuck,
    }, ...arg),
    facebookFederatedClick: (metadata, ...arg) => facebookFederatedClick({
        ...metadata,
        userDuck,
    }, ...arg),
    appleFederatedClick: (metadata, ...arg) => appleFederatedClick({
        ...metadata,
        userDuck,
    }, ...arg),
    googleFederatedClick: (metadata, ...arg) => googleFederatedClick({
        ...metadata,
        userDuck,
    }, ...arg),
    getStartedClick: (metadata, ...arg) => getStartedClick({
        ...metadata,
        userDuck,
    }, ...arg),
    editAccountClick: (metadata, ...arg) => editAccountClick({
        ...metadata,
        userDuck,
    }, ...arg),
    changePasswordClick: (metadata, ...arg) => changePasswordClick({
        ...metadata,
        userDuck,
    }, ...arg),
    editAccountSubmitClick: (metadata, ...arg) => editAccountSubmitClick({
        ...metadata,
        userDuck,
    }, ...arg),
    shareClick: (metadata, option) => {
        const { data } = metadata;
        return shareClick({
            ...metadata,
            data: {
                ...data,
            },
            userDuck,
        }, option);
    },
    carAlertsClick: (metadata, ...arg) => carAlertsClick({
        ...metadata,
    }, ...arg),
};
