import React from 'react';
import { Theme } from '@argo/principles';
import {
    atcTheme,
    kbbTheme,
} from '@argo/themes';
import { ArgoFonts } from '@argo/utils';

atcTheme.button.primary.regular = 48;
atcTheme.button.secondary.regular = 48;
atcTheme.button.tertiary.regular = 48;

export default ({ isKBB, ...props }) => (
    <Theme
        theme={isKBB ? kbbTheme : atcTheme}
        fontFace={isKBB && ArgoFonts}
        {...props}
    />
);
