import { fetchJSON } from '@bonnet/core/fetch';

export default async (email, userSelections) => {
    const cleanToken = email.replace(' ', '+');
    const encodedEmailToken = encodeURIComponent(cleanToken);

    try {
        const data = await fetchJSON('/account/light-user/subscriptions/unsubscribe', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: {
                emailToken: encodedEmailToken,
                selections: userSelections,
            },
        });

        return data?.userSuccesfullyUnsubscribed;
    } catch (err) {
        console.error('unsubscribe Error:::', err);
    }
    return null;
};
