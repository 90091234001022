export default function setSelectedColor(editCarDuck) {
    return ({
        colorId,
    }) => async (dispatch, getState) => {
        const options = editCarDuck.selectors.getOptions(getState());
        const selectHistory = editCarDuck.selectors.getSelectHistory(getState());

        const colors = options.colors.map((color) => {
            const isSelected = color.vehicleOptionId === colorId;
            if (isSelected) {
                dispatch(editCarDuck.creators.setExtColor(color.optionName.toLowerCase()));
            }
            return {
                ...color,
                isSelected,
            };
        });

        dispatch(editCarDuck.creators.hydrateOptions({
            ...options,
            colors,
        }));
        dispatch(editCarDuck.creators.setColorId(+colorId));
        dispatch(editCarDuck.creators.setSelectHistory(`${selectHistory && `${selectHistory}|`}${colorId}|true`));
    };
}
